import { PassSelectedExtraService } from './../services/pass-selected-extra.service';


import { TourService } from './../services/tour.service';
import { TransportService } from './../services/transport.service';
import { Component, OnInit } from '@angular/core';
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";
import { ExtraService } from './../services/extra.service';
import * as $ from 'jquery';
import { ViewChild,  } from '@angular/core';
import { MatAccordion } from '@angular/material';


@Component({
  selector: 'app-extra',
  templateUrl: './extra.component.html',
  styleUrls: ['./extra.component.css']
})
export class ExtraComponent implements OnInit {

  displayFeatures
  transport_array
  flight_DFt
  flight_array
  transport_DFt
  translationsLabels

  extra_array =[]
  tourListProvider
  expand=0
  @ViewChild('accordion') Accordion: MatAccordion;

  day

  day_id

  extra

  extra_id

  header_img

  constructor(private transportation : TransportService,
     public tourService: TourService,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private extraService:ExtraService,
    private pass_extra_service:PassSelectedExtraService) { 

      this.header_img = JSON.parse(localStorage.getItem("LSDitinerarydata")).itinerary.imgSrc


      setTimeout(()=>{this.check_height()},100) 

      this.day=this.pass_extra_service.getDay();

      this.extra =this.pass_extra_service.getExtra();

      this.pass_extra_service.setData(undefined,undefined)

      if(this.day !=undefined && this.extra !=undefined){
        this.day_id=this.day.day
        this.extra_id= this.extra.id+this.day.day


        setTimeout(()=>{
          var x = $("#"+this.extra_id).position();
       console.log(" PASSSTTTT  Top: " + x.top + " Left: " + x.left);


        window.scrollTo(x.left, x.top)


     },1000);


      }

     

    }

  

    check_height(){

     
        if($(window).height()>= $(".mainContainer").height()+250){
          this.show_scroll_up_btn=false;
        }
        
        if($(window).height()< $(".mainContainer").height()+250){
          this.show_scroll_up_btn=true;
        }
  
        
      }

    show_scroll_up_btn=true;

  ngOnInit() {


    this.svgregister();

    
  this.translationsLabels=""
  var userinfo = JSON.parse(localStorage.getItem("UserData"))
  this.translationsLabels = JSON.parse(localStorage.getItem("UserData")).user.translations


  this.extraService.extraAll(data=>{


    this.get_Extra_Content(data);


    var index = (localStorage.getItem('INDEX_WELCOME'))

    this.tourListProvider = JSON.parse(localStorage.getItem("tourListProvider"))
    
    if (this.tourListProvider[index].brand.length == undefined) {
        $('#extraCSS').text(
            '.guide-color {color:' + this.tourListProvider[index].brand.color + ' !important;}' +
            'a {color:' + this.tourListProvider[index].brand.color + ' !important;}'
    
        );
    } else {
        $('#extraCSS').text(
            '.guide-color {color:' + JSON.parse(localStorage.getItem("primaryColor")) + ' !important;}' +
            'a {color:' + JSON.parse(localStorage.getItem("primaryColor")) + ' !important;}'
        );
    }

  });






  
}


get_Extra_Content(data) {

  console.log("get_Extra_Content: extra ", data)
  var extra_details = data;





  var extraData = []

  for (var i = 0; i < extra_details.length; i++) {

    if (extra_details[i].extras_json.length > 0) {

      var extra_info = extra_details[i].extras_json



      var dd = extra_info[0].start_date
      var mm = extra_info[0].start_month
      var y = extra_info[0].start_year





      var formattedDate = dd + ' ' + mm + ' ' + y;
      console.log("get_Extra_Content: date",formattedDate)



      //Redirect Links Code
      if (extra_info.length != 0) {

        for (var j = 0; j < extra_info.length; j++) {
          var desc = extra_info[j].description;
         
          // extra_info[j].description= desc.replace("<a", "<a  target='_blank' ");
          extra_info[j].description = desc.replace(/<a/g, "<a  target='_blank' ");

        }       
      }
       //End :Redirect Links Code



      for (var j = 0; j < extra_info.length; j++) {

        if (extra_info.length != 0) {

          
          extra_info.day_number = extra_details[i].day_number;

          

          extra_info.name = extra_info[j].name;
          extra_info.description = extra_info[j].description;
          extra_info.id = extra_info[j].id;
          extra_info.trans_date = formattedDate;
          extra_info[j].trasnport_date = formattedDate;
        }
      }
      extraData.push(extra_details[i])
    }

  }

  this.extra_array = extraData;


  this.sortByDay();

}

sortByDay() {
  this.extra_array.sort((a, b) => {
    return parseFloat(a.day_number) - parseFloat(b.day_number);
  });
}

closeAllPanels() {
  this.expand = 0
  this.Accordion.closeAll();

}

openAllPanels() {
  this.expand = 1
  this.Accordion.openAll();
}

togglePanel(extra,value){
  extra.panelOpenState = value;
  const anyOpen = this.extra_array.some(day => day.panelOpenState);
  
  if (anyOpen){
      this.expand = 1
  }else{
      this.expand = 0
  }
}

svgregister() {



//Transport Erica BOC From Scratch
this.matIconRegistry.addSvgIcon(
    "bus",
    this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/Images/transport/bus.svg")
);

this.matIconRegistry.addSvgIcon(
    "ferry",
    this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/Images/transport/ferry.svg")
);

this.matIconRegistry.addSvgIcon(
    "cablecar",
    this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/Images/transport/cablecar.svg")
);

this.matIconRegistry.addSvgIcon(
    "train",
    this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/Images/transport/train.svg")
);

this.matIconRegistry.addSvgIcon(
    "other",
    this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/Images/transport/others.svg")
);


this.matIconRegistry.addSvgIcon(
    "shuttle",
    this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/Images/transport/shuttle.svg")
);


this.matIconRegistry.addSvgIcon(
    "flight",
    this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/Images/transport/flights.svg")
);

this.matIconRegistry.addSvgIcon(
    "flight-arraival",
    this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/Images/transport/flight_arrives.svg")
);

this.matIconRegistry.addSvgIcon(
    "flight-departure",
    this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/Images/transport/flight_departs.svg")
);

this.matIconRegistry.addSvgIcon(
  "itinerarycalender",
  this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/Images/svg/itinerary-list.svg")
);


//Transport Erica EOC From Scratch


}

scroll_up(){
  
  $(window).scrollTop( $("body").offset().top );
}

trigger(){
  this.check_height();
}


}
