import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DailySurveyService {

  
survey
id
day
  constructor() { 
    
  }

  setDatasurvey(val){
    this.survey= val;
  }
  getDatasurvey(){
    return this.survey;
  }

  setDataid(id){
    this.id= id;
  }
  getDataid(){
    return this.id;
  }

  setDataDay(val){
    this.day= val;
  }
  getDataDay(){
    return this.day;
  }

}
