import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PagenameService {

 
  pageName =""

  constructor() { }

  setData(val){
    this.pageName= val;
  }
  getData(){
    return this.pageName;
  }
}
