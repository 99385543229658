import { Component, OnInit } from '@angular/core';
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";
import * as $ from 'jquery';
import { Location } from '@angular/common';
import { link } from 'fs';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.css']
})
export class ContactUsComponent implements OnInit {

  companyInfo
  translationsLabels

  logo
  header_img
  constructor(private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private location: Location,) { 

      this.header_img = JSON.parse(localStorage.getItem("LSDitinerarydata")).itinerary.imgSrc


     this.companyInfo = JSON.parse(window.localStorage.getItem('companyProvider'));
    



     this.companyInfo.website_link= this.updatelinksks(this.companyInfo.website_link);
     this.companyInfo.facebook_link= this.updatelinksks(this.companyInfo.facebook_link);

     this.companyInfo.linkedin_link= this.updatelinksks(this.companyInfo.linkedin_link);
     this.companyInfo.twitter_link= this.updatelinksks(this.companyInfo.twitter_link);

     this.companyInfo.instagram_link= this.updatelinksks(this.companyInfo.instagram_link);
     this.companyInfo.youtube_link= this.updatelinksks(this.companyInfo.youtube_link);

     this.companyInfo.pinterest_link= this.updatelinksks(this.companyInfo.pinterest_link); 
     this.companyInfo.whatsapp_link= this.updatelinksks(this.companyInfo.whatsapp_link);



   

     this.translationsLabels = JSON.parse(localStorage.getItem("UserData")).user.translations


     this.svgregister() 

  
     setTimeout(()=>{this.check_height()},100) 

     this.tourlistsaved= JSON.parse(localStorage.getItem('tourListProvider')); 
     this.index = (localStorage.getItem('INDEX_WELCOME'))

     if (this.tourlistsaved[this.index].brand.color != undefined) {
         
      
      this.logo=this.tourlistsaved[this.index].brand.logo
      
  
  
  
  } else {
    
      this.logo=JSON.parse(localStorage.getItem("companyProvider")).logo

      
      }
      

     

    }

    index
   

    tourlistsaved

    check_height(){

    
     
        if($(window).height()>= $(".mainContainer").height()+250){
          this.show_scroll_up_btn=false;
        }else if($(window).height()< $(".mainContainer").height()+250){
          this.show_scroll_up_btn=true;
        }
  
       
      }

      scroll_up(){
  
        $(window).scrollTop( $("body").offset().top );
    }

    close_page(){
      this.location.back();
    }
  
    
  
      show_scroll_up_btn=true;

  ngOnInit() {
  }

  svgregister() {

  

this.matIconRegistry.addSvgIcon(
  "phone_c",
  this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/Images/mixed/phone.svg")
);

this.matIconRegistry.addSvgIcon(
  "mail_c",
  this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/Images/mixed/mail.svg")
);
// *************************************8



    

}

updatelinksks(link){
  
  if(link!=null && link!=undefined){
    var re = /\/\//gi; 
    var str = link;//"www.google.com" ;//"https://www.pinterest.ca/";//www.google.com
    if (str.search(re) == -1 ) { 
      
    
     str="http://"+str
   
     return str;
    
    } else { 
    
    
       var re = /https:\/\//gi; 
    
       var newstr = str.replace(re, "http://"); 
    
  
       return newstr
  }
  }
  return link;
  }
 




}
