import { Injectable } from '@angular/core';
import { Restangular } from 'ngx-restangular';
import { AppUrls} from '../app-storagekeys-urls';
import * as $ from 'jquery';
import { HttpClient } from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class DocumentService {

  Urls = AppUrls()
  currentUserData
  restHeader

  constructor(private restangular: Restangular,private http: HttpClient,) { }

  
  all(callback) {
    var id = localStorage.getItem('LStourListId');
    if ((localStorage.getItem("UserData")) != null) {
      this.currentUserData = JSON.parse(localStorage.getItem("UserData"));
  }

  var user_language = "";
  if (window.localStorage.getItem("selected_language_by_user") == null) {
      user_language = this.currentUserData.user.language;
  } else {
      user_language = window.localStorage.getItem("selected_language_by_user");
  }


  this.restHeader = this.currentUserData.user.email + ':' + this.currentUserData.token + ':' + user_language

 


    this.restangular.withConfig((RestangularConfigurer) => {
        RestangularConfigurer.setDefaultHeaders({
            'Content-Type': 'application/json',
            'Authorization': this.restHeader
        });
    }).one(this.Urls.docCategoriesUrl).get({
        itinerary_id: id
    }).toPromise().then(response => {
        console.log("Doccument factory , response", response)
        console.log('DocumentFactory getting List newwww :  ', response.document_categories);
        callback(response.document_categories);
    }, (err) => {
        console.log('geting err in hotels provider : ', err)
        callback("ERROR");
    })

}

documentList(catId, callback) {

  var id = localStorage.getItem('LStourListId');
  if ((localStorage.getItem("UserData")) != null) {
    this.currentUserData = JSON.parse(localStorage.getItem("UserData"));
}

var user_language = "";
if (window.localStorage.getItem("selected_language_by_user") == null) {
    user_language = this.currentUserData.user.language;
} else {
    user_language = window.localStorage.getItem("selected_language_by_user");
}


this.restHeader = this.currentUserData.user.email + ':' + this.currentUserData.token + ':' + user_language




  this.restangular.withConfig((RestangularConfigurer) => {
      RestangularConfigurer.setDefaultHeaders({
          'Content-Type': 'application/json',
          'Authorization': this.restHeader
      });

  }).one(this.Urls.documentListUrl).get({
      category_id: catId,
      itinerary_id: id,
     
  }).toPromise().then(function(response) {
      console.log('Get documents list in DocumentFactory: ', response.document);
      callback(response.document);
  }, function(response) {
      console.log('Error documents list DocumentFactory: ', response.data.response);
      sessionStorage.setItem('checkDocumentNull', 'true');
      callback('error')
  });
}


GetAllUsers(callback) {
    var id = localStorage.getItem('LStourListId');
    if ((localStorage.getItem("UserData")) != null) {
      this.currentUserData = JSON.parse(localStorage.getItem("UserData"));
  }
  
  var user_language = "";
  if (window.localStorage.getItem("selected_language_by_user") == null) {
      user_language = this.currentUserData.user.language;
  } else {
      user_language = window.localStorage.getItem("selected_language_by_user");
  }
  
  
  this.restHeader = this.currentUserData.user.email + ':' + this.currentUserData.token + ':' + user_language
  

    this.restangular.withConfig((RestangularConfigurer) => {
        RestangularConfigurer.setDefaultHeaders({
            'Content-Type': 'application/json',
            'Authorization': this.restHeader
        });

    }).one(this.Urls.subGetusers).get({
        itinerary_id: id
    }).toPromise().then(function(response) {
        console.log('DocumentFactory getting Users:  ', response);
        callback(response);
    }, function(err) {
        console.log('Error DocumentFactory getting Users: ', err);
        sessionStorage.setItem('checkDocumentNull', 'true');
        callback('error')
    });
}


allAdmin(tID, callback) {
    console.log("user idddddddddddddddddddddddd", tID)
    var id = localStorage.getItem('LStourListId');
    if ((localStorage.getItem("UserData")) != null) {
      this.currentUserData = JSON.parse(localStorage.getItem("UserData"));
  }
  
  var user_language = "";
  if (window.localStorage.getItem("selected_language_by_user") == null) {
      user_language = this.currentUserData.user.language;
  } else {
      user_language = window.localStorage.getItem("selected_language_by_user");
  }
  
  
  this.restHeader = this.currentUserData.user.email + ':' + this.currentUserData.token + ':' + user_language
  

    console.log('getting tourlistId in doccument Provider : ', id)
    this.restangular.withConfig((RestangularConfigurer) => {
        RestangularConfigurer.setDefaultHeaders({
            'Content-Type': 'application/json',
            'Authorization':  this.restHeader
        });
    }).one(this.Urls.subGetlist).get({
        itinerary_id: id,
        traveller_id: tID
    }).toPromise().then(response => {
        console.log("Doccument factory , response", response)
        console.log('DocumentFactory getting List ALL Admin :  ', response.document_categories);
        callback(response.document_categories);
    }, (err) => {
        console.log('geting err in hotels provider : ', err)
        callback(err);
    })

}

DocumentFactoryAllTraveller(docId, traveller_id, callback) {
    var id = localStorage.getItem('LStourListId');
    if ((localStorage.getItem("UserData")) != null) {
      this.currentUserData = JSON.parse(localStorage.getItem("UserData"));
  }
  
  var user_language = "";
  if (window.localStorage.getItem("selected_language_by_user") == null) {
      user_language = this.currentUserData.user.language;
  } else {
      user_language = window.localStorage.getItem("selected_language_by_user");
  }
  
  
  this.restHeader = this.currentUserData.user.email + ':' + this.currentUserData.token + ':' + user_language
  

    console.log('getting tourlistId in doccument Provider : ', id)
    this.restangular.withConfig((RestangularConfigurer) => {
        RestangularConfigurer.setDefaultHeaders({
            'Content-Type': 'application/json',
            'Authorization':  this.restHeader
        });

    }).one(this.Urls.subGet).get({
        category_id: docId,
        itinerary_id: id,
        traveller_id: traveller_id
    }).toPromise().then(function(response) {
        console.log('DocumentFactory TRAVELLER:  ', response.document);
        callback(response.document);
    }, function(response) {
        console.log('Error DocumentFactory TRAVELLER: ', response.data.response);
        sessionStorage.setItem('checkDocumentNull', 'true');
        callback('error')
    });
}

uploadDocuments(formModel, loadingCtrl, callback) {


    //   let headerOptions: any = {
    //     'Authorization': window.localStorage.getItem('restHeader')
    // };
    var user_language = "";
    if (window.localStorage.getItem("selected_language_by_user") == null) {
        console.log("ORR 1", JSON.parse(localStorage.getItem("UserData")));
        user_language = this.currentUserData.user.language;
    } else {
        console.log("ORR 2", JSON.parse(localStorage.getItem("UserData")));
        user_language = window.localStorage.getItem("selected_language_by_user");
    }

    if ((localStorage.getItem("UserData")) != null) {
        this.currentUserData = JSON.parse(localStorage.getItem("UserData"));
    }
    this.restHeader = this.currentUserData.user.email + ':' + this.currentUserData.token + ':' + user_language
      let headerOptions: any = {
        'Authorization': this.restHeader
    };
   
    let headers = new Headers(headerOptions);
    this.http.post(this.Urls.apiuploaddoc + 'documents/create', formModel,{headers: headerOptions}).toPromise().then(response => {
        console.log("SUCCES OF DOCUMENTS UPLOAD", response)
        callback(response)
        
    }).catch(err => {
        console.log("erro:", err)
      
    })
}

DocumentFactoryAll(catId, callback) {
    if ((localStorage.getItem("UserData")) != null) {
        this.currentUserData = JSON.parse(localStorage.getItem("UserData"));
    }
    console.log("selected_language_by_user", window.localStorage.getItem("selected_language_by_user"))


    var user_language = "";
    if (window.localStorage.getItem("selected_language_by_user") == null) {
        console.log("ORR 1", JSON.parse(localStorage.getItem("UserData")));
        user_language = this.currentUserData.user.language;
    } else {
        console.log("ORR 2", JSON.parse(localStorage.getItem("UserData")));
        user_language = window.localStorage.getItem("selected_language_by_user");
    }


    this.restHeader = this.currentUserData.user.email + ':' + this.currentUserData.token + ':' + user_language


    var itinery_id = localStorage.getItem("LStourListId");
    console.log("I AM in DoccumentFactoryALL", itinery_id, "catID", catId)
    console.log("I AM in DoccumentFactoryALL rest header", localStorage.getItem('restHeader'))
    this.restangular.withConfig((RestangularConfigurer) => {
        RestangularConfigurer.setDefaultHeaders({
            'Content-Type': 'application/json',
            'Authorization': this.restHeader
        });
     
    }).one(this.Urls.subGet).get({
        category_id: catId,
        itinerary_id: itinery_id
    }).toPromise().then(function(response) {
        console.log('Get documents list in DocumentFactory: ', response.document);
        callback(response.document);
    }, function(response) {
        console.log('Error documents list DocumentFactory: ', response.data.response);
        sessionStorage.setItem('checkDocumentNull', 'true');
        callback('error')
    });
}



deleteuploadedDocuments(id, callback) {


    if ((localStorage.getItem("UserData")) != null) {
        this.currentUserData = JSON.parse(localStorage.getItem("UserData"));
    }
    console.log("selected_language_by_user", window.localStorage.getItem("selected_language_by_user"))


    var user_language = "";
    if (window.localStorage.getItem("selected_language_by_user") == null) {
        console.log("ORR 1", JSON.parse(localStorage.getItem("UserData")));
        user_language = this.currentUserData.user.language;
    } else {
        console.log("ORR 2", JSON.parse(localStorage.getItem("UserData")));
        user_language = window.localStorage.getItem("selected_language_by_user");
    }


    this.restHeader = this.currentUserData.user.email + ':' + this.currentUserData.token + ':' + user_language


   

    var subdelete = '/documents/delete?document_id=' + id;
    this.restangular.withConfig((RestangularConfigurer) => {
        RestangularConfigurer.setDefaultHeaders({
            'Content-Type': 'application/json',
            'Authorization': this.restHeader
        });
    }).one(subdelete).remove().toPromise().then(function(response) {
        console.log('deleteuploadedDocuments in DocumentFactory: ', response);
        callback(response);
        
    }, function(err) {
        console.log('Error deleteuploadedDocuments in DocumentFactory: ', err);
        callback('error')
       
    });

}



}
