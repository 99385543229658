import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UserIdService {

  constructor() { }

  
  sharedData =""

  

  setData(val){
    this.sharedData= val;
  }
  getData(){
    return this.sharedData;
  }
}
