﻿import { InsidePagesGuard } from './inside-pages.guard';
import { AuthLoggedInGuard } from './auth-logged-in.guard';
import { AuthGuard } from './auth.guard';
import { AuthGuardService }  from './auth-guard.service';
import { HomePdfComponent } from './home-pdf/home-pdf.component';
import { SingleItineraryPdfComponent } from './single-itinerary-pdf/single-itinerary-pdf.component';
import { ContactUsefulComponent } from './contact-useful/contact-useful.component';
import { ContactEmergencyComponent } from './contact-emergency/contact-emergency.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { ContactsComponent } from './contacts/contacts.component';
import { DailySurveyComponent } from './daily-survey/daily-survey.component';
import { EndOfTourComponent } from './end-of-tour/end-of-tour.component';

//  (01) : Erica : Tranport Feature
//  (02) : Erica : Custom Menu
//  (03) : Erica : Extra
//  (04) : Erica : Document
//  (05) : Erica : Document List
//  (06) : Erica : Document View
//  (07) : Erica : daily Survey
//  (08) : Erica : Single Itinerary PDF CR
//  (09) : Erica : Home Desc  PDF CR
//  (10) : Erica : Header Image change in 40 files (header_image)

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { ForgotpasswordComponent } from './forgotpassword/forgotpassword.component';
import { WelcomeComponent } from './welcome/welcome.component';
import { HomeComponent } from './home/home.component';
import { ItineraryComponent } from './itinerary/itinerary.component';
import { HotelComponent } from './hotel/hotel.component';
import { GuidesComponent } from './guides/guides.component';
import { ExperiencesComponent } from './experiences/experiences.component';
import { MealsComponent } from './meals/meals.component';
import { TransportComponent } from './transport/transport.component';
import { CustomComponent } from './custom/custom.component';//(02)
import { SubCustomMenuComponent } from './sub-custom-menu/sub-custom-menu.component';//(02)
import { RefreshComponent } from './services/refresh/refresh.component';
import { ExtraComponent } from './extra/extra.component';
import { DocumentComponent } from './document/document.component';
import { DocumentListComponent } from './document-list/document-list.component';
import { DocumentViewComponent } from './document-view/document-view.component';
import { ShowAllTravellerDocsComponent } from './show-all-traveller-docs/show-all-traveller-docs.component';






const routes: Routes = [

    {
        path: '', component: LoginComponent,
        canActivate: [AuthLoggedInGuard] 
    },
    {
        path: 'forgotpassword', component: ForgotpasswordComponent
    },
    {
        path: 'welcome', 
        component: WelcomeComponent,
         canActivate: [AuthGuard] 
    },
    {
        path: 'home', 
        component: HomeComponent,
         canActivate: [InsidePagesGuard]  
    },
    {
        path: 'itinerary',
         component: ItineraryComponent,
         canActivate: [InsidePagesGuard] 
    },
    {
        path: 'hotel', 
        component: HotelComponent,
        canActivate: [InsidePagesGuard] 
    },
    {
        path: 'guides',
         component: GuidesComponent,
         canActivate: [InsidePagesGuard] 
    },
    {
        path: 'experiences', 
        component: ExperiencesComponent,
        canActivate: [InsidePagesGuard] 
    },
    {
        path: 'meals',
         component: MealsComponent,
         canActivate: [InsidePagesGuard] 
    },
    {
        path: 'transport',
         component: TransportComponent,//(01)
         canActivate: [InsidePagesGuard] 
    },
    {
        path:'custom-menu', 
        component:CustomComponent,//(02)
        canActivate: [InsidePagesGuard] 
    },
    {
        path:'sub-custom-menu',
         component:SubCustomMenuComponent,//(02)
         canActivate: [InsidePagesGuard] 
    },
    {
        path:'refresh', 
        component:RefreshComponent//(02)
    },
    {
        path:'extra',
         component:ExtraComponent,//(03)
         canActivate: [InsidePagesGuard]  
    },
    {
        path:'document',
         component:DocumentComponent,//(04)
         canActivate: [InsidePagesGuard]  
    },
    {
        path:'document-list',
         component:DocumentListComponent,//(05)
         canActivate: [InsidePagesGuard]
    },
    {
        path:'document-view',
         component:DocumentViewComponent,//(06)
         canActivate: [InsidePagesGuard]
    },
    {
        path:'show-all-traveller-doc', 
        component:ShowAllTravellerDocsComponent,
        canActivate: [InsidePagesGuard]
    },
    {
        path:'end-of-tour', 
        component:EndOfTourComponent,
        canActivate: [InsidePagesGuard]
    },
    {
        path:'daily-survey',
         component:DailySurveyComponent,
         canActivate: [InsidePagesGuard]
    },
    {
        path:'contact',
         component:ContactsComponent,
         canActivate: [InsidePagesGuard]
    },
    {
        path:'contact-us', 
        component:ContactUsComponent,
        canActivate: [InsidePagesGuard]
    },
    {
        path:'contact-emergency',
         component:ContactEmergencyComponent,
         canActivate: [InsidePagesGuard]
    },
    {
        path:'contact-useful',
         component:ContactUsefulComponent,
         canActivate: [InsidePagesGuard]
    },
    {
        path:'single-itinerary-pdf',
         component:SingleItineraryPdfComponent,
         canActivate: [InsidePagesGuard]
    },
    {
        path:'home-desc-pdf', 
        component:HomePdfComponent,
        canActivate: [InsidePagesGuard]
    }

    


   
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
