import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';
import {DomSanitizer} from '@angular/platform-browser';

@Component({
  selector: 'app-home-pdf',
  templateUrl: './home-pdf.component.html',
  styleUrls: ['./home-pdf.component.css']
})
export class HomePdfComponent implements OnInit {

  itinerary
  SinglePDFurl
  header_img

  constructor(public domSanitizer:DomSanitizer,) {

    this.header_img = JSON.parse(localStorage.getItem("LSDitinerarydata")).itinerary.imgSrc


    this.itinerary= JSON.parse(localStorage.getItem("itinerary_data"));

    this.SinglePDFurl = this.domSanitizer.bypassSecurityTrustResourceUrl(this.itinerary.pdf_description);

    



    setTimeout(()=>{this.check_height()},100) 

          }
      
          show_scroll_up_btn=false
          check_height(){

            console.log("Custom Menu xoxo "+$(window).height()+" "+$(document).height())
           
              if($(window).height()>= $(".mainContainer").height()+250){
                this.show_scroll_up_btn=false;
              }else if($(window).height()< $(".mainContainer").height()+250){
                this.show_scroll_up_btn=true;
              }
        
             
            }
      
            scroll_up(){
        
              $(window).scrollTop( $("body").offset().top );
          }

  ngOnInit() {
  }

}
