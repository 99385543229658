import { Component, OnInit } from '@angular/core';
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";
import * as $ from 'jquery';
import { Location } from '@angular/common';

@Component({
  selector: 'app-contact-useful',
  templateUrl: './contact-useful.component.html',
  styleUrls: ['./contact-useful.component.css']
})
export class ContactUsefulComponent implements OnInit {

 
  companyInfo
  translationsLabels
  displayFeatures
  supplierArr
  countTransport=0
  countFood=0
  countTravel=0
  countOther=0
  countRecreation=0
  supplierArrcount
  
  header_img
  
  constructor(private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private location: Location,) { 

      this.header_img = JSON.parse(localStorage.getItem("LSDitinerarydata")).itinerary.imgSrc


     this.companyInfo = JSON.parse(window.localStorage.getItem('companyProvider'));


      // BOC Erica (02)
   {
    this.displayFeatures = JSON.parse(localStorage.getItem("display_feature"));
    }
    // EOC Erica (02)

    

     this.translationsLabels = JSON.parse(localStorage.getItem("UserData")).user.translations

     this.supplierArr = this.TypeSupplierFactory(this.displayFeatures).all();


     var LSDgroupcontactOffline = JSON.parse(window.localStorage.getItem('LSDContactList'))

    

      {
      if (LSDgroupcontactOffline) {
        for (let i = 0; i < LSDgroupcontactOffline.length; i++) {
          if (LSDgroupcontactOffline[i].role == "Suppliers") {
            if (LSDgroupcontactOffline[i].supplier_type == "Transportation") {
              this.countTransport = this.countTransport + 1
            
            } else if (LSDgroupcontactOffline[i].supplier_type == "Food and Beverage") {
              this.countFood = this.countFood + 1
          
            } else if (LSDgroupcontactOffline[i].supplier_type == "Travel and Tourism") {
              this.countTravel = this.countTravel + 1
           
            } else if (LSDgroupcontactOffline[i].supplier_type == "Recreation") {
              this.countRecreation = this.countRecreation + 1
             
            } else if (LSDgroupcontactOffline[i].supplier_type == "Others") {
              this.countOther = this.countOther + 1
          
            }

          }
        }
      }

      
      this.supplierArrcount = this.countTransport + this.countFood + this.countTravel + this.countRecreation + this.countOther

      for (let i = 0; i < this.supplierArr.length; i++) {
        if (this.supplierArr[i].supplierId == "Transportation") {

          this.supplierArr[i].count = this.countTransport

        }
        if (this.supplierArr[i].supplierId == "Food and Beverage") {
          this.supplierArr[i].count = this.countFood

        }
        if (this.supplierArr[i].supplierId == "Travel and Tourism") {
          this.supplierArr[i].count = this.countTravel

        }
        if (this.supplierArr[i].supplierId == "Recreation") {
          this.supplierArr[i].count = this.countRecreation

        }
        if (this.supplierArr[i].supplierId == "Others") {
          this.supplierArr[i].count = this.countOther

        }
        var supplierTypeArr = [];
        var supplier = this.TypeSupplierFactory(this.displayFeatures).get(this.supplierArr[i].supplierId)
        var LSDgroupcontactOffline = JSON.parse(window.localStorage.getItem('LSDContactList'));
        this.supplierArr[i].contacts = []

        for (let j = 0; j < LSDgroupcontactOffline.length; j++) {
          if (LSDgroupcontactOffline[j].supplier_type != null) {
            if (LSDgroupcontactOffline[j].supplier_type == supplier.supplierId) {
              this.supplierArr[i].contacts.push(LSDgroupcontactOffline[j])
            }
          }

        }
      }

     

    }


     this.svgregister() 
     setTimeout(()=>{this.check_height()},100) 

  }

    show_scroll_up_btn
    check_height(){

      
     
        if($(window).height()>= $(".mainContainer").height()+250){
          this.show_scroll_up_btn=false;
        }else if($(window).height()< $(".mainContainer").height()+250){
          this.show_scroll_up_btn=true;
        }
  
       
      }

      scroll_up(){
  
        $(window).scrollTop( $("body").offset().top );
    }

    close_page(){
      this.location.back();
    }
  ngOnInit() {
  }

  svgregister() {

  

this.matIconRegistry.addSvgIcon(
  "phone_c",
  this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/Images/mixed/phone.svg")
);

this.matIconRegistry.addSvgIcon(
  "mail_c",
  this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/Images/mixed/mail.svg")
);
// *************************************8



    

}


TypeSupplierFactory(displayFeatures){
  var LSDuserinfoProvider = JSON.parse(localStorage.getItem('userInfoProvider'));
  
  var supplierTypeArray = [
    {
      supplierId: 'Food and Beverage',
      name: this.translationsLabels.food,
      class: 'tineri-catering'
    },
    {
      supplierId: 'Recreation',
      name: this.translationsLabels.recreation,
      class: 'tineri-essential-oil'
    },
    {
      supplierId: 'Transportation',
      name: this.translationsLabels.transportation,
      class: 'tineri-restaurant-date-calendar-page'
    }, {
      supplierId: 'Travel and Tourism',
      name: this.translationsLabels.travel,
      class: 'tineri-entrance'
    }, {
      supplierId: 'Others',
      name: this.translationsLabels.others,
      class: 'tineri-books'
    },

  ];

  return{
    all: function(){
      return supplierTypeArray;
    },
    get: function (supplierId) {
     
      for (var i = 0; i < supplierTypeArray.length; i++) {
        if (supplierTypeArray[i].supplierId == supplierId) {
          return supplierTypeArray[i];
        }
      }
      return null;
    }
  }
}



}
