import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PassSelectedTransportService {

  
  day
  transport

  constructor() { }

  setData(day, transport){
    console.log("PASSSTTTT             ",day)
    console.log("PASSSTTTT             ",transport)
    this.day= day;
    this.transport= transport;
  }
  getDay(){
    return this.day;
  }
  getTransport(){
    return this.transport;
  }
}
