//(01): Erica Sub Menu dynamic Color Chnages 
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router'
import {ActivatedRoute} from '@angular/router'
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import { MatIconRegistry } from "@angular/material/icon";
import { SubMenuServiceService } from './../services/sub-menu-service.service';
// import {Http, ResponseContentType} from '@angular/http';
// import * as fileSaver from 'file-saver';
import * as $ from 'jquery';//(01)
import { Location } from '@angular/common';

@Component({
  selector: 'app-sub-custom-menu',
  templateUrl: './sub-custom-menu.component.html',
  styleUrls: ['./sub-custom-menu.component.css']
})
export class SubCustomMenuComponent implements OnInit {

  sub_menu
  tourListProvider: any;//(01)
  translationsLabels

  header_img

  constructor(private router: Router,
    private route : ActivatedRoute,
   public submenuservice: SubMenuServiceService,//(03) Erica
   public domSanitizer:DomSanitizer,
   private matIconRegistry: MatIconRegistry,
  //  private http: Http,
   private location: Location,
   ) {

    this.header_img = JSON.parse(localStorage.getItem("LSDitinerarydata")).itinerary.imgSrc


    this.translationsLabels=""
    var userinfo = JSON.parse(localStorage.getItem("UserData"))
    this.translationsLabels = JSON.parse(localStorage.getItem("UserData")).user.translations
  
  

    this.tourListProvider = JSON.parse(localStorage.getItem("tourListProvider"))


    // BOC Erica (01)
    var index = (localStorage.getItem('INDEX_WELCOME'))



    setTimeout(() => {
        if (this.tourListProvider[index].brand.length == undefined) {
            console.log("TEST IS ON", this.tourListProvider[index].brand.color)
            $('#Header').text('app-sub-custom-menu .bbb svg{fill:'+ this.tourListProvider[index].brand.color + ' !important; stroke-opacity:0 !important}'+
          'app-sub-custom-menu .bbb svg rect{fill:'+ this.tourListProvider[index].brand.color +' !important ; stroke-opacity:0 !important}'+
            'app-sub-custom-menu .bbb svg polygon{fill:'+ this.tourListProvider[index].brand.color+ ' !important ; stroke-opacity:0 !important}'+
           'app-sub-custom-menu .bbb svg path{fill:'+ this.tourListProvider[index].brand.color +' !important; stroke-opacity:0 !important}'+
           'app-sub-custom-menu .bbb svg path:nth-of-type(n){fill:'+ this.tourListProvider[index].brand.color +' !important; stroke-opacity:0 !important}'+
           'app-sub-custom-menu .bbb svg path:nth-of-type(1){fill:'+ this.tourListProvider[index].brand.color +' !important; stroke-opacity:0 !important}'+
           'app-sub-custom-menu .bbb svg path:nth-of-type(2){fill:'+ this.tourListProvider[index].brand.color +' !important; stroke-opacity:0 !important}'+
           'app-sub-custom-menu .bbb svg path:nth-of-type(3){fill:'+ this.tourListProvider[index].brand.color +' !important; stroke-opacity:0 !important}'+
        
          
          
           'app-header .bbb svg{fill:'+ this.tourListProvider[index].brand.color + ' !important; stroke-opacity:0 !important}'+
           'app-header .bbb svg rect{fill:'+ this.tourListProvider[index].brand.color +' !important ; stroke-opacity:0 !important}'+
             'app-header .bbb svg polygon{fill:'+ this.tourListProvider[index].brand.color+ ' !important ; stroke-opacity:0 !important}'+
            'app-header .bbb svg path{fill:'+ this.tourListProvider[index].brand.color +' !important; stroke-opacity:0 !important}'+
            'app-header .bbb svg path:nth-of-type(n){fill:'+ this.tourListProvider[index].brand.color +' !important; stroke-opacity:0 !important}'+
            'app-header .bbb svg path:nth-of-type(1){fill:'+ this.tourListProvider[index].brand.color +' !important; stroke-opacity:0 !important}'+
            'app-header .bbb svg path:nth-of-type(2){fill:'+ this.tourListProvider[index].brand.color +' !important; stroke-opacity:0 !important}'+
            'app-header .bbb svg path:nth-of-type(3){fill:'+ this.tourListProvider[index].brand.color +' !important; stroke-opacity:0 !important}'+
         
           
           
            'app-header .ccc svg{fill:white !important; stroke-opacity:0 !important}'+
            'app-header .ccc svg rect{fill:white !important; stroke-opacity:0 !important}'+
            'app-header .ccc svg polygon:nth-of-type(n){fill:white !important; stroke-opacity:0 !important}'+
            'app-header .ccc svg polygon:nth-of-type(1){fill:white !important; stroke-opacity:0 !important}'+
            'app-header .ccc svg path:nth-of-type(1){fill:white !important; stroke-opacity:0 !important}'+
            'app-header .ccc svg path:nth-of-type(2){fill:white !important; stroke-opacity:0 !important}'+
            'app-header .ccc svg path:nth-of-type(3){fill:white !important; stroke-opacity:0 !important}'+
            'app-header .ccc svg path:nth-of-type(n){fill:white !important; stroke-opacity:0 !important}'+
              'app-header .ccc svg polygon{fill:white !important; stroke-opacity:0 !important}'+
             'app-header .ccc svg path{fill:white !important; stroke-opacity:0 !important}'
             
        
        
            );
        } else {
            console.log("Entry else", JSON.parse(localStorage.getItem("primaryColor")))
            // $('#Header').text('app-header .bbb svg{fill:' + JSON.parse(localStorage.getItem("primaryColor")) + ' !important;}'
        
        
            // );
        }
        }, 200);
         // EOC Erica (01)
    

         setTimeout(()=>{this.check_height()},100) 

        }
    
      

        check_height(){

         
            if($(window).height()>= $(".mainContainer").height()+250){
              this.show_scroll_up_btn=false;
            }else if($(window).height()< $(".mainContainer").height()+250){
              this.show_scroll_up_btn=true;
            }
      
           
          }
    
        show_scroll_up_btn=true;

        close_page(){
          this.location.back();
        }
      

    url:SafeResourceUrl
    pdf_url:SafeResourceUrl
    description

    url_try

  ngOnInit() {
    // var index = (localStorage.getItem('INDEX_WELCOME'))
    // console.log("Slide index from welcome", index)
    // $('#Header').text('app-header .bbb svg{stroke:'+ this.tourListProvider[index].brand.color + ' !important;}'+
    // 'app-header .bbb svg rect:nth-of-type(2){stroke:'+ this.tourListProvider[index].brand.color + ' !important;}'+
    //  'app-header .bbb svg rect:nth-of-type(1){stroke:'+ this.tourListProvider[index].brand.color + ' !important;}'+
    //   'app-header .bbb svg polygon:nth-of-type(1){stroke:'+ this.tourListProvider[index].brand.color + ' !important;}'+
    //  'app-header .bbb svg path:nth-of-type(3){stroke:'+ this.tourListProvider[index].brand.color + ' !important;}'+
    // 'app-header .bbb svg path:nth-of-type(2){stroke:'+ this.tourListProvider[index].brand.color + ' !important;}'+
    //   'app-header .bbb svg path:nth-of-type(1){stroke:'+ this.tourListProvider[index].brand.color + ' !important;}'+
    //  'app-header .bbb svg path{fill:'+ this.tourListProvider[index].brand.color + ' !important;}'
      
  
    //   );
  
    this.sub_menu = this.submenuservice.getData();

   

    if(Object.keys(this.sub_menu).length === 0){

     
      this.location.back();
    }

    this.svgregister_icon(this.sub_menu)

   

      if( this.sub_menu.content_type == 0){

        var str = this.sub_menu.description; 
      // var res = str.replace("<a", "<a  target='_blank' ");
      var res = str.replace(/<a/g, "<a  target='_blank' ");

      
  
        this.description = res;
      }

    if( this.sub_menu.content_type == 2){

      var str = this.sub_menu.url; 
      this.url_try=str

      var res = str.replace("/watch?v=", "/embed/");
    

      // this.url = this.domSanitizer.bypassSecurityTrustResourceUrl(res);

      this.url = this.updatelinksks(res)

    }

    if(this.sub_menu.content_type == 1){

  

      this.pdf_url = this.domSanitizer.bypassSecurityTrustResourceUrl(this.sub_menu.pdf_description);
    }

  }

  svgregister_icon( customIconSubMenus) {

    
    this.matIconRegistry.addSvgIcon(
      customIconSubMenus.name,
        this.domSanitizer.bypassSecurityTrustResourceUrl(customIconSubMenus.icon_url)
    );

}

// downloadFile(url_y){
//   return this.http.get(url_y, {responseType: ResponseContentType.Blob});

  


  
   

// }

// Method to donwload pdf
// download(url_y) {
//   this.downloadFile(url_y).subscribe(response => {
//     let blob:any = new Blob([response.blob()], {type: "application/pdf;charset=utf-8"});
//     const url= window.URL.createObjectURL(blob);
//     // //window.open(url);
//     // window.location.href = response.url;
//     fileSaver.saveAs(blob, 'employees');
//   }), error => console.log('Error downloading the file'),
//                () => console.info('File downloaded successfully');
// }

svgregister() {

 

  //Transport Erica BOC From Scratch
  this.matIconRegistry.addSvgIcon(
      "download",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/Images/mixed/download.svg")
  );

 
  

}

updatelinksks(link){
  var re = /\/\//gi; 
  var str = link;//"www.google.com" ;//"https://www.pinterest.ca/";//www.google.com
  if (str.search(re) == -1 ) { 
    
  
   str="http://"+str
  
  
   return str;
  
  } else { 
    
  
     var re = /https:\/\//gi; 
  
     var newstr = str.replace(re, "http://"); 
    

     return newstr
}
}

scroll_up(){
  
  $(window).scrollTop( $("body").offset().top );
}

}
