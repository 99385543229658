import { Injectable } from '@angular/core';

import { BehaviorSubject } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class DataServiceService {
  public loggedIn: BehaviorSubject<any> = new BehaviorSubject<any>('');
  constructor() { }

  get isLoggedIn(){
    return this.loggedIn.asObservable()
  }
}
