import { ContactService } from './../services/contact.service';
import { Component, OnInit } from '@angular/core';
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";
import { Router } from '@angular/router';

@Component({
  selector: 'app-contacts',
  templateUrl: './contacts.component.html',
  styleUrls: ['./contacts.component.css']
})
export class ContactsComponent implements OnInit {

  displayFeatures
  supplierList = 0
  emergency_count
  groupContact

  isShowdata

  header_img

  constructor( public contactService: ContactService,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private router: Router ) {

      this.header_img = JSON.parse(localStorage.getItem("LSDitinerarydata")).itinerary.imgSrc


      this.svgregister();

  //   this.displayFeatures =JSON.parse(localStorage.getItem("display_feature"));

 
  //    this.groupContact = this.TypeContactsFactory(this.displayFeatures).all()
  // this.getContactsList()


   // BOC Erica (02)
   {
    this.displayFeatures = JSON.parse(localStorage.getItem("display_feature"));
    }
    // EOC Erica (02)

    this.translationsLabels = JSON.parse(localStorage.getItem("UserData")).user.translations


  let allGroupContact = this.TypeContactsFactory(this.displayFeatures).all()
  console.log('coming to offline in contacts page')
  var LSDcompanyOffline = JSON.parse(window.localStorage.getItem('LSDCompany'));
  
  var emergencycontact = JSON.parse(localStorage.getItem("LSDContactListEmergency")) //LSDContactListEmergency
  var contactList = JSON.parse(localStorage.getItem("LSDContactList"))


  if(emergencycontact && this.displayFeatures){
    let emeCount = 0
    for(let i=0;i<emergencycontact.length;i++){
      if(emergencycontact[i].phone != '' && this.displayFeatures.messages.operators == true){
        emeCount = emeCount + 1

      }else if(emergencycontact[i].phone != '' && this.displayFeatures.messages.operators == false){
        emeCount = emeCount + 1

      }else if(emergencycontact[i].phone == '' && this.displayFeatures.messages.operators == true){
        emeCount = emeCount + 1
      }
    }
    if(emeCount > 0){
      this.emergency_count= emeCount
    }else{
      this.emergency_count=0;
      this.showEmergency = '0'
    }

  }else{
    this.emergency_count=0;
    this.showEmergency = '0'
  }


 
  for (let i = 0 ; i < contactList.length; i++) {
      if (contactList[i].role == "Suppliers") {
    

        this.supplierList = this.supplierList + 1

      }
  }
 
  if (emergencycontact == undefined || emergencycontact == null) {
      this.showEmergency = '0'
  } else {
      this.showEmergency = '1'
  }

  this.assignConacts(allGroupContact,this.supplierList,this.emergency_count)



   }

   showEmergency

  ngOnInit() {
  }

  getContactsList(){
    this.contactService.ContactsFactoryListAll((data) => {

      if (data != 'error') {

          for (let i = 0 ; i < data.length; i++) {
            if (data[i].role == "Suppliers") {
              this.supplierList = this.supplierList + 1

            }
          }

          this.getEmergencyContact()
      } else {
        this.supplierList = 0

        this.getEmergencyContact()
      }
    })
  }

  getEmergencyContact(){
    this.contactService.StoreEmergencyContact((data) => {


      if (data != 'error') {

            let emeCount = 0
            for(let i=0;i<data.length;i++){
              if(data[i].phone != '' && this.displayFeatures.messages.operators == true){
                emeCount = emeCount + 1

              } else if(data[i].phone != '' && this.displayFeatures.messages.operators == false){
                emeCount = emeCount + 1

              }else if(data[i].phone == '' && this.displayFeatures.messages.operators == true){
                emeCount = emeCount + 1
              }
            }
            if(emeCount > 0){
              this.emergency_count= emeCount

            }else{
              this.emergency_count=0;

            }
         
      }else{

        this.emergency_count=0;
        
      }

      this.assignConacts(this.groupContact,this.supplierList,this.emergency_count)
  })
  }


  assignConacts(groupContact,supplierList,emergency_count){
  
    let contactsArr = []
    groupContact.forEach(contact=>{
      if(contact.contacts_feature && (contact.groupId != 'Guides')){
        if(contact.groupId == 'Suppliers' && supplierList>0){
          contactsArr.push(contact)
          }
          if(contact.groupId == 'Emergency Contact' && emergency_count>0){
            contactsArr.push(contact)
          }
          if(contact.groupId == 'Head Offices'){
            contactsArr.push(contact)
          }
      }
    })

    console.log("Contacts validation:",contactsArr)
    this.groupContact = contactsArr
    this.isShowdata=true;
  }


  translationsLabels
  TypeContactsFactory(displayFeatures){
    var LSDuserinfoProvider = JSON.parse(localStorage.getItem('userInfoProvider'));

    this.translationsLabels = JSON.parse(localStorage.getItem("UserData")).user.translations
    var contactGuideArr = [
      {
        groupId: 'Transportation',
        name: this.translationsLabels.transport,
        class: 'tineri-transport',
        contacts_feature: displayFeatures.contacts.transport
      },
      {
        groupId: 'Head Offices',
        name: this.translationsLabels.contact_us,
        class: 'contactus_c',
        contacts_feature: displayFeatures.contacts.head_office
      },
      {
        groupId: 'Suppliers',
        name: this.translationsLabels.useful_contact,
        //  class: 'tineri-hotel-supplier',
        class: 'useful_contacts_c',
        contacts_feature: displayFeatures.contacts.supplier
      },
      {
        groupId: 'Emergency Contact',
        name: this.translationsLabels.emergency_contact,
        class: 'emergency_contacts_c',
        contacts_feature: displayFeatures.contacts.emergency_contact
      }
    ];

    return{
      all: function(){
        return contactGuideArr
      },

      get: function(contactGuideId){
        for (let i = 0; i < contactGuideArr.length; i++) {
          if (contactGuideArr[i].groupId == contactGuideId) {
              return contactGuideArr[i];
          }
        }
        return null;
      }
    }
  }

  svgregister() {

   


    this.matIconRegistry.addSvgIcon(
        "contactus_c",
        this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/Images/contact/contact_us.svg")
    );

    this.matIconRegistry.addSvgIcon(
      "emergency_contacts_c",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/Images/contact/emergencyContacts.svg")
  );

  this.matIconRegistry.addSvgIcon(
    "useful_contacts_c",
    this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/Images/contact/useful_contacts.svg")
);


this.matIconRegistry.addSvgIcon(
  "forward_c",
  this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/Images/mixed/forward.svg")
);

this.matIconRegistry.addSvgIcon(
  "phone_c",
  this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/Images/mixed/phone.svg")
);
   


    

}

open(data){




  if(data == this.translationsLabels.contact_us){

    this.router.navigate(['contact-us'])

  }
  if(data == this.translationsLabels.emergency_contact){

    this.router.navigate(['contact-emergency'])

  }
  if(data == this.translationsLabels.useful_contact){

    this.router.navigate(['contact-useful'])

  }

  

}





}
