import { Injectable } from '@angular/core';
import { Restangular } from 'ngx-restangular';
import { AppUrls} from '../app-storagekeys-urls'


@Injectable({
  providedIn: 'root'
})
export class TransportService {

  Urls = AppUrls()
  currentUserData
  restHeader

  constructor(private restangular: Restangular) { }

  transportsAll(callback) {

    var id = localStorage.getItem('LStourListId');
    if ((localStorage.getItem("UserData")) != null) {
      this.currentUserData = JSON.parse(localStorage.getItem("UserData"));
  }

  var user_language = "";
  if (window.localStorage.getItem("selected_language_by_user") == null) {
      user_language = this.currentUserData.user.language;
  } else {
      user_language = window.localStorage.getItem("selected_language_by_user");
  }


  this.restHeader = this.currentUserData.user.email + ':' + this.currentUserData.token + ':' + user_language



    this.restangular.withConfig((RestangularConfigurer) => {
        RestangularConfigurer.setDefaultHeaders({
          'Content-Type': 'application/json',
          'Authorization': this.restHeader
        });
      }).one(this.Urls.transportSubUrl).get({
        itinerary_id: id
      }).toPromise()
      .then(function (response) {
      
        callback(response.transports);
      }, (err) => {
        
      })
  }


  flightsAll(callback) {

    var id = localStorage.getItem('LStourListId');
    if ((localStorage.getItem("UserData")) != null) {
      this.currentUserData = JSON.parse(localStorage.getItem("UserData"));
  }

  var user_language = "";
  if (window.localStorage.getItem("selected_language_by_user") == null) {
      user_language = this.currentUserData.user.language;
  } else {
      user_language = window.localStorage.getItem("selected_language_by_user");
  }


  this.restHeader = this.currentUserData.user.email + ':' + this.currentUserData.token + ':' + user_language




    this.restangular.withConfig((RestangularConfigurer) => {
      RestangularConfigurer.setDefaultHeaders({
        'Content-Type': 'application/json',
        'Authorization': this.restHeader
      });
    }).one(this.Urls.flightsSubUrl).get({
      itinerary_id: id
    }).toPromise().then(function (response) {
      
      callback(response.flights);
    }, (err) => {
      
      callback('error');
    })
  }



}
