﻿import { Component, OnInit ,Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as $ from 'jquery';


@Component({
  selector: 'app-alert-dailog',
  templateUrl: './alert-dailog.component.html',

})
export class AlertDailogComponent implements OnInit {
  

    tourListProvider

    
    

    constructor( @Inject(MAT_DIALOG_DATA) public data: any) {
       


        this.tourListProvider = JSON.parse(localStorage.getItem("tourListProvider"))

      


        
        var index = (localStorage.getItem('INDEX_WELCOME'))
       
        if(index!=null){

          setTimeout(() => {

            if(this.tourListProvider!=undefined){
              if (this.tourListProvider!=null && this.tourListProvider[index].brand.color != undefined ) {

                $('#mixed').text(
              
                '  .popup_button {background:'+ this.tourListProvider[index].brand.color +' !important; }'

                );
            } else if(this.tourListProvider==null || this.tourListProvider[index].brand.color == undefined ) {


                $('#mixed').text(
                  '  .popup_button {background:'+ JSON.parse(localStorage.getItem("primaryColor")) +' !important; }'

                );

            }
            }

            }, 100);

        }
        
        if(index==null || this.tourListProvider==undefined){
          $('#mixed').text('  .popup_button {background:'+ JSON.parse(localStorage.getItem("primaryColor")) +' !important; }'

          );
          }

       
       



    }



  ngOnInit() {
  }



}







