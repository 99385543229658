import { AlertDialogDynamicComponent } from './../alert-dialog-dynamic/alert-dialog-dynamic.component';
import { DocumentService } from './../services/document.service';
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthenticationService } from '../services/authentication.service'
import { Router } from '@angular/router'
import { AlertDailogComponent } from '../alert-dailog/alert-dailog.component';
import { DomSanitizer,SafeHtml  } from "@angular/platform-browser";
import * as $ from 'jquery';




@Component({
 
  selector: 'app-upload-document',
  templateUrl: './upload-document.component.html',
  styleUrls: ['./upload-document.component.css']
})

export class UploadDocumentComponent implements OnInit {

 
  // @ViewChild("component1") component1: DocumentComponent;



  message: any;
  userinfo: any;
  docname
  file: any;
  disableChooseBtn: boolean;
  groupDocArr: any;
  docArrlength: any;
  docArr: any[];
  allDocs: any[];

  passDATA

  hideSpinner=true

  translationsLabels
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
  public DomSanitizer:DomSanitizer, public authService: AuthenticationService,
       private router: Router,
      private matDialog: MatDialog,public AuthenticationService:AuthenticationService,
      private documentService:DocumentService,
      private dialogRef: MatDialogRef<UploadDocumentComponent>,
      ) {
       
        this.passDATA=data

        this.userinfo = JSON.parse(localStorage.getItem("UserData"))
        
        var companyinfo = JSON.parse(localStorage.getItem("companyProvider"))

        this.translationsLabels = this.userinfo.user.translations


        this.tourListProvider = JSON.parse(localStorage.getItem("tourListProvider"))

        // BOC Erica (06)
        var index = (localStorage.getItem('INDEX_WELCOME'))

        setTimeout(() => {

          
            if (this.tourListProvider[index].brand.color != undefined) {

                $('#custommenu').text('app-header .bbb svg{fill:'+ this.tourListProvider[index].brand.color + ' !important; stroke-opacity:0 !important}'+
              'app-header .bbb svg rect{fill:'+ this.tourListProvider[index].brand.color +' !important ; stroke-opacity:0 !important}'+
                'app-header .bbb svg polygon{fill:'+ this.tourListProvider[index].brand.color+ ' !important ; stroke-opacity:0 !important}'+
               'app-header .bbb svg path{fill:'+ this.tourListProvider[index].brand.color +' !important; stroke-opacity:0 !important}'+
               'app-header .bbb svg path:nth-of-type(n){fill:'+ this.tourListProvider[index].brand.color +' !important; stroke-opacity:0 !important}'+
               'app-header .bbb svg path:nth-of-type(1){fill:'+ this.tourListProvider[index].brand.color +' !important; stroke-opacity:0 !important}'+
               'app-header .bbb svg path:nth-of-type(2){fill:'+ this.tourListProvider[index].brand.color +' !important; stroke-opacity:0 !important}'+
               'app-header .bbb svg path:nth-of-type(3){fill:'+ this.tourListProvider[index].brand.color +' !important; stroke-opacity:0 !important}'+
            
              
              
               'app-header .ccc svg{fill:white !important; stroke-opacity:0 !important}'+
               'app-header .ccc svg rect{fill:white !important; stroke-opacity:0 !important}'+
               'app-header .ccc svg polygon:nth-of-type(n){fill:white !important; stroke-opacity:0 !important}'+
               'app-header .ccc svg polygon:nth-of-type(1){fill:white !important; stroke-opacity:0 !important}'+
               'app-header .ccc svg path:nth-of-type(1){fill:white !important; stroke-opacity:0 !important}'+
               'app-header .ccc svg path:nth-of-type(2){fill:white !important; stroke-opacity:0 !important}'+
               'app-header .ccc svg path:nth-of-type(3){fill:white !important; stroke-opacity:0 !important}'+
               'app-header .ccc svg path:nth-of-type(n){fill:white !important; stroke-opacity:0 !important}'+
                 'app-header .ccc svg polygon{fill:white !important; stroke-opacity:0 !important}'+
                'app-header .ccc svg path{fill:white !important; stroke-opacity:0 !important}'+
                'app-upload-document  .popup_button {background:'+ this.tourListProvider[index].brand.color +' !important; }'

                
            
            
                );
            } else if(this.tourListProvider[index].brand.color == undefined) {


                $('#custommenu').text('app-header .bbb svg{fill:'+ JSON.parse(localStorage.getItem("primaryColor")) + ' !important; stroke-opacity:0 !important}'+
              'app-header .bbb svg rect{fill:'+ JSON.parse(localStorage.getItem("primaryColor")) +' !important ; stroke-opacity:0 !important}'+
                'app-header .bbb svg polygon{fill:'+ JSON.parse(localStorage.getItem("primaryColor"))+ ' !important ; stroke-opacity:0 !important}'+
               'app-header .bbb svg path{fill:'+ JSON.parse(localStorage.getItem("primaryColor")) +' !important; stroke-opacity:0 !important}'+
               'app-header .bbb svg path:nth-of-type(n){fill:'+ JSON.parse(localStorage.getItem("primaryColor")) +' !important; stroke-opacity:0 !important}'+
               'app-header .bbb svg path:nth-of-type(1){fill:'+ JSON.parse(localStorage.getItem("primaryColor")) +' !important; stroke-opacity:0 !important}'+
               'app-header .bbb svg path:nth-of-type(2){fill:'+ JSON.parse(localStorage.getItem("primaryColor")) +' !important; stroke-opacity:0 !important}'+
               'app-header .bbb svg path:nth-of-type(3){fill:'+ JSON.parse(localStorage.getItem("primaryColor")) +' !important; stroke-opacity:0 !important}'+
            
              
              
               'app-header .ccc svg{fill:white !important; stroke-opacity:0 !important}'+
               'app-header .ccc svg rect{fill:white !important; stroke-opacity:0 !important}'+
               'app-header .ccc svg polygon:nth-of-type(n){fill:white !important; stroke-opacity:0 !important}'+
               'app-header .ccc svg polygon:nth-of-type(1){fill:white !important; stroke-opacity:0 !important}'+
               'app-header .ccc svg path:nth-of-type(1){fill:white !important; stroke-opacity:0 !important}'+
               'app-header .ccc svg path:nth-of-type(2){fill:white !important; stroke-opacity:0 !important}'+
               'app-header .ccc svg path:nth-of-type(3){fill:white !important; stroke-opacity:0 !important}'+
               'app-header .ccc svg path:nth-of-type(n){fill:white !important; stroke-opacity:0 !important}'+
                 'app-header .ccc svg polygon{fill:white !important; stroke-opacity:0 !important}'+
                'app-header .ccc svg path{fill:white !important; stroke-opacity:0 !important}'+
                'app-upload-document  .popup_button {background:'+ JSON.parse(localStorage.getItem("primaryColor")) +' !important; }'

            
            
                );

                

                // $('#Header').text('app-header .bbb svg{fill:' + JSON.parse(localStorage.getItem("primaryColor")) + ' !important;}'
            
            
                // );
            }


            }, 100);
             // EOC Erica (06)


       }

  ngOnInit() {
    
  }

  
  choosedoc(imgFileData) {
    this.file = imgFileData.target.files[0];
 }
 getTheNameOfDoc() {
    if (this.docname.trim().length == 0 || this.docname == undefined || this.docname == null) {
      this.disableChooseBtn = true
    } else {
      this.disableChooseBtn = false
    }
  }
    uploadDoc() {

      var docid
      var doctype
        docid = 4;
        doctype = 'Others'
        var LSDuserinfoProvider = JSON.parse(localStorage.getItem('UserData')).user;
        
        if (this.docname == undefined || this.docname == null || this.docname.trim().length == 0) {

          this.message = LSDuserinfoProvider.translations.error+": "+LSDuserinfoProvider.translations.enter_doc_name,
           
            this.openDialog()
         } else {
          if (this.file == undefined || this.file == "") {
           this.message = LSDuserinfoProvider.translations.error+": "+LSDuserinfoProvider.translations.doc_not_selected,
          
            this.openDialog()
          }
          
          var iti_id = localStorage.getItem("LStourListId");
          var extension = this.ext(this.file.name).toLowerCase();
          if (extension == '.jpg' || extension == '.png'
           || extension == '.jpeg' || extension == '.bmp' || extension == '.pdf' ||
            extension == '.docx' || extension == '.doc' || extension == '.gif' || extension == '.txt') {
            if (this.file.size > 10000000) {
                this.message =  LSDuserinfoProvider.translations.upload_Failed+": "+LSDuserinfoProvider.translations.size_5mb,
                
                this.openDialog()
                 } else {

                  this.hideSpinner=false

              let formData: FormData = new FormData();
              formData.append('image', this.file);
              formData.append('name', this.docname);
              formData.append('itinerary_id', iti_id);
              formData.append('user_uploaded', 'true');
              this.documentService.uploadDocuments(formData, 't', (data) => {
                if (data.document == 'success') {

                
                    // this.dialogRef.close("popopo"  );
                   
                  

                    this.message =  LSDuserinfoProvider.translations.doc_upload_success,
                    
                    this.openDialog()
                    // this.getdoc()
                    // this.ngOnInit()
                }
    
              })
            }
    
          } else {
            this.message =  LSDuserinfoProvider.translations.upload_Failed+": "+LSDuserinfoProvider.translations.file_not_supported,
          

           
            this.openDialog()
            //BOC (307) ERICA
           
        }
      }
 
    }



      ext(url) {
        return (url = url.substr(1 + url.lastIndexOf("/")).split('?')[0]).split('#')[0].substr(url.lastIndexOf("."))
      }

      getdoc() {

        console.log("in get Doc")
    
    
            // this.groupDocArr = this.document.GroupDocument(this.displayFeatures).all();
            // this.groupDocArr = this.document.all();
    
            this.documentService.all((data) => {
              this.groupDocArr = data
    console.log("GROUPD DOC ARR",data)
            this.docArrlength = this.groupDocArr.length;
            console.log("LENGTH===", this.docArrlength, this.groupDocArr.length)
    
            for (let z = 0; z < this.groupDocArr.length; z++) {
              this.groupDocArr[z].icon=this.DomSanitizer.bypassSecurityTrustResourceUrl(this.groupDocArr[z].icon)
              console.log("this.groupDocArr[z]====", this.groupDocArr[z])
    
              this.documentService.DocumentFactoryAll(this.groupDocArr[z].id, (data) => {
    
             
    
                if (data == 'document not found') {
    
                  this.docArr = [];
    
                } else if (data != 'error') {
                 // this.documentsAvailableForOfflineDownload = true //Midhun(10)
                  var docArr = [];
                  var doc_img = [];
                  var fileName = 'Documentsimg_'
    
                  for (let i = 0; i < data.length; i++) {
                    var extension = this.ext(data[i].image).toLowerCase();
                    console.log(extension);
                    if (extension == '.jpg' || extension == '.png' || extension == '.jpeg' || extension == '.bmp') {
                      data[i].showImage = "{'background-image':'url(" + data[i].image + ")', 'background-size': 'contain'}";
                    } else if (extension == '.doc' || extension == '.docx') {
                      data[i].showImage = "{'background-image':'url(\"assets/img/docs/doc_icon.png\")', 'background-size': 'initial'}";
                    } else if (extension == '.xls' || extension == '.xlsx') {
                      data[i].showImage = "{'background-image':'url(\"assets/img/docs/xls_icon.png\")', 'background-size': 'initial'}";
                    } else if (extension == '.ppt' || extension == '.pptx') {
                      data[i].showImage = "{'background-image':'url(\"assets/img/docs/ppt_icon.png\")', 'background-size': 'initial'}";
                    } else if (extension == '.pdf') {
                      data[i].showImage = "{'background-image':'url(\"assets/img/docs/pdf_icon.png\")', 'background-size': 'initial'}";
                    } else if (extension == '.txt') {
                      data[i].showImage = "{'background-image':'url(\"assets/img/docs/txt_icon.png\")', 'background-size': 'initial'}";
                    }
    
    
                    console.log("Outer Loop", i)
                    data[i].localurl = data[i].image
                    data[i].Islocal = 0;
                    data[i].docType = "";
    
                    if (window.localStorage.getItem('LSDDocumentList') != null) {
    
                      let p = 0;
    
                      for (let j = 0; j < (JSON.parse(window.localStorage.getItem('LSDDocumentList'))).length; j++) {
                        if (JSON.parse(window.localStorage.getItem('LSDDocumentList'))[j].id == data[i].id) {
                          console.log("if", j)
                          p = 1
                        }
    
                      }
    
    
                      if (p != 1) {
                        console.log("DATA(I)", data[i])
    
                        var N
                        N = JSON.parse(window.localStorage.getItem('LSDDocumentList'));
                        N.push(data[i])
                        window.localStorage.setItem('LSDDocumentList', JSON.stringify(N))
    
                        console.log("Final addition", JSON.parse(window.localStorage.getItem('LSDDocumentList')))
    
    
                      }
    
                    } else {
                      var arr = []
                      arr.push(data[i])
                      window.localStorage.setItem('LSDDocumentList', JSON.stringify(arr))
                      console.log("ONCE IN LIFE TIME", JSON.parse(window.localStorage.getItem('LSDDocumentList')))
    
                    }
    
    
    
                    console.log("Final i", i , " == " ,data.length )
                    if (i == data.length - 1 && z == this.groupDocArr.length - 1 )
                    {
                      console.log("Final list", JSON.parse(window.localStorage.getItem('LSDDocumentList')))
    
    
                      var LSDdocListoffline = JSON.parse(window.localStorage.getItem('LSDDocumentList'));
    
                      this.docArr=[]
                      if (LSDdocListoffline) {
                        for (let x = 0; x < LSDdocListoffline.length; x++) {
    
                          var extension = this.ext(LSDdocListoffline[x].image).toLowerCase();
                          console.log("#VVVVVVVV",extension);
                          if (extension == '.jpg' || extension == '.png' || extension == '.jpeg' || extension == '.bmp') {
                            LSDdocListoffline[x].icon_type ="img"
                          } else if (extension == '.doc' || extension == '.docx') {
                            LSDdocListoffline[x].icon_type ="doc"
                          } else if (extension == '.xls' || extension == '.xlsx') {
                            LSDdocListoffline[x].icon_type ="xls"
                          } else if (extension == '.ppt' || extension == '.pptx') {
                            LSDdocListoffline[x].icon_type ="ppt"
                          } else if (extension == '.pdf') {
                            LSDdocListoffline[x].icon_type ="pdf"
                          } else if (extension == '.txt') {
                            LSDdocListoffline[x].icon_type ="txt"
                          }
    
    
                            this.docArr.push(LSDdocListoffline[x])
                            this.allDocs =this.docArr
    
                            if (i == (LSDdocListoffline.length - 1) )
                            {
                              this.allDocs =this.docArr
                              console.log("this.docArr2",this.docArr)
                            }
    
                        }
    
    
                    }
    
    
                  }
    
    
    
    
                  }
                  console.log("DOC ARRAY", this.docArr)
                  localStorage.setItem("this.docArr",JSON.stringify(this.docArr))
                } else {
                  console.log('comming to error')
                }
    
    
    
    
    
                /*New CODE For DELETE Check From CMS*/
              //   var newLSDocAll = []
              //   newLSDocAll = JSON.parse(window.localStorage.getItem('LSDDocumentList'))
              //   if (newLSDocAll!= null)
              //   {
              //   for (let m = 0; m < newLSDocAll.length; m++) {
    
              //     console.log("Before Compare", newLSDocAll[m].type, this.groupDocArr[z].doctype)
    
              //     if (newLSDocAll[m].type == this.groupDocArr[z].doctype) {
              //       console.log("After Compare", newLSDocAll[m].type, this.groupDocArr[z].doctype)
              //       var x
              //       x = 0
              //       for (let n = 0; n < data.length; n++) {
              //         console.log("IN data loop=======", data[n].id)
              //         if (data[n].id == newLSDocAll[m].id) {
              //           console.log("ID FOUND", newLSDocAll[m].id)
              //           x = 1
              //           console.log("FOUND")
              //         }
              //       }
              //       if (x != 1) {
              //         console.log("THIS IS NOT PRESENT", newLSDocAll[m].id)
    
              //         newLSDocAll.splice(m, 1)
              //         window.localStorage.setItem("LSDDocumentList", JSON.stringify(newLSDocAll))
              
    
              //       }
              //     }
    
              //   }
              // }
                /*END OF NEW CODE */
    
              })
    
    
    
          }
    
          });
          }

          openDialog() {
            console.log("upload_doc Dialog open dailog",this.message )

            var LSDuserinfoProvider = JSON.parse(localStorage.getItem('UserData')).user;

            if(LSDuserinfoProvider.translations.doc_upload_success==this.message){
              this.hideSpinner=true
              this.dialogRef.close("succesful upload");
              // // this.component1.refresh_Data();
              // this.documentComponent.refresh_Data();
            }
            const dialogRef_alert = this.matDialog.open(AlertDialogDynamicComponent, {
                data: { message: this.message }
            });
        
            dialogRef_alert.afterClosed().subscribe(result => {
                console.log(`Dialog result: ${result}`);
            });
        }

        tourListProvider
        ngAfterViewInit() {
          console.log("NGONINIT")
          if (this.tourListProvider[index].brand.length == undefined) {
              var index = (localStorage.getItem('INDEX_WELCOME'))
              $('#extraCSS').text('.Doc_icon svg path{fill:' + this.tourListProvider[index].brand.color + ' !important;}' +
                  '.plus svg{fill:' + this.tourListProvider[index].brand.color + ' !important;}'
              );
          } else {
    
              $('#extraCSS').text('.Doc_icon svg path{fill:' + JSON.parse(localStorage.getItem("primaryColor")) + ' !important;}' +
                  '.plus svg{fill:' + JSON.parse(localStorage.getItem("primaryColor")) + ' !important;}'
              );
    
          }
      }

      success=" Successful upload"
        



}
