﻿import { Injectable } from '@angular/core';
import { Restangular } from 'ngx-restangular';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router'

import { AppUrls} from '../app-storagekeys-urls'

import { DataServiceService } from '../services/data-service.service'

@Injectable({
  providedIn: 'root'
})
export class TourService {

  Urls = AppUrls()
  currentUserData
  restHeader
  
  constructor(private restangular: Restangular, private http: HttpClient, private router:Router,
    private dataService:DataServiceService) {
   
  }

 

  //all(callback) {
  //    this.restangular.withConfig((RestangularConfigurer) => {
  //        RestangularConfigFactory(RestangularConfigurer)
  //    }).one(this.subUrlList).get({}).toPromise().then(function (response) {
  //        var rs = [];
  //        var result = response.itineraries;
  //        console.log("IN PROVIDER--------", result)
  //        var j = 0;
  //        for (var i = 0; i < result.length; i++) {
  //            result[i].dateStart = moment(result[i].dateStart).startOf('day').toDate();
  //            result[i].dateEnd = moment(result[i].dateEnd).startOf('day').toDate();
  //            rs[j] = result[i];
  //            j++;
  //        }
  //        sessionStorage.setItem('tourListProvider', JSON.stringify(rs));
  //        callback(rs);

  //    }, function (response) {
  //        console.log("SRS:  tourlist all()")
  //        loading.dismiss();
  //    });
  //}



  all(callback) {


      if ((localStorage.getItem("UserData")) != null) {
          this.currentUserData = JSON.parse(localStorage.getItem("UserData"));
      }

      var user_language = "";
      if (window.localStorage.getItem("selected_language_by_user") == null) {
          user_language = this.currentUserData.user.language;
      } else {
          user_language = window.localStorage.getItem("selected_language_by_user");
      }


      this.restHeader = this.currentUserData.user.email + ':' + this.currentUserData.token + ':' + user_language

    

      this.restangular.withConfig((RestangularConfigurer) => {
     
          RestangularConfigurer.setDefaultHeaders({
              'Content-Type': 'application/json',
              'Authorization': this.restHeader
          });
      }).one(this.Urls.TourList).get({}).toPromise().then((response) => {

    
          callback(response.itineraries)




     
         
      }, (err) => {
      
         

          //nav.setRoot('SignIn')// Commenting this did not solve the refresh bug
      });


  }



  show(callback) {
      // Getting itineries data of current booking organization
      var id = localStorage.getItem("LStourListId");

      if ((localStorage.getItem("UserData")) != null) {
          this.currentUserData = JSON.parse(localStorage.getItem("UserData"));
      }

      var user_language = "";
      if (window.localStorage.getItem("selected_language_by_user") == null) {
          user_language = this.currentUserData.user.language;
      } else {
          user_language = window.localStorage.getItem("selected_language_by_user");
      }

      
      this.restHeader = this.currentUserData.user.email + ':' + this.currentUserData.token + ':' + user_language




      this.restangular.withConfig((RestangularConfigurer) => {
          RestangularConfigurer.setDefaultHeaders({
              'Content-Type': 'application/json',
              'Authorization': this.restHeader
          });
      }).one(this.Urls.TourShow).get({
          itinerary_id: id
      }).toPromise().then(function (response) {
          localStorage.setItem("LSDitinerarydata", JSON.stringify(response));

    


          callback(response);
      }, function (response) {

      });

  }


  TipsFactoryAll(callback) {
      //GEtting all tips data
      var itinery_id = localStorage.getItem("LStourListId");

      if ((localStorage.getItem("UserData")) != null) {
          this.currentUserData = JSON.parse(localStorage.getItem("UserData"));
      }

      var user_language = "";
      if (window.localStorage.getItem("selected_language_by_user") == null) {
          user_language = this.currentUserData.user.language;
      } else {
          user_language = window.localStorage.getItem("selected_language_by_user");
      }

     
      this.restHeader = this.currentUserData.user.email + ':' + this.currentUserData.token + ':' + user_language

   



      this.restangular.withConfig((RestangularConfigurer) => {
          RestangularConfigurer.setDefaultHeaders({
              'Content-Type': 'application/json',
              'Authorization': this.restHeader
          });
      }).one(this.Urls.TipsSubUrl).get({
          itinerary_id: itinery_id
      }).toPromise().then(function(response) {
      
          callback(response);
      }, function(response) {

          });


      //this.restangular.withConfig((RestangularConfigurer) => {
      //    RestangularConfigurer.setDefaultHeaders({
      //        'Content-Type': 'application/json',
      //        'Authorization': this.restHeader
      //    });
      //}).one(this.Urls.TipsSubUrl).get({
      //    itinerary_id: itinery_id
      //}).toPromise().then(function(response) {
      //    //Sucess of getting data
      //    console.log("Tineri tips response", response )
      //    callback(response);
      //}, function(response) {
      //    // Error getting data
      //    console.log("Tineri tips error")
      //    callback('error')
      //});

  }

//BOC Added by Erica for Transport feature from scratch
  getTourListById(callback){

    var id = localStorage.getItem("LStourListId");

    if ((localStorage.getItem("UserData")) != null) {
        this.currentUserData = JSON.parse(localStorage.getItem("UserData"));
    }

    var user_language = "";
    if (window.localStorage.getItem("selected_language_by_user") == null) {
        user_language = this.currentUserData.user.language;
    } else {
        user_language = window.localStorage.getItem("selected_language_by_user");
    }


    this.restHeader = this.currentUserData.user.email + ':' + this.currentUserData.token + ':' + user_language


    this.restangular.withConfig((RestangularConfigurer) => {
      RestangularConfigurer.setDefaultHeaders({
              'Content-Type': 'application/json',
              'Authorization': this.restHeader
          });
    }).one(this.Urls.subUrl).get({
      itinerary_id: id
    }).toPromise().then(function (response) {
   
      callback(response);
    }, function (err) {

      callback('error');
    });


  }
  //EOC Added by Erica for Transport feature from scratch



}
