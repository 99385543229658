﻿import { PassSelectedTransportService } from './../services/pass-selected-transport.service';
import { TourService } from './../services/tour.service';
import { TransportService } from './../services/transport.service';
import { Component, OnInit } from '@angular/core';
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";
import { ViewChild,} from '@angular/core';
import { MatAccordion } from '@angular/material';
import * as $ from 'jquery';




@Component({
  selector: 'app-transport',
  templateUrl: './transport.component.html',
  styleUrls: ['./transport.component.css']
})
export class TransportComponent implements OnInit {

  displayFeatures
  transport_array =[]
  flight_DFt
  flight_array
  transport_DFt
  translationsLabels
  expand=0
  show_scroll_up_btn=true;
@ViewChild('accordion') Accordion: MatAccordion;

transport 

transport_id

day

day_id

header_img

  constructor(private transportation : TransportService,
     public tourService: TourService,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private pass_transport_service:PassSelectedTransportService) { 

      this.header_img = JSON.parse(localStorage.getItem("LSDitinerarydata")).itinerary.imgSrc


      this.day=this.pass_transport_service.getDay()
      this.transport=this.pass_transport_service.getTransport()

      this.pass_transport_service.setData(undefined,undefined)

      if(this.day !=undefined && this.transport !=undefined ){
        this.day_id = this.day.day
        this.transport_id = this.transport.id+this.day.day

        setTimeout(()=>{
          var x = $("#"+this.transport_id).position();
       console.log(" PASSSTTTT  Top: " + x.top + " Left: " + x.left);


        window.scrollTo(x.left, x.top)


     },1000);


      }

    

      

      console.log("PASSSTTTT             ",this.day)
      console.log("PASSSTTTT             ",this.transport)
      console.log("PASSSTTTT             ",this.transport_id)

      console.log("PASSSTTTT             ",this.day_id)
      

   

 setTimeout(()=>{this.check_height()},100) 

    }

   

    check_height(){

      
     
        if($(window).height()>= $(".mainContainer").height()+250){
          this.show_scroll_up_btn=false;
        }else if($(window).height()< $(".mainContainer").height()+250){
          this.show_scroll_up_btn=true;
        }
  
       
      }

  

  ngOnInit() {
      this.svgregister();
    this.translationsLabels=""
    var userinfo = JSON.parse(localStorage.getItem("UserData"))
    this.translationsLabels = JSON.parse(localStorage.getItem("UserData")).user.translations

    this.tourService.show(data => {

      {
          this.displayFeatures = data.itinerary.display_feature;
          localStorage.setItem("display_feature",JSON.stringify(data.itinerary.display_feature));

          if (localStorage.getItem("user_role") != "Travellers" && localStorage.getItem("user_role") != "Admin" && localStorage.getItem("user_role") != "Guides") {
            this.displayFeatures.itinerary.survey = false;

          }
       
        }

    
     
  });





    this.loadFlightsData().then((flights_array: any) => {
    
      this.loadTransportData();
    });



    
  }

  loadTransportData() {
    this.transport_array = [];
    {
      //online data
      this.transportation.transportsAll(data => {
        this.getTransportContent(data)
      })


    }
  }

  loadFlightsData() {
    this.flight_array = [];

    return new Promise((resolve) => {

   {
      //online data
      this.transportation.flightsAll(data => {
        this.getFlightsContent(data).then((flights_array: any) => {
          resolve(flights_array);
        });
      });
    }

   

  });


  }

  getFlightsContent(data) {

    return new Promise((resolve) => {
    {
    var startDate = localStorage.getItem("booking_start_date");

    var flightDate = new Date(startDate);

    var flightsData = []

    for (var i = 0; i < data.length; i++) {

      if (data[i].flight_info.length > 0) {
        var flight_info = data[i].flight_info;


        var newdate = new Date(flightDate);


        var dd = flight_info[0].flight_start_date
        if(dd.length==1){
          dd="0"+dd
        }
        var mm = flight_info[0].flight_start_month
        var y = flight_info[0].flight_start_year



        var month = new Array();
        month[0] = "January";
        month[1] = "February";
        month[2] = "March";
        month[3] = "April";
        month[4] = "May";
        month[5] = "June";
        month[6] = "July";
        month[7] = "August";
        month[8] = "September";
        month[9] = "October";
        month[10] = "November";
        month[11] = "December";

        var formattedDate = dd + '-' + mm + '-' + y;
        var formattedDate1 = dd + '-' + mm + '-' + y;



        let monthName = formattedDate.split('-')[1]; // changed from '' to '-' by Irshad/midhun(01)
      

        for (var j = 0; j < flight_info.length; j++) {


          var link = flight_info[j].logo;

          var uri
          if (link.indexOf('%') != -1) {
            uri = link;
          } else {
            uri = encodeURI(link);
          }
          //EOC Irshad(01)


          var fileName = link.replace(/\?.*$/, "").replace(/.*\//, "");


          
          var fileURL


          var local_logo
          var i_=i
          var j_=j
          var day_number= data[i_].day_number;



          if (flight_info.length != 0) {
            //**** */
            data[i].flight_info.day_number = data[i].day_number;
            data[i].flight_info.flight_id = flight_info[j].flight_id;
            data[i].flight_info.flight_date = formattedDate;
            data[i].flight_info[j].newflight_date = formattedDate1;
            data[i].flight_info[j].local_logo = local_logo;
            data[i].flight_info[j].flight_date = formattedDate;

            data[i].flight_info[j].type = "Flight";
            data[i].flight_info[j].trans_date = formattedDate;



          }

        }
        flightsData.push(data[i])
      }
    }


    this.flight_array = flightsData;

    console.log("Data flight ", this.flight_array)

    this.flight_array.sort((a, b) => {
      return parseFloat(a.day_number) - parseFloat(b.day_number);
    });

    resolve(this.flight_array);
  }

});

  }

  getTransportContent(data) {

    console.log("DATA TRansport-----", data.transportations)
    var transportation_details = data.transportations
    var startDate = localStorage.getItem("booking_start_date");
    console.log(startDate);
    var transportDate = new Date(startDate);
    var transportData = []

    for (var i = 0; i < transportation_details.length; i++) {

      if (transportation_details[i].transportation_json.length > 0) {
        var transport_info = transportation_details[i].transportation_json
        var newdate = new Date(transportDate);
        newdate.setDate(newdate.getUTCDate() + (transportation_details[i].day_number - 1));




        var dd = transport_info[0].transport_start_date
        var mm = transport_info[0].transport_start_month
        var y = transport_info[0].transport_start_year

        var month = new Array();
        month[0] = "January";
        month[1] = "February";
        month[2] = "March";
        month[3] = "April";
        month[4] = "May";
        month[5] = "June";
        month[6] = "July";
        month[7] = "August";
        month[8] = "September";
        month[9] = "October";
        month[10] = "November";
        month[11] = "December";

        var formattedDate = dd + '-' + mm + '-' + y;


        let monthName = formattedDate.split('-')[1]; // changed from '' to '-' by Irshad/midhun(01)


        for (var j = 0; j < transport_info.length; j++) {

          if (transport_info.length != 0) {
            console.log("CHECKING TRANSPORT INFO---", transport_info)
            transport_info.day_number = transportation_details[i].day_number;
            transport_info.type = transport_info[j].type;
            transport_info.name = transport_info[j].name;
            transport_info.id = transport_info[j].id;
            transport_info.trans_date = formattedDate;
            transport_info[j].trasnport_date = formattedDate;
          }
        }
        transportData.push(transportation_details[i])
      }
    }





    this.transport_array = transportData;
    this.sortByDay();
    
    this.create_transport_array();
  }


  create_transport_array(){

    var LSDItineraryallOffline 


    this.tourService.getTourListById(data=>{

      var itineraryDayTrips = data.itinerary.dayTrips;

      LSDItineraryallOffline= itineraryDayTrips;

      
    // BOC Erica (01)
    console.log("List2Show:: transport combine",localStorage.getItem("display_feature"))

    {
    this.displayFeatures = JSON.parse(localStorage.getItem("display_feature"));
   //BOC ERICA (02)
   if(this.displayFeatures!=undefined){
    this.flight_DFt=this.displayFeatures.transportations.flights_main;
    this.transport_DFt=this.displayFeatures.transportations.transporters_main;
    console.log("List2Show:: transport flight_DFt",this.flight_DFt);
    console.log("List2Show:: transport transport_DFt",this.transport_DFt)

   }
   //BOC ERICA (02)
  }
// EOC Erica (01)

    var transport_All=[];

    for(var i=1;i<=LSDItineraryallOffline.length;i++){
      var  b = this.transport_array.find(arr => arr.day_number == i)
       var c = this.flight_array.find(arr => arr.day_number == i)

       var day_itinery = LSDItineraryallOffline.find(arr=>arr.day == i)

       var day_name = day_itinery.name

     


      if(b!=undefined && c!=undefined)
      {

        var date1 =b.transportation_json.trans_date

        var date_update = date1.replace(/-/g, ' ');

        

      var d=b.transportation_json.concat(c.flight_info );

      //(02) BOC ERICA
      if(this.transport_DFt !=undefined && this.flight_DFt !=undefined){
        if(this.transport_DFt && this.flight_DFt){
          d=b.transportation_json.concat(c.flight_info );
        }else if(!this.transport_DFt && this.flight_DFt){
          d=c.flight_info ;
        }else if(this.transport_DFt && !this.flight_DFt){
          d=b.transportation_json ;
        }
      }


      transport_All.push({day_number:i,transportation_json:d,
        date:date_update,
      day_name:day_name})

      }else if(b!=undefined && c==undefined){

        var date2 =b.transportation_json.trans_date

        var date_update2 = date2.replace(/-/g, ' ');


      if(this.transport_DFt !=undefined ){

        if(this.transport_DFt){
          transport_All.push({day_number:i,
            transportation_json:b.transportation_json,
            date:date_update2, day_name:day_name})
        }
      }


      }
      else if(b==undefined && c!=undefined){

        var date3 =c.flight_info[0].trans_date

        var date_update3 = date3.replace(/-/g, ' ');

         //(02) BOC ERICA
        if(this.flight_DFt !=undefined ){

          if(this.flight_DFt){
            transport_All.push({day_number:i,
              transportation_json:c.flight_info,date:date_update3, day_name:day_name})

          }
        }

      }

  }


  this.transport_array=transport_All;

    });








}

sortByDay() {
  this.transport_array.sort((a, b) => {
    return parseFloat(a.day_number) - parseFloat(b.day_number);
  });
}

svgregister() {

 

  //Transport Erica BOC From Scratch
  this.matIconRegistry.addSvgIcon(
      "bus",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/Images/transport/bus.svg")
  );

  this.matIconRegistry.addSvgIcon(
      "ferry",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/Images/transport/ferry.svg")
  );

  this.matIconRegistry.addSvgIcon(
      "cablecar",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/Images/transport/cablecar.svg")
  );

  this.matIconRegistry.addSvgIcon(
      "train",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/Images/transport/train.svg")
  );

  this.matIconRegistry.addSvgIcon(
      "other",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/Images/transport/others.svg")
  );

  
  this.matIconRegistry.addSvgIcon(
      "shuttle",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/Images/transport/shuttle.svg")
  );


  this.matIconRegistry.addSvgIcon(
      "flight",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/Images/transport/flights.svg")
  );

  this.matIconRegistry.addSvgIcon(
      "flight-arraival",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/Images/transport/flight_arrives.svg")
  );

  this.matIconRegistry.addSvgIcon(
      "flight-departure",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/Images/transport/flight_departs.svg")
  );

  this.matIconRegistry.addSvgIcon(
    "itinerarycalender",
    this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/Images/svg/itinerary-list.svg")
);


  //Transport Erica EOC From Scratch
  

}

closeAllPanels() {
  this.expand = 0
  this.Accordion.closeAll();

}

openAllPanels() {
  this.expand = 1
  this.Accordion.openAll();
}

togglePanel(transport,value){
  transport.panelOpenState = value;
  const anyOpen = this.transport_array.some(day => day.panelOpenState);
  
  if (anyOpen){
      this.expand = 1
  }else{
      this.expand = 0
  }
}

scroll_up(){
  
  $(window).scrollTop( $("body").offset().top );
}

trigger(){
  this.check_height();
}


}
