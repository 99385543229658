﻿import { InsidePagesGuard } from './inside-pages.guard';
import { AuthLoggedInGuard } from './auth-logged-in.guard';
import { AuthGuard } from './auth.guard';
import { AuthGuardService } from './auth-guard.service';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { MatCardModule } from '@angular/material';




import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { WelcomeComponent } from './welcome/welcome.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
////import { MatDialogModule } from '@angular/material/dialog';
import { MaterialModule } from './material-module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RestangularModule, RestangularHttp, Restangular } from 'ngx-restangular';
import { AppLocalStorageKeys, AppRootUrls } from './app-storagekeys-urls';;
import { HeaderComponent } from './header/header.component';
import { HomeComponent } from './home/home.component';
import { ItineraryComponent } from './itinerary/itinerary.component';
import { HotelComponent } from './hotel/hotel.component';
import { GuidesComponent } from './guides/guides.component';
import { ForgotpasswordComponent } from './forgotpassword/forgotpassword.component';
import { AlertDailogComponent } from './alert-dailog/alert-dailog.component';
//import { MatDialog} from '@angular/material/dialog';
//import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { MealsComponent } from './meals/meals.component';
import { ExperiencesComponent } from './experiences/experiences.component';

import { ChangeLanguageComponent } from './change-language/change-language.component'
;
import { TransportComponent } from './transport/transport.component'
;
import { SafePipe } from './safe.pipe'
;
import { CustomComponent } from './custom/custom.component';
import { SubCustomMenuComponent } from './sub-custom-menu/sub-custom-menu.component'
;
import { RefreshComponent } from './services/refresh/refresh.component'
import {HttpModule} from '@angular/http';;
;
import { ExtraComponent } from './extra/extra.component'
;
import { DocumentComponent } from './document/document.component'
;
import { DocumentListComponent } from './document-list/document-list.component'
;
import { DocumentViewComponent } from './document-view/document-view.component'
;
import { ShowAllTravellerDocsComponent } from './show-all-traveller-docs/show-all-traveller-docs.component'
;
import { UploadDocumentComponent } from './upload-document/upload-document.component';
import { DeleteDocumentComponent } from './delete-document/delete-document.component';;
import { TineriTipComponent } from './tineri-tip/tineri-tip.component'
;
import { EndOfTourComponent } from './end-of-tour/end-of-tour.component'
// import { FormioModule } from 'angular-formio';
// import { FormioModule } from 'angular-formio';

import {MatSliderModule} from '@angular/material/slider';;
import { DailySurveyComponent } from './daily-survey/daily-survey.component'
;
import { ContactsComponent } from './contacts/contacts.component'
;
import { ContactDetailsComponent } from './contact-details/contact-details.component'
;
import { ContactUsComponent } from './contact-us/contact-us.component'
;
import { ContactEmergencyComponent } from './contact-emergency/contact-emergency.component'
;
import { ContactUsefulComponent } from './contact-useful/contact-useful.component';;
import { SingleItineraryPdfComponent } from './single-itinerary-pdf/single-itinerary-pdf.component'
;
import { HomePdfComponent } from './home-pdf/home-pdf.component';;
import { AlertDialogDynamicComponent } from './alert-dialog-dynamic/alert-dialog-dynamic.component'



export function RestangularConfigFactory(RestangularProvider) {
    // console.log(AppLocalStorageKeys().restHeader);
    // console.log(window.localStorage.getItem(AppLocalStorageKeys().restHeader));

    RestangularProvider.setBaseUrl(AppRootUrls().stagingUrl);

    if (window.localStorage.getItem('restHeader')) {
        RestangularProvider.setDefaultHeaders({
            'Content-Type': 'application/json',
            'Authorization': window.localStorage.getItem('restHeader')
        });
    } else {
        RestangularProvider.setDefaultHeaders({
            'Content-Type': 'application/json',
        });
    }


}
//import {MatCardModule} from '@angular/material';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    WelcomeComponent,
    HeaderComponent ,
    HomeComponent ,
    ItineraryComponent,
    HotelComponent,
    GuidesComponent,
    ForgotpasswordComponent ,
      AlertDailogComponent,
      ExperiencesComponent,
      MealsComponent,
       ChangeLanguageComponent,
       TransportComponent,
       SafePipe,
       CustomComponent ,
       SubCustomMenuComponent ,
       RefreshComponent ,
       ExtraComponent,
       DocumentComponent,
       DocumentListComponent,
       DocumentViewComponent,
       ShowAllTravellerDocsComponent,
       UploadDocumentComponent,
       DeleteDocumentComponent,
       TineriTipComponent,
       EndOfTourComponent,
       DailySurveyComponent,
       ContactsComponent ,
       ContactDetailsComponent ,
       ContactUsComponent ,
       ContactEmergencyComponent ,
       ContactUsefulComponent ,
       SingleItineraryPdfComponent ,
       HomePdfComponent ,
       AlertDialogDynamicComponent,

    ],
      
 

  entryComponents: [
    AlertDialogDynamicComponent,
    AlertDailogComponent, 
    ChangeLanguageComponent,
    UploadDocumentComponent,//Added By Erica
    DeleteDocumentComponent,//Added By Erica
    TineriTipComponent,//Added By Erica
   ],
  imports: [
    BrowserModule,
    AppRoutingModule,
      BrowserAnimationsModule, 
      MaterialModule, 
      FormsModule, 
      ReactiveFormsModule,
      RestangularModule.forRoot(RestangularConfigFactory),
       
      HttpModule,
      // FormioModule,
      MatSliderModule,
     
      // FormioModule,// Erica Added
  ],
  providers: [AlertDailogComponent, ChangeLanguageComponent,AuthGuard, AuthLoggedInGuard, InsidePagesGuard
   
      ],
  bootstrap: [AppComponent],

})
export class AppModule { }


