﻿import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators, FormGroupDirective, NgForm } from '@angular/forms';
import { AuthenticationService } from '../services/authentication.service'
import { Router } from '@angular/router'
import { MatDialog } from '@angular/material/dialog';
import { AlertDailogComponent } from '../alert-dailog/alert-dailog.component';


@Component({
  selector: 'app-forgotpassword',
  templateUrl: './forgotpassword.component.html',
  styleUrls: ['./forgotpassword.component.css']
})
export class ForgotpasswordComponent implements OnInit {
    SigninForm: FormGroup
    message
    hideSpinner = true
    constructor(fb: FormBuilder, public authService: AuthenticationService, private router: Router, private matDialog: MatDialog)

    {

        this.SigninForm = fb.group({
            email: new FormControl("", Validators.compose([
                Validators.required,
                Validators.email
            ]))
          
        })
    }

  ngOnInit() {
  }

  onClickSubmit(signinForm) {

      this.hideSpinner = false
      if (signinForm.email == '' || signinForm.email == undefined) {

          this.message = "Email field is empty."
          this.hideSpinner = true
          this.openDialog()
      }
      else
      {
          setTimeout(() => {
              this.hideSpinner = true
              console.log("inside sec timer2", this.hideSpinner)
          }, 2000);

          console.log("Form values:", signinForm)
          this.authService.password(signinForm)
      }


    

  }



  cancel()
  {
      this.router.navigate([''])
  }


  openDialog() {
      console.log("open dailog")
 

      const dialogRef = this.matDialog.open(AlertDailogComponent, {
          data: { message: this.message }
      });

      dialogRef.afterClosed().subscribe(result => {
          console.log(`Dialog result: ${result}`);
      });
  }



}
