import { UserIdService } from './../services/user-id.service';
import { Component, OnInit } from '@angular/core';
import { DocumentService } from './../services/document.service';
import { Router } from '@angular/router';
import * as $ from 'jquery';//(01)


@Component({
  selector: 'app-show-all-traveller-docs',
  templateUrl: './show-all-traveller-docs.component.html',
  styleUrls: ['./show-all-traveller-docs.component.css']
})
export class ShowAllTravellerDocsComponent implements OnInit {

  header_img// Bug sinclaire africa

  constructor(private documentService: DocumentService, private router:Router,
    private userIdService:UserIdService) {

      this.header_img = JSON.parse(localStorage.getItem("LSDitinerarydata")).itinerary.imgSrc // Bug sinclaire africa


      setTimeout(()=>{this.check_height()},100) 

    }

   

    check_height(){

     
        if($(window).height()>= $(".mainContainer").height()+250){
          this.show_scroll_up_btn=false;
        }else if($(window).height()< $(".mainContainer").height()+250){
          this.show_scroll_up_btn=true;
        }
  
      
      }

    show_scroll_up_btn=true;
  ngOnInit() {

    this.getUsers();
  }

  AlldocCount

  allTravellers

  users
  getUsers() {
    
    var allUsersArr = []

    this.documentService.GetAllUsers((data) => {
      if (data.status == 404) {

      } else {

   

        if(data!="error"){
          for (let key in data.users.users_array) {

            allUsersArr.push(data.users.users_array[key])
  
  
            this.AlldocCount = this.AlldocCount + parseInt(data.users.users_array[key].document_size)
          }
  
        
  
  
          this.allTravellers = this.setTravellersWithNameSort(allUsersArr)
  
          let travelersArr = []
  
          if (this.allTravellers.length > 20) {
            for (let j = 0; j < 20; j++) {
              travelersArr.push(this.allTravellers[j])
            }
          } else {
            travelersArr = allUsersArr
          }
  
          this.users = travelersArr

          setTimeout(()=>{this.check_height()},100) 
  
       
        }
      
        

      }
    })

  }

  setTravellersWithNameSort(allUsersArr) {
    return allUsersArr.sort((a, b) => {
      a = a.fullname.replace(" ", "").toLowerCase().trim();
      a = a.toLowerCase().trim();
      b = b.fullname.replace(" ", "").toLowerCase().trim();
      b = b.toLowerCase().trim();
      return a < b ? -1 : a > b ? 1 : 0;
    })
  }

 
  goToDoclist(userUid) {
    console.log("userUid Selected:", userUid)
   



    this.router.navigate(['document']);

        
    this.userIdService.setData(userUid);


   

  }

  scroll_up(){
        
    $(window).scrollTop( $("body").offset().top );
}

}
