﻿import { ContactService } from './../services/contact.service';
//(01) Erica : Display Feature :  Implementation
//02: HRUSHIKESH 28-May-2020 Organization color change
//(03): Erica : BUG detected in Prod Build displayStyles was refrencing index outside ngfor hnace would not let prob build
import { Component, OnInit } from '@angular/core';
import { TourService } from '../services/tour.service'
import * as moment from 'moment';
import * as $ from 'jquery';
import { Router } from '@angular/router'
import { HotelService } from '../services/hotel.service'
import { GuideService } from '../services/guide.service'
import { ExperiencesService } from '../services/experiences.service'
import { MealsService } from '../services/meals.service'
import { AuthenticationService } from '../services/authentication.service';
import { DomSanitizer  } from "@angular/platform-browser";
import { MatIconRegistry } from "@angular/material/icon";

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.css']
})
export class WelcomeComponent implements OnInit {
    logo
    web_background
    traveller
    tourlist = []
    tourlistsaved = []
    leftPos = 0
    tourListData
    count
    allTourListData
    numBookingLength
    tourHash
    user_role
    oldTourListData
    futureTourListData
    currentTourListData
    translationsLabels
    timercall
    seccount = 60
    hideSpinner = true
    deactivate_timer=false


    aspect_ratio
    isShowArrows =true;
    index_for_color=0
    
  //@ViewChild('targetright', 'targetLeft') myDivRef: ElementRef;


    constructor(public tourService: TourService, 
        private router: Router, 
        public hotelService: HotelService,
         public GuideService: GuideService
        , public experiencesService: ExperiencesService,
         public mealsService: MealsService, 
         public authService: AuthenticationService,
         private matIconRegistry: MatIconRegistry,
        private domSanitizer: DomSanitizer,
         public contactService: ContactService) {


        var userinfo = JSON.parse(localStorage.getItem("UserData"))

        var companyinfo = JSON.parse(localStorage.getItem("companyProvider"))

       this.user_role = JSON.parse(localStorage.getItem("UserData")).user.role


       {
         
        $('#extraCSS').text(
        
            '.background_pg { background-image: url( '+JSON.parse(localStorage.getItem("companyProvider")).web_background+' ) !important }'


            );

        
    
    } 
   

     
        
        




     this.logo = JSON.parse(localStorage.getItem("companyProvider")).logo
     this.web_background = JSON.parse(localStorage.getItem("companyProvider")).web_background
       this.traveller = userinfo.user.fullname.trim();
       this.translationsLabels = userinfo.user.translations

    

       this.svgregister();

       var scope =this

       $(window).resize(function() {

    
       setTimeout(()=>{scope.check_aspect_ratio()},200)
      });

       
    
    }

  


    check_aspect_ratio(){

        this.aspect_ratio = $(window).width()/$(window).height();

        

        if(this.aspect_ratio>=1  && this.tourlist.length<=2){
            this.isShowArrows =false;
        }else if(this.aspect_ratio>=1  && this.tourlist.length>2){
            this.isShowArrows =true;
        }

        if(this.aspect_ratio<1  && this.tourlist.length<=1){
            this.isShowArrows =false;
        }else if(this.aspect_ratio<1  && this.tourlist.length>1){
            this.isShowArrows =true;
        }
     
       
       
      }

    changeStyle($event,index){

        this.index_for_color=index
        //02: BOC HRUSHIKESH := Organization color applied if exist . Else company primary color applied
    
      
        var col = localStorage.getItem('primaryColor')
    
        if (this.tourlistsaved[index].brand.color != undefined) {
      
            $('#extraCSS').text(
                '.button:hover { border-color:' + this.tourlistsaved[index].brand.color + ' !important;}' +
                '.icon:hover { color:' + this.tourlistsaved[index].brand.color + ' !important;}' +
                '.card:hover { box-shadow:  0 2px 2px 0' + this.tourlistsaved[index].brand.color + ' !important;}' +
                '.card:hover { border:  1px solid' + this.tourlistsaved[index].brand.color + ' !important;}'+
                
                '.background_pg { background-image: url('+JSON.parse(localStorage.getItem("companyProvider")).web_background+') !important }'


            );
            this.logo=this.tourlistsaved[index].brand.logo

          

            // this.web_background = JSON.parse(localStorage.getItem("companyProvider")).web_background

      
      
      
        } else {
            $('#extraCSS').text(
                '.button:hover { border-color:' + JSON.parse(localStorage.getItem("primaryColor")) + ' !important;}' +
                '.icon:hover { color:' + JSON.parse(localStorage.getItem("primaryColor")) + ' !important;}' +
                '.card:hover { box-shadow: 0 2px 2px 0' + JSON.parse(localStorage.getItem("primaryColor")) + ' !important;}' +
                '.card:hover { border:  1px solid' + JSON.parse(localStorage.getItem("primaryColor")) + ' !important;}'+
                '.background_pg { background-image: url('+JSON.parse(localStorage.getItem("companyProvider")).web_background+') !important }'

            );
            this.logo=JSON.parse(localStorage.getItem("companyProvider")).logo

          
        
          
            }


          //EOC HRUSHIKESH
            }

            // (03): BOC Erica Solution

      

        changeStyleArrow($event){
      
            
            var col = localStorage.getItem('primaryColor')
         
            if (this.tourlistsaved[this.index_for_color].brand.color != undefined) {
         
                $('#extraCSS').text(
                    '.button:hover { border-color:' + this.tourlistsaved[this.index_for_color].brand.color + ' !important;}' +
                    '.icon:hover { color:' + this.tourlistsaved[this.index_for_color].brand.color + ' !important;}' +
                    '.card:hover { box-shadow:  0 2px 2px 0' + this.tourlistsaved[this.index_for_color].brand.color + ' !important;}' +
                    '.card:hover { border:  1px solid' + this.tourlistsaved[this.index_for_color].brand.color + ' !important;}'
                    +
                    '.background_pg { background-image: url( '+JSON.parse(localStorage.getItem("companyProvider")).web_background+' ) !important }'


                    );
                this.logo=this.tourlistsaved[this.index_for_color].brand.logo

                
            
            } else {
                $('#extraCSS').text(
                    '.button:hover { border-color:' + JSON.parse(localStorage.getItem("primaryColor")) + ' !important;}' +
                    '.icon:hover { color:' + JSON.parse(localStorage.getItem("primaryColor")) + ' !important;}' +
                    '.card:hover { box-shadow:  0 2px 2px 0' + JSON.parse(localStorage.getItem("primaryColor")) + ' !important;}' +
                    '.card:hover { border:  1px solid' + JSON.parse(localStorage.getItem("primaryColor")) + ' !important;}'
                    +
                    '.background_pg { background-image: url('+JSON.parse(localStorage.getItem("companyProvider")).web_background+') !important }'

                    
            
                );
                this.logo=JSON.parse(localStorage.getItem("companyProvider")).logo

             
                
                }
                }

         // (03): EOC Erica Solution

    ngOnInit() {

     this.loaddata()

    }


    scroll(el: HTMLElement) {
        el.scrollIntoView({ behavior: 'smooth', block :'center',  inline: "nearest" });
       
    }

    scrollL() {

  
           this.leftPos = $('.inner-container').scrollLeft();
            //this.leftPos = 0
            $(".inner-container").animate({
                scrollLeft: this.leftPos + 290
            }, 200);
    }

    scrollR() {

   

        this.leftPos = $('.inner-container').scrollLeft();
        //this.leftPos = 0
        $(".inner-container").animate({
            scrollLeft: this.leftPos - 290
        }, 200);
    }
  


    loaddata()
    {
        let scope = this
        setInterval(function () {
        

            scope.seccount = scope.seccount - 1
            if (scope.seccount == 1) {

                scope.seccount = 60
            }

        }, 1000);


        this.tourService.all(data => {

         
            this.tourlist = data
            this.tourlistsaved = data
            
      
           
            this.numBookingLength = data.length
            
            this.tourHash = data

            localStorage.setItem('tourListProvider', JSON.stringify(data)); //Irshad(01)

            
          
            this.user_role = JSON.parse(localStorage.getItem("UserData")).user.role

          
            this.tourListData = [];
            this.allTourListData = [];
            // (05) BOC Erica
            this.oldTourListData = [];
            this.futureTourListData = [];
            this.currentTourListData = [];

            // (05) EOC Erica
            var count = 0;
            var tourList = data.length

            localStorage.setItem("LSDTourListLength", tourList)
          





            for (var i = 0; i < tourList; i++) {


              
          
                count = count + 1;
                var IDate

                var StartDate = data[i].dateStart;


                var EndDate = data[i].dateEnd;

                let monthS = data[i].iti_start_month;
                let monthE = data[i].iti_end_month;

                let YearS = data[i].iti_start_year;
                let YearE = data[i].iti_end_year;

                var dStart
                var dEnd

                if (monthS == monthE) {
                    dStart = moment(StartDate).format("DD");
                    dEnd = moment(EndDate).format("DD MMMM YYYY");
                    let monthName = dEnd.split(' ')[1];
                    dEnd = dEnd.replace(monthName, this.translationsLabels[monthName.toLowerCase()]);

                    IDate = dStart + "-" + dEnd
                    

                } else {
                    dStart = moment(StartDate).format("DD MMMM");
                    let monthNameStart = dStart.split(' ')[1];
                    dStart = dStart.replace(monthNameStart, this.translationsLabels[monthNameStart.toLowerCase()]);

                    dEnd = moment(EndDate).format("DD MMMM YYYY");
                    let monthName = dEnd.split(' ')[1];
                    dEnd = dEnd.replace(monthName, this.translationsLabels[monthName.toLowerCase()]);

                    IDate = dStart + "-" + dEnd

                }


                if (YearS != YearE) {
                    dStart = moment(StartDate).format("DD MMMM YYYY");
                    let monthNameStart = dStart.split(' ')[1];
                    dStart = dStart.replace(monthNameStart, this.translationsLabels[monthNameStart.toLowerCase()]);

                    dEnd = moment(EndDate).format("DD MMMM YYYY");
                    let monthName = dEnd.split(' ')[1];
                    dEnd = dEnd.replace(monthName, this.translationsLabels[monthName.toLowerCase()]);

                    IDate = dStart + "-" + dEnd
                }



               
                data[i].formatDate = IDate;
                this.tourlist[i].formatDate = IDate;
                this.tourlistsaved[i].formatDate = IDate;
                localStorage.removeItem('tourListHeaderImage' + data[i].id);
      
              
                var DDate = this.tourlist[i].iti_start_date
                var MDate = this.tourlist[i].iti_start_month
                var YDate = this.tourlist[i].iti_start_year
                var Time = this.tourlist[i].trip_start_time + ":00"
               


    

                //function timeBetweenDates(DDate, MDate, YDate) {
          
                    var now = new Date();

                    var Bndate = DDate + ' ' + MDate + ' ' + YDate + ' ' + Time
                    let HDTime = moment(DDate + ' ' + MDate + ' ' + YDate + ' ' + Time)


                  

                    var difference = moment(HDTime)['_d'] - moment(now.getTime())['_d'];

                   
                 
                    if (difference <= 0) {
                        // Timer done

                        this.tourlistsaved[i].timer = 0
                        this.tourlist[i].dayst = 0

                        

                    } else {

                        this.tourlistsaved[i].timer = 1
                        this.tourlist[i].dayst = 1

                        //var seconds = Math.floor(difference / 1000);
                        //var minutes = Math.floor(seconds / 60);
                        //var hours = Math.floor(minutes / 60);
                        //var days = Math.floor(hours / 24);

                        var seconds = difference / 1000;
                        var minutes = seconds / 60;
                        var hours = minutes / 60;
                        var days = hours / 24;


                     
                        
                        hours %= 24;
                        minutes %= 60;
                        seconds %= 60;
                        let new_seconds
                        let new_minutes
                        let new_hours
                        let new_day
                        if (seconds < 10) {
                            new_seconds = "0" + seconds
                        } else if (seconds >= 10) {
                            new_seconds = seconds
                        }

                        if (minutes < 10) {
                            new_minutes = "0" + minutes
                        } else if (minutes >= 10) {
                            new_minutes = minutes
                        }

                        if (hours < 10) {
                            new_hours = "0" + hours
                        } else if (hours >= 10) {
                            new_hours = hours
                        }

                        if (days < 10) {
                            new_day = "0" + days
                        } else if (days >= 10) {
                            new_day = days
                        }


                     
                        this.tourlistsaved[i].dayst = Math.floor(new_day)
                        this.tourlistsaved[i].hourst = Math.floor(new_hours)
                        this.tourlistsaved[i].minutest = Math.floor(new_minutes)
                        this.tourlistsaved[i].secst = Math.floor(new_seconds)


                        this.tourlist[i].dayst = Math.floor(new_day)
                        this.tourlist[i].hourst = Math.floor(new_hours)
                        this.tourlist[i].minutest = Math.floor(new_minutes)
                        this.tourlistsaved[i].secst = Math.floor(new_seconds)

                        //Danger

                        setTimeout(()=>{this.update_time()},1000)
   




                   }
                


         

            }

            setTimeout(()=>{this.check_aspect_ratio()},1000)
   


        })
    }

    update_time( ){

        {

            
            // (05) EOC Erica
            var count = 0;
            var tourList = this.tourlist.length

           



            for (var i = 0; i < tourList; i++) {

                var DDate = this.tourlist[i].iti_start_date
                var MDate = this.tourlist[i].iti_start_month
                var YDate = this.tourlist[i].iti_start_year
                var Time = this.tourlist[i].trip_start_time + ":00"
               


    

                //function timeBetweenDates(DDate, MDate, YDate) {
          
                    var now = new Date();

                    var Bndate = DDate + ' ' + MDate + ' ' + YDate + ' ' + Time
                    let HDTime = moment(DDate + ' ' + MDate + ' ' + YDate + ' ' + Time)


                  
                    var difference = moment(HDTime)['_d'] - moment(now.getTime())['_d'];

                 
                    if (difference <= 0) {
                        // Timer done

                        this.tourlistsaved[i].timer = 0
                        this.tourlist[i].dayst = 0

                     
                       

                    } else {

                        this.tourlistsaved[i].timer = 1
                        this.tourlist[i].dayst = 1

                        //var seconds = Math.floor(difference / 1000);
                        //var minutes = Math.floor(seconds / 60);
                        //var hours = Math.floor(minutes / 60);
                        //var days = Math.floor(hours / 24);

                        var seconds = difference / 1000;
                        var minutes = seconds / 60;
                        var hours = minutes / 60;
                        var days = hours / 24;


                        
                        
                        hours %= 24;
                        minutes %= 60;
                        seconds %= 60;
                        let new_seconds
                        let new_minutes
                        let new_hours
                        let new_day
                        if (seconds < 10) {
                            new_seconds = "0" + seconds
                        } else if (seconds >= 10) {
                            new_seconds = seconds
                        }

                        if (minutes < 10) {
                            new_minutes = "0" + minutes
                        } else if (minutes >= 10) {
                            new_minutes = minutes
                        }

                        if (hours < 10) {
                            new_hours = "0" + hours
                        } else if (hours >= 10) {
                            new_hours = hours
                        }

                        if (days < 10) {
                            new_day = "0" + days
                        } else if (days >= 10) {
                            new_day = days
                        }


                        
                        this.tourlistsaved[i].dayst = Math.floor(new_day)
                        this.tourlistsaved[i].hourst = Math.floor(new_hours)
                        this.tourlistsaved[i].minutest = Math.floor(new_minutes)
                        this.tourlistsaved[i].secst = Math.floor(new_seconds)


                        this.tourlist[i].dayst = Math.floor(new_day)
                        this.tourlist[i].hourst = Math.floor(new_hours)
                        this.tourlist[i].minutest = Math.floor(new_minutes)
                        this.tourlistsaved[i].secst = Math.floor(new_seconds)

                        //inside method
                      
                         if(!this.deactivate_timer){
                            setTimeout(()=>{this.update_time()},1000)
                         }
                        
   




                   }
                


         

            }




        }
    }


    callHome(Itineraryid,index)
    {

        this.clear_local_storage();

        this.deactivate_timer = true

      

          //05: BOC HRUSHIKESH := Slide index saved in localstorage to get color of organization
          localStorage.setItem("INDEX_WELCOME", index)
          //EOC HRUSHIKESH     
          
          this.hideSpinner = false
          
          //05: BOC HRUSHIKESH := Loader will get organization color if exist or company color by default      
          setTimeout(() => {
              if (this.tourlistsaved[index].brand.color != undefined) {
          
                  $('#extraCSS').text(
                      '.loader {border-top: 8px solid' + this.tourlistsaved[index].brand.color + ' !important;}'
                      +  '.background_pg { background-image: url('+JSON.parse(localStorage.getItem("companyProvider")).web_background+') !important }'

          
                  );
              } else {
          
                  $('#extraCSS').text(
                      '.loader {border-top: 8px solid' + JSON.parse(localStorage.getItem("primaryColor")) + ' !important;}'
                      +'.background_pg { background-image: url('+JSON.parse(localStorage.getItem("companyProvider")).web_background+') !important }'

                 
                      );
              }
          }, 1000);
          //EOC HRUSHIKESH  
          
          




        localStorage.setItem("LStourListId", Itineraryid)


        this.tourService.show(data => {

            // BOC Erica (01)
          localStorage.setItem("display_feature",JSON.stringify(data.itinerary.display_feature));

          // EOC Erica (01)

          localStorage.setItem("itinerary_data",JSON.stringify(data.itinerary));// Added by  Erica

          // EOC Erica (01)
          localStorage.setItem("IsSinglePDF", "false")
          localStorage.setItem("IsHomeDescPDF", "false")

        //   if(data.itinerary.pdf_description == undefined){
            localStorage.setItem("pdf_description_url", "null"); 
        //   }
         


          if(data.itinerary.pdf_type!=undefined){
        



            
            if (    data.itinerary.pdf_type == "Single Itinerary PDF") {
              localStorage.setItem("IsSinglePDF", "true");
              localStorage.setItem("pdf_description_url", data.itinerary.pdf_type.pdf_description); 
            } 
  
           

            if (   data.itinerary.pdf_type == "Daily PDF") {
              localStorage.setItem("IsHomeDescPDF", "true");
              localStorage.setItem("pdf_description_url", data.itinerary.pdf_type.pdf_description); 

            }


          }

         
           

            setTimeout(() => {
                this.hideSpinner = true
            }, 3000);

            this.hotelService.all(data => {
      

            });

            this.experiencesService.all(data => {
  

            });


            this.mealsService.all(data => {
         

            });

            this.GuideService.all(data => {
               

            });

            this.udfTipsOFFline()
            this.udfOtherdetails()

            // this.router.navigate(['home'])

            // this.check_home_desc_pdf();

        });

    



    }


    showCancelBtn(keyword, allTourListData) {
        this.selectBooking(keyword, allTourListData)
    }

    logout() {
    
        this.authService.logout()
    }


    selectBooking(keyword, allTourListData) {
     
        if (keyword != undefined) {
            var noBookingFound;
            this.tourListData = [];


            if (keyword != "") {

            

                for (var i = 0; i < allTourListData.length; i++) {
                    if (allTourListData[i].title.toLowerCase().indexOf(keyword.toLowerCase()) != -1) {
                        this.tourListData.push(allTourListData[i]);
                        this.tourlist = this.tourListData
                       
                    }
                }
            
                this.numBookingLength = this.tourListData.length
              

                if (this.tourListData.length == 0) {
                 
                }

            } else if (keyword == "") {
                this.tourlist = this.tourlistsaved;
                this.numBookingLength = this.tourlist.length;
              
            }

         
        
            
        }

    }



    udfTipsOFFline() {

     
        this.tourService.TipsFactoryAll((data) => {

            if (data != 'error') {
             


                if (data != undefined &&
                    data != null &&
                    data.day_trip_hash_tag != undefined &&
                    data.day_trip_hash_tag.length != 0) {

                    //------------------TIPS FOR TRIP -------------------------------------
                    var tripHashTagArry = [];
                    var countDayTrip = 0;
                     tripHashTagArry = data.day_trip_hash_tag
                     window.localStorage.setItem('LSDtipsList', JSON.stringify(tripHashTagArry))

                     console.log("SEP::22::1                     ",tripHashTagArry)

                } else if (data == undefined ||
                    data == null ||
                    data.day_trip_hash_tag == undefined ||
                    data.day_trip_hash_tag.length == 0) {
                  

                }

                //------------------TIPS FOR ITINERARY -------------------------------------


                let itineraryTipsArry = [];
                if (data != undefined &&
                    data != null &&
                    data.itinerary_hash_tag != undefined &&
                    data.itinerary_hash_tag.length != 0) {

                     



                    {
                        if (data.itinerary_hash_tag != "no hash tags for itinerary") {

                        
                            let countIti = 0;
                            itineraryTipsArry = data.itinerary_hash_tag
                            window.localStorage.setItem('LSDItinerarytipsList', JSON.stringify(itineraryTipsArry));
                        
                            console.log("SEP::22::2                     ",itineraryTipsArry)
                        }
                    }

                } else if (data == undefined ||
                    data == null ||
                    data.itinerary_hash_tag == undefined ||
                    data.itinerary_hash_tag.length == 0) {

                }
            }


            //------------------TIPS FOR GUIDES -------------------------------------
            if (data != undefined &&
                data != null &&
                data.guides_hash_tag != undefined &&
                data.guides_hash_tag.length != 0) {
                {
                    let guideTipsArry = [];
                    let countGuidesHash = 0;
                    guideTipsArry = data.guides_hash_tag
                    window.localStorage.setItem('LSDGuidestipsList', JSON.stringify(guideTipsArry));
                    console.log("SEP::22::3                     ",guideTipsArry)

                }
            } else if (data == undefined ||
                data == null ||
                data.guides_hash_tag == undefined ||
                data.guides_hash_tag.length == 0) {
            }



            //------------------TIPS FOR HOTELS -------------------------------------

            if (data != undefined &&
                data != null &&
                data.hotels_hash_tag != undefined &&
                data.hotels_hash_tag.length != 0) {
                {
                    let hotelTipsArry = [];
                    let countHotelsHash = 0;
                    hotelTipsArry = data.hotels_hash_tag
                    window.localStorage.setItem('LSDHotelstipsList', JSON.stringify(hotelTipsArry));

                    console.log("SEP::22::4                     ",hotelTipsArry)


                }
            } else if (data == undefined ||
                data == null ||
                data.hotels_hash_tag == undefined ||
                data.hotels_hash_tag.length == 0) {
              
            }



            //--------------------------- Tip List Experiences ------------------------------------


            if (data != undefined &&
                data != null &&
                data.experiences_hash_tag != undefined &&
                data.experiences_hash_tag.length != 0) {
                {
                    ExperienceTipsArry = [];
                    let countExperiencesHash = 0;
                    var ExperienceTipsArry = data.experiences_hash_tag
                    window.localStorage.setItem('LSDExperiencetipsList', JSON.stringify(ExperienceTipsArry));

                    console.log("SEP::22::5                     ",ExperienceTipsArry)

                }
            } else if (data == undefined ||
                data == null ||
                data.experiences_hash_tag == undefined ||
                data.experiences_hash_tag.length == 0) {

            }



            //--------------------------- Tip List Meals ------------------------------------


            if (data != undefined &&
                data != null &&
                data.meals_hash_tag != undefined &&
                data.meals_hash_tag.length != 0) {
                {
                    let MealsTipsArry = [];
                    let countMealsHash = 0;
                    MealsTipsArry = data.meals_hash_tag
                    window.localStorage.setItem('LSDMealstipsList', JSON.stringify(MealsTipsArry));

                    console.log("SEP::22::6                     ",MealsTipsArry)

                }
            } else if (data == undefined ||
                data == null ||
                data.meals_hash_tag == undefined ||
                data.meals_hash_tag.length == 0) {

            }



              this.check_home_desc_pdf();

        });

}


udfOtherdetails() {

    

    
     


        //-----------------CONTACTS FACTORY LIST ---------------------------------------------------
        this.contactService.ContactsFactoryListAll((data) => {

          if(data=="error"){
            
          }

          if (data != 'error') {
            window.localStorage.setItem("LSDContactList", JSON.stringify(data));
           
          } else {
            let contacts = '[]'
            window.localStorage.setItem("LSDContactList", contacts);
          }
          
        })


        this.contactService.StoreEmergencyContact((data) => {

          if(data=="error"){
          
          }
          window.localStorage.removeItem("LSDContactListEmergency")
         
          if (data != 'error') {
            window.localStorage.setItem("LSDContactListEmergency", JSON.stringify(data));
          } else {
            let contactsEmergency = '[]'
            window.localStorage.setItem("LSDContactListEmergency", contactsEmergency);
          }
          
        });

    


  
}

svgregister() {



    //Transport Erica BOC From Scratch
    this.matIconRegistry.addSvgIcon(
        "menu_options",
        this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/Images/mixed/menu_options.svg")
    );
    }    

    

    clear_local_storage(){

        

        localStorage.removeItem("IsSinglePDF");
        localStorage.removeItem("IsHomeDescPDF");
        

localStorage.removeItem("LSDContactList");
localStorage.removeItem("LSDContactListEmergency");
localStorage.removeItem("LSDDocumentList");
localStorage.removeItem("LSDExperiencetipsList");
localStorage.removeItem("LSDExplistdata");
localStorage.removeItem("LSDGuidelistdata");
localStorage.removeItem("LSDHotellistdata");
localStorage.removeItem("LSDItinerarytipsList");
localStorage.removeItem("LSDMealslistdata");
localStorage.removeItem("LSDMealstipsList");
localStorage.removeItem("LSDitinerarydata");
localStorage.removeItem("LSDtipsList");

localStorage.removeItem('LSDtipsList')
localStorage.removeItem('LSDItinerarytipsList');
  localStorage.removeItem('LSDGuidestipsList');
 localStorage.removeItem('LSDHotelstipsList');
localStorage.removeItem('LSDExperiencetipsList');
 localStorage.removeItem('LSDMealstipsList');

  
    }

    check_home_desc_pdf(){

        

        if (localStorage.getItem('IsHomeDescPDF') == 'true') {

          
            this.router.navigate(['home-desc-pdf'])
    
        } else {
           
            this.router.navigate(['home'])
        }
    }

}



