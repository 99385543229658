import { AlertDialogDynamicComponent } from './../alert-dialog-dynamic/alert-dialog-dynamic.component';
import { EndOfTourService } from './../services/end-of-tour.service';
import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';

import { MatDialog } from '@angular/material/dialog';
import { AlertDailogComponent } from '../alert-dailog/alert-dailog.component';


declare var jQuery: any;

declare var formBuilder:any;

@Component({
  selector: 'app-end-of-tour',
  templateUrl: './end-of-tour.component.html',
  styleUrls: ['./end-of-tour.component.css']
})
export class EndOfTourComponent implements OnInit {

  tourListProvider

  translationsLabels

  header_img

  constructor(private endOfTourService:EndOfTourService, private matDialog: MatDialog) {

    this.header_img = JSON.parse(localStorage.getItem("LSDitinerarydata")).itinerary.imgSrc


    this.tourListProvider = JSON.parse(localStorage.getItem("tourListProvider"))

    this.translationsLabels = JSON.parse(localStorage.getItem("UserData")).user.translations


        // BOC Erica (06)
        var index = (localStorage.getItem('INDEX_WELCOME'))

 


setTimeout(()=>{
     
      
  if(this.tourListProvider[index].brand.color == undefined){
 

    $('#eot_survey').text(
      'app-end-of-tour .range-md .range-knob {background:'+JSON.parse(localStorage.getItem("primaryColor")) +' !important;'+
      'border: 2px solid' +JSON.parse(localStorage.getItem("primaryColor"))+' !important;}'+
      'app-end-of-tour .range-md .range-bar-active {background:'+JSON.parse(localStorage.getItem("primaryColor"))+' !important;}'+
      'app-end-of-tour #slider_eos ion-label{color:'+JSON.parse(localStorage.getItem("primaryColor"))+' !important;}'+
      'app-end-of-tour .build-wrap input:checked + label{ color:'+JSON.parse(localStorage.getItem("primaryColor"))+' !important;}'+
      'app-end-of-tour .build-wrap b{ color:'+JSON.parse(localStorage.getItem("primaryColor"))+' !important;}'

      //Radio button css
      +'app-end-of-tour .build-wrap .formbuilder-radio input[type="radio"]:checked, [type="radio"]:not(:checked) { position: absolute; left: -9999px; }'
      +'app-end-of-tour .build-wrap .formbuilder-radio input[type="radio"]:checked + label, .formbuilder-radio input[type="radio"]:not(:checked) + label { position: relative; padding-left: 40px; cursor: pointer; line-height: 25px; display: inline-block; color: #666;}'
      +'app-end-of-tour .build-wrap .formbuilder-radio input[type="radio"]:checked + label:before, .formbuilder-radio input[type="radio"]:not(:checked) + label:before { content: ""; position: absolute; left: 0; top: 0; width: 16px; height: 16px;border: 2px solid gray; border-radius: 100%; background: #fff; }'
      +'app-end-of-tour .build-wrap .formbuilder-radio input[type="radio"]:checked + label:after, .formbuilder-radio input[type="radio"]:not(:checked) + label:after { content: ""; width: 12px; height: 12px; background: '+
      JSON.parse(localStorage.getItem("primaryColor"))+
      '; position: absolute;top: 4px; left: 4px; border-radius: 100%; -webkit-transition: all 0.2s ease; transition: all 0.2s ease; }'
      +'app-end-of-tour .build-wrap .formbuilder-radio input[type="radio"]:not(:checked) + label:after { opacity: 0; -webkit-transform: scale(0); transform: scale(0); }'
      +'app-end-of-tour .build-wrap .formbuilder-radio input[type="radio"]:checked + label:after { opacity: 1; -webkit-transform: scale(1); transform: scale(1); }'
      +'app-end-of-tour .build-wrap .formbuilder-radio label:hover:before {  border: 2px solid '+JSON.parse(localStorage.getItem("primaryColor"))+' !important; }'

      //Checkbox Css
      +'app-end-of-tour .build-wrap input[type="checkbox"] { cursor: pointer; -webkit-appearance: none; '
        +"-moz-appearance: none; appearance: none; outline: 0; background: lightgray; height: 25px; width: 25px; border: 1px solid white; }"
      +'app-end-of-tour .build-wrap input[type="checkbox"]:checked {background: '+JSON.parse(localStorage.getItem("primaryColor"))+';}'
      +'app-end-of-tour .build-wrap input[type="checkbox"]:hover { filter: brightness(90%);}'
      +'app-end-of-tour .build-wrap input[type="checkbox"]:disabled {background: #e6e6e6; opacity: 0.6; pointer-events: none;}'
      +'app-end-of-tour .build-wrap input[type="checkbox"]:after { content: ""; position: relative; left: 36%; top: 8%; width: 25%; height: 60%; border: solid #ffffff; border-width: 0px 2px 2px 0px; transform: rotate(45deg); display: none; }'
      +'app-end-of-tour .build-wrap input[type="checkbox"]:checked:after {display: block; }'
      +'app-end-of-tour .build-wrap input[type="checkbox"]:disabled:after { border-color: #7b7b7b;}'

       //Toggle checkbox CSS
       +'app-end-of-tour .build-wrap .rendered-form .kc-toggle input:checked+span::after { transform: translateX(100%); }'
       +'app-end-of-tour .build-wrap .rendered-form .kc-toggle span::after { position: relative; content: ""; width: 45%; height: 22px; left: 0; border-radius: 50px; background: linear-gradient(to bottom, #fff 0%, #fff 100%); border: 1px solid #999; transition: transform 100ms; transform: translateX(0); }'
       +'app-end-of-tour .build-wrap .rendered-form .kc-toggle span::after, .rendered-form .kc-toggle span::before { position: absolute; display: inline-block; top: 0; }'
       +'app-end-of-tour .build-wrap .rendered-form .kc-toggle input:checked+span::before { background-color:  '+JSON.parse(localStorage.getItem("primaryColor"))+'; }'
       +'app-end-of-tour .build-wrap .rendered-form .kc-toggle span::before { border-radius: 50px; top: 0px; left: 0px; content: "";  width: 49px;  height: 26px; background-color: transparent; }'
       +'app-end-of-tour .build-wrap .rendered-form .kc-toggle span::after, .rendered-form .kc-toggle span::before { position: absolute; display: inline-block; top: 0; }'
       +'app-end-of-tour .build-wrap .rendered-form .kc-toggle span {  position: relative; width: 48px;border-radius: 50px;  height: 26px;  background: #cccccc;  display: inline-block; border: 1px solid #ffffff; padding: 2px;  overflow: hidden;  float: left;  margin-right: 5px;  will-change: transform;} '

       +'app-end-of-tour .build-wrap input[type="checkbox"]:checked {background:'+JSON.parse(localStorage.getItem("primaryColor"))+' !important ;}'
       +'app-end-of-tour  .build-wrap .formbuilder-radio input[type="radio"]:checked + label:before, .formbuilder-radio input[type="radio"]:not(:checked) + label:before {border: 2px solid'+JSON.parse(localStorage.getItem("primaryColor"))+' !important ;background: #fff !important;}'
       +'app-end-of-tour  .build-wrap .formbuilder-radio input[type="radio"]:checked + label:after, .formbuilder-radio input[type="radio"]:not(:checked) + label:after { background: '+JSON.parse(localStorage.getItem("primaryColor"))+' !important ;}'
       +'app-end-of-tour  .mat-slider-horizontal .mat-slider-track-fill {background: '+JSON.parse(localStorage.getItem("primaryColor"))+' !important;}'
       +'app-end-of-tour  .mat-slider:not(.mat-slider-disabled) .mat-slider-thumb, .mat-slider:not(.mat-slider-disabled) .mat-slider-thumb-label {background: '+JSON.parse(localStorage.getItem("primaryColor"))+' !important;}'
       +'app-end-of-tour  .mat-slider-thumb-label {  display: none;align-items: center;justify-content: center;position: absolute; width: 28px;height: 28px;border-radius: 50%; transition: transform .4s cubic-bezier(.25,.8,.25,1),border-radius .4s cubic-bezier(.25,.8,.25,1),background-color .4s cubic-bezier(.25,.8,.25,1);  background: '+JSON.parse(localStorage.getItem("primaryColor"))+' !important;}'
       +'app-end-of-tour  .rendered-form .kc-toggle input:checked+span::before {background-color: '+JSON.parse(localStorage.getItem("primaryColor"))+' !important;}'
       +'app-end-of-tour .submit_button{background :'+JSON.parse(localStorage.getItem("primaryColor"))+' !important;}'
       );
      // (01): EOC: Erica Replace #build-wrap with in the selector in jquery page-end-of-tour #build-wrap





  }else if(this.tourListProvider[index].brand.color != undefined){

    $('#eot_survey').text(
      'app-end-of-tour .range-md .range-knob {background:'+this.tourListProvider[index].brand.color +' !important;'+
      'border: 2px solid' +this.tourListProvider[index].brand.color+' !important;}'+
      'app-end-of-tour .range-md .range-bar-active {background:'+this.tourListProvider[index].brand.color+' !important;}'+
      'app-end-of-tour #slider_eos ion-label{color:'+this.tourListProvider[index].brand.color+' !important;}'+
      'app-end-of-tour  .build-wrap input:checked + label{ color:'+this.tourListProvider[index].brand.color+' !important;}'+
      'app-end-of-tour  .build-wrap b{ color:'+this.tourListProvider[index].brand.color+' !important;}'

      //Radio button css
      +'app-end-of-tour  .build-wrap .formbuilder-radio input[type="radio"]:checked, [type="radio"]:not(:checked) { position: absolute; left: -9999px; }'
      +'app-end-of-tour  .build-wrap .formbuilder-radio input[type="radio"]:checked + label, .formbuilder-radio input[type="radio"]:not(:checked) + label { position: relative; padding-left: 40px; cursor: pointer; line-height: 25px; display: inline-block; color: #666;}'
      +'app-end-of-tour  .build-wrap .formbuilder-radio input[type="radio"]:checked + label:before, .formbuilder-radio input[type="radio"]:not(:checked) + label:before { content: ""; position: absolute; left: 0; top: 0; width: 16px; height: 16px;border: 2px solid gray; border-radius: 100%; background: #fff; }'
      +'app-end-of-tour  .build-wrap .formbuilder-radio input[type="radio"]:checked + label:after, .formbuilder-radio input[type="radio"]:not(:checked) + label:after { content: ""; width: 12px; height: 12px; background: '+
      this.tourListProvider[index].brand.color+
      '; position: absolute;top: 4px; left: 4px; border-radius: 100%; -webkit-transition: all 0.2s ease; transition: all 0.2s ease; }'
      +'app-end-of-tour  .build-wrap .formbuilder-radio input[type="radio"]:not(:checked) + label:after { opacity: 0; -webkit-transform: scale(0); transform: scale(0); }'
      +'app-end-of-tour  .build-wrap .formbuilder-radio input[type="radio"]:checked + label:after { opacity: 1; -webkit-transform: scale(1); transform: scale(1); }'
      +'app-end-of-tour  .build-wrap .formbuilder-radio label:hover:before {  border: 2px solid '+this.tourListProvider[index].brand.color+' !important; }'

      //Checkbox Css
      +'app-end-of-tour  .build-wrap input[type="checkbox"] { cursor: pointer; -webkit-appearance: none; '
        +"-moz-appearance: none; appearance: none; outline: 0; background: lightgray; height: 25px; width: 25px; border: 1px solid white; }"
      +'app-end-of-tour  .build-wrap input[type="checkbox"]:checked {background: '+this.tourListProvider[index].brand.color+';}'
      +'app-end-of-tour  .build-wrap input[type="checkbox"]:hover { filter: brightness(90%);}'
      +'app-end-of-tour  .build-wrap input[type="checkbox"]:disabled {background: #e6e6e6; opacity: 0.6; pointer-events: none;}'
      +'app-end-of-tour  .build-wrap input[type="checkbox"]:after { content: ""; position: relative; left: 36%; top: 8%; width: 25%; height: 60%; border: solid #ffffff; border-width: 0px 2px 2px 0px; transform: rotate(45deg); display: none; }'
      +'app-end-of-tour  .build-wrap input[type="checkbox"]:checked:after {display: block; }'
      +'app-end-of-tour  .build-wrap input[type="checkbox"]:disabled:after { border-color: #7b7b7b;}'

       //Toggle checkbox CSS
       +'app-end-of-tour  .build-wrap .rendered-form .kc-toggle input:checked+span::after { transform: translateX(100%); }'
       +'app-end-of-tour  .build-wrap .rendered-form .kc-toggle span::after { position: relative; content: ""; width: 45%; height: 22px; left: 0; border-radius: 50px; background: linear-gradient(to bottom, #fff 0%, #fff 100%); border: 1px solid #999; transition: transform 100ms; transform: translateX(0); }'
       +'app-end-of-tour  .build-wrap .rendered-form .kc-toggle span::after, .rendered-form .kc-toggle span::before { position: absolute; display: inline-block; top: 0; }'
       +'app-end-of-tour  .build-wrap .rendered-form .kc-toggle input:checked+span::before { background-color:  '+this.tourListProvider[index].brand.color+'; }'
       +'app-end-of-tour  .build-wrap .rendered-form .kc-toggle span::before { border-radius: 50px; top: 0px; left: 0px; content: "";  width: 49px;  height: 26px; background-color: transparent; }'
       +'app-end-of-tour  .build-wrap .rendered-form .kc-toggle span::after, .rendered-form .kc-toggle span::before { position: absolute; display: inline-block; top: 0; }'
       +'app-end-of-tour  .build-wrap .rendered-form .kc-toggle span {  position: relative; width: 48px;border-radius: 50px;  height: 26px;  background: #cccccc;  display: inline-block; border: 1px solid #ffffff; padding: 2px;  overflow: hidden;  float: left;  margin-right: 5px;  will-change: transform;} '

       +'app-end-of-tour  .build-wrap input[type="checkbox"]:checked {background:'+this.tourListProvider[index].brand.color+' !important ;}'
       +'app-end-of-tour   .build-wrap .formbuilder-radio input[type="radio"]:checked + label:before, .formbuilder-radio input[type="radio"]:not(:checked) + label:before {border: 2px solid'+this.tourListProvider[index].brand.color+' !important ;background: #fff !important;}'
       +'app-end-of-tour   .build-wrap .formbuilder-radio input[type="radio"]:checked + label:after, .formbuilder-radio input[type="radio"]:not(:checked) + label:after { background: '+this.tourListProvider[index].brand.color+' !important ;}'
       +'app-end-of-tour   .mat-slider-horizontal .mat-slider-track-fill {background: '+this.tourListProvider[index].brand.color+' !important;}'
       +'app-end-of-tour   .mat-slider:not(.mat-slider-disabled) .mat-slider-thumb, .mat-slider:not(.mat-slider-disabled) .mat-slider-thumb-label {background: '+this.tourListProvider[index].brand.color+' !important;}'
       +'app-end-of-tour  .mat-slider-thumb-label {  display: none;align-items: center;justify-content: center;position: absolute; width: 28px;height: 28px;border-radius: 50%; transition: transform .4s cubic-bezier(.25,.8,.25,1),border-radius .4s cubic-bezier(.25,.8,.25,1),background-color .4s cubic-bezier(.25,.8,.25,1);  background: '+this.tourListProvider[index].brand.color+' !important;}'

       +'app-end-of-tour   .rendered-form .kc-toggle input:checked+span::before {background-color: '+this.tourListProvider[index].brand.color+' !important;}'
       +'app-end-of-tour  .submit_button{background :'+this.tourListProvider[index].brand.color+' !important;}'
       );
      // (01): EOC: Erica Replace #build-wrap with in the selector in jquery page-end-of-tour #build-wrap





  }
  
 






},500);





setTimeout(()=>{this.check_height()},500) 

}

show_scroll_up_btn


  check_height(){

   
      if($(window).height()>= $(".mainContainer").height()+250){
        this.show_scroll_up_btn=false;
      }else if($(window).height()< $(".mainContainer").height()+250){
        this.show_scroll_up_btn=true;
      }

      
    }

  scroll_up(){

    $(window).scrollTop( $("body").offset().top );
}


  sentJSON
  slider_value

  endOfSurveyJson

  survey_id
  isError=false
  ngOnInit() {

   

    this.endOfTourService.get_endOfTourSurvey(data=>{
    

      if(data.type!="error"){

        this.sentJSON = data.surveys.form_fields
        this.slider_value = data.surveys.overall_score
        this.gridsize=data.surveys.overall_score
  
        
  
  
        if (data.surveys.form_fields != null) {
  
  
  
          this.endOfSurveyJson = data.surveys.form_fields;
          console.log("EOT: data==sentJSON==1 ", this.endOfSurveyJson);
  
          this.survey_id = data.surveys.survey_id
          var formRenderOpts = {
            dataType: 'json',
            formData: JSON.stringify(this.endOfSurveyJson)
          };
  
  
          // var renderedForm = $('.build-wrap')
  
          console.log("EOT: data==sentJSON== 2", formRenderOpts);
  
          // (01): BOC: Erica Replace .build-wrap with in the selector in jquery page-end-of-tour #build-wrap
          jQuery('.build-wrap').formRender(formRenderOpts);
          // (01): EOC: Erica Replace .build-wrap with in the selector in jquery page-end-of-tour #build-wrap
  
        
  
        }
      }
      if(data.type=="error"){
        this.isError=false;
      }



    });
  }

  

  

  formatLabel(value: number) {

    
   

      this.slider_value=value
      // return Math.round(value ) ;
    

    return value;
  }


  submitForm() {
    localStorage.setItem("eotBadge","false");

    let survey_result = []
    let arraysent_json = []

    survey_result = jQuery('#form_eot').serializeArray()

    arraysent_json = this.sentJSON

    let checkfound = 0
    let radiofound = 0
    let range_value = {}
    range_value["name"] = "survey_range";
    range_value["value"] = this.slider_value;
    range_value["type"] = "number";

    let checkingText,
    checkingTextradio,
    checkingTextcheck,
    //tells if out of range numbers are entered
    //or number is mandatory and not entered
    // and if text area has exceeded its length
    //or is mandatory and not filled
    checkingConditionFails = false,
    //Tells if mandatory checkbox are checked or not
    checkingConditionFailscheck = false,
    checkingConditionFailsradio = false

    //For::Checking number and textarea
    for (let i = 0; i < arraysent_json.length; i++) {

      for (let j = 0; j < survey_result.length; j++) {

        //if::Checking number
        if (survey_result[j].name.indexOf("number") != -1) {

          //matched number field
          if (survey_result[j].name == arraysent_json[i].name) {

            //Step 1: less then min number limit
            if (parseInt(survey_result[j].value) < parseInt(arraysent_json[i].min)) {
              //Spaces Added
              checkingText = this.translationsLabels.min_num_should
              +" " + arraysent_json[i].min
              + " " +this.translationsLabels.for_field
              +" "+ arraysent_json[i].label

             // Minimum Number Should Be .... For Field ......

              var n = checkingText.includes("&nbsp;");
                if(n==true){
                checkingText=checkingText.replace("&nbsp;", " ");
                }
              checkingConditionFails = true
            }

            //Step 2: more then max number limit
            if (parseInt(survey_result[j].value) > parseInt(arraysent_json[i].max)) {

              //Spaces Added
              checkingText = this.translationsLabels.max_num_should
              +" " + arraysent_json[i].max
              + " " + this.translationsLabels.for_field
              + " " + arraysent_json[i].label

              // Maximum Number Should Be .... For Field ......


              var n = checkingText.includes("&nbsp;");
                if(n==true){
                checkingText=checkingText.replace("&nbsp;", " ");
                }
              checkingConditionFails = true
            }

            //Step 3: Madatory number not entered
            if (arraysent_json[i].required == true) {
              if (survey_result[j].value == "") {
                //&nbsp fix in text box toast Added by Erica

                checkingText = this.translationsLabels.mandatory_field

               //Please fill the mandatory fields in the survey.
                var n = checkingText.includes("&nbsp;");
                if(n==true){
                checkingText=checkingText.replace("&nbsp;", " ");
                }
                checkingConditionFails = true
              }
            }

          }//end matched number field


        }//end if::Checking number

        //if::Checking textarea
        if (survey_result[j].name.indexOf("textarea") != -1) {

          //matched textarea field
          if (survey_result[j].name == arraysent_json[i].name) {

            //Step 1: textarea exceeded max length limit
            if (parseInt(survey_result[j].value.lenght) > parseInt(arraysent_json[i].maxlength)) {
              checkingText = this.translationsLabels.max_length_should_be
              +" "+arraysent_json[i].maxlength
              + " " +this.translationsLabels.for_field
              + " " + arraysent_json[i].label

              //Maximum lenght should be ... For Field .....

              var n = checkingText.includes("&nbsp;");
              if(n==true){
              checkingText=checkingText.replace("&nbsp;", " ");
              }
              checkingConditionFails = true
            }

            //Step 2: madatory textarea not filled
            if (arraysent_json[i].required == true) {
              if (survey_result[j].value == "") {
                //&nbsp fix in text box toast Added by Erica
                // checkingText = this.translationsLabels.mandatory_field.concat(" " + arraysent_json[i].label)
                checkingText = this.translationsLabels.mandatory_field
                //Please fill the mandatory fields in the survey.
                var n = checkingText.includes("&nbsp;");
                if(n==true){
                checkingText=checkingText.replace("&nbsp;", " ");
                }
                checkingConditionFails = true
              }
            }

          }//end matched textarea field

        }//end if::Checking textarea

      }

    }//end For::Checking number and textarea



    let checkbox_count=0;

    let madatory_checkbox_has_value=false;

    //For::Checking CheckboxGroup
    for (let k = 0; k < arraysent_json.length; k++) {

      //if checkbox-group
      if (arraysent_json[k].type == "checkbox-group") {

        checkbox_count=checkbox_count+1;

        //if checkbox-group required
        if (arraysent_json[k].required == true) {

          madatory_checkbox_has_value=false;
          for (let c = 0; c < survey_result.length; c++) {

            if ((arraysent_json[k].name + '[]') == (survey_result[c].name)) {

              madatory_checkbox_has_value = true

            }

          }


            if(madatory_checkbox_has_value==false){

              //&nbsp fix in text box toast Added by Erica
              // checkingTextcheck = this.translationsLabels.mandatory_field.concat(" " + arraysent_json[k].label)
              checkingTextcheck = this.translationsLabels.mandatory_field
              var n = checkingTextcheck.includes("&nbsp;");
              if(n==true){
                checkingTextcheck=checkingTextcheck.replace("&nbsp;", " ");
              }
              checkingConditionFailscheck = true
              madatory_checkbox_has_value=false;
            }

        }// end if checkbox-group required

      }//end if checkbox-group

    }//end For::Checking CheckboxGroup

    var all_madatory_radio_groups=[];

    //Create array of all mandatory radio-group
    for(var a = 0; a < arraysent_json.length; a++){

      if((arraysent_json[a].type == "radio-group")&&
      (arraysent_json[a].required == true)){
        all_madatory_radio_groups.push(arraysent_json[a]);
      }
    }



    var all_filled_radio_groups=[];

    for(var b = 0; b < survey_result.length; b++){

    if(survey_result[b].name.includes("radio-group")){

    all_filled_radio_groups.push(survey_result[b]);
    }
    }


    checkingConditionFailsradio= false
    var count=0;
    for(var c=0;c<all_madatory_radio_groups.length;c++){
    var element=all_madatory_radio_groups[c];
    for(var d=0;d<all_filled_radio_groups.length;d++)
    {


    if(element.name==all_filled_radio_groups[d].name){

    ++count;

    }

    }

    }

    if(count==all_madatory_radio_groups.length){
    checkingConditionFailsradio= false
    }else if(count<all_madatory_radio_groups.length){
    checkingConditionFailsradio= true


    checkingTextradio = this.translationsLabels.mandatory_field;

    }







    for (let i = 0; i < survey_result.length; i++) {
      if (survey_result[i].name.indexOf("checkbox-group") != -1) {
        survey_result[i].name = survey_result[i].name.replace("[]", "");


      }

    }



    if (checkingConditionFails == true) {

      const dialogRef = this.matDialog.open(AlertDialogDynamicComponent, {
        data: { message: checkingText }
        });
        
        dialogRef.afterClosed().subscribe(result => {
        console.log(`Dialog result: ${result}`);
        });


      //BOC (307) ERICA
      // let sendMailFailed= this.modal.create('OrgColorPopupmodalPage',
      // { message: checkingText,
      //   showFailText:false }
      // ,{cssClass:'settings-modal'});
      // sendMailFailed.present().then(()=>{
      //   checkingText = ""
      //   checkingConditionFails = false
      // })

      //EOC (307) ERICA


    } else if (checkingConditionFailscheck == true) {

      const dialogRef = this.matDialog.open(AlertDialogDynamicComponent, {
        data: { message: checkingTextcheck }
        });
        
        dialogRef.afterClosed().subscribe(result => {
        console.log(`Dialog result: ${result}`);
        });



       //BOC (307) ERICA
      // let confirmAlert= this.modal.create('OrgColorPopupmodalPage',
      // { message: checkingTextcheck,
      //   showFailText:false }
      // ,{cssClass:'settings-modal'});
      // confirmAlert.present().then(()=>{
      //   checkingText = ""
      //   checkingConditionFails = false
      // })

       //EOC (307) ERICA


    } else if (checkingConditionFailsradio == true) {

      const dialogRef = this.matDialog.open(AlertDialogDynamicComponent, {
        data: { message: checkingTextradio }
        });
        
        dialogRef.afterClosed().subscribe(result => {
        console.log(`Dialog result: ${result}`);
        });

         //BOC (307) ERICA
        //  let confirmAlert= this.modal.create('OrgColorPopupmodalPage',
        //  { message: checkingTextradio,
        //    showFailText:false }
        //  ,{cssClass:'settings-modal'});
        //  confirmAlert.present().then(()=>{
        //    checkingText = ""
        //    checkingConditionFails = false
        //  })

          //EOC (307) ERICA

    } else {

     

      this.endOfTourService.submitFinalSurvey(this.survey_id,this.gridsize, survey_result, this.matDialog)
    }




  }//end submitForm()


  gridsize: number = 0;
  updateSetting(event) {
    this.buttonsList = document.getElementsByClassName('mat-slider-eot');

    // for(let currentButton of this.buttonsList) {
    //   //currentButton.classList.remove("cdk-mouse-focused");
    //    currentButton.classList.add("cdk-focused");
    //   // currentButton.classList.remove("cdk-program-focused");
    // }
    this.gridsize = event.value;

   
    //this.onSideNavOpened()
  }

  mat_slider
  

  buttonsList
  onSideNavOpened() {

    setTimeout(function(){
      this.buttonsList = document.getElementsByClassName('mat-slider-eot');

      for(let currentButton of this.buttonsList) {
        //currentButton.classList.remove("cdk-mouse-focused");
         currentButton.classList.remove("cdk-focused");
        // currentButton.classList.remove("cdk-program-focused");
      }


      for(let currentButton of this.buttonsList) {
        // currentButton.classList.add("cdk-focused");
        // currentButton.classList.add("cdk-program-focused");
      }
    
    }, 500);
   
    
  }

  


}
