import { AlertDialogDynamicComponent } from './../alert-dialog-dynamic/alert-dialog-dynamic.component';
import { Injectable } from '@angular/core';
import { Restangular } from 'ngx-restangular';
import { AppUrls} from '../app-storagekeys-urls';
import { MatDialog } from '@angular/material/dialog';
import { AlertDailogComponent } from '../alert-dailog/alert-dailog.component';
import { Location } from '@angular/common';


@Injectable({
  providedIn: 'root'
})
export class EndOfTourService {

  constructor(private restangular: Restangular, private matDialog: MatDialog,
    private location: Location, ) { }

  Urls = AppUrls()
  currentUserData
  restHeader


  get_endOfTourSurvey(callback) {

    console.log("EOOT: get_endOfTourSurvey")


    var id = localStorage.getItem('LStourListId');

  if ((localStorage.getItem("UserData")) != null) {
    this.currentUserData = JSON.parse(localStorage.getItem("UserData"));
}

var user_language = "";
if (window.localStorage.getItem("selected_language_by_user") == null) {
    user_language = this.currentUserData.user.language;
} else {
    user_language = window.localStorage.getItem("selected_language_by_user");
}


this.restHeader = this.currentUserData.user.email + ':' + this.currentUserData.token + ':' + user_language


    this.restangular.withConfig((RestangularConfigurer) => {
        RestangularConfigurer.setDefaultHeaders({
            'Content-Type': 'application/json',
            'Authorization': this.restHeader
        });
    }).one(this.Urls.endofTourSurveyURL).get({
        itinerary_id: id
    }).toPromise().then(response => {
      console.log("EOOT: get_endOfTourSurvey",response)
        // Sucess of getting data
        console.log(" End of Tour Form", response)
        callback(response)
    }, err => {

      console.log("EOOT: get_endOfTourSurvey   ERROR")
        // Error of getting data
        console.log(err)
        callback(err.data)
    })
}


submitFinalSurvey(id, slider_value, survey, matDialog) {
  
  // var LSDuserinfoProvider = JSON.parse(localStorage.getItem('userInfoProvider'));

  var translationsLabels = JSON.parse(localStorage.getItem("UserData")).user.translations

  var iti_id = localStorage.getItem("LStourListId");

  

  let request_params_array = []
  let request_params = {};
  request_params["itinerary_id"] = iti_id;
  request_params["final_survey_id"] = id
  request_params["form_response"] = survey
  request_params["overall_score"] = slider_value

  request_params_array.push(request_params)

  if ((localStorage.getItem("UserData")) != null) {
    this.currentUserData = JSON.parse(localStorage.getItem("UserData"));
}

var user_language = "";
if (window.localStorage.getItem("selected_language_by_user") == null) {
    user_language = this.currentUserData.user.language;
} else {
    user_language = window.localStorage.getItem("selected_language_by_user");
}


this.restHeader = this.currentUserData.user.email + ':' + this.currentUserData.token + ':' + user_language


  this.restangular.withConfig((RestangularConfigurer) => {
      RestangularConfigurer.setDefaultHeaders({
          'Content-Type': 'application/json',
          'Authorization': this.restHeader
      });
  }).all(this.Urls.urlEos).post({
      final_surveys: request_params_array
  }).toPromise().then((response) => {
      console.log("submit final survey", response);

      const dialogRef = matDialog.open(AlertDialogDynamicComponent, {
        data: { message: translationsLabels.survey_submitted }
        });
        
        dialogRef.afterClosed().subscribe(result => {
        console.log(`Dialog result: ${result}`);

        ///uzzzo

        this.location.back()

        });

     
      // let sendMailFailed = this.modal.create('AlertModalOK', {
      //     message: LSDuserinfoProvider.translations.survey_submitted,
      //     showFailText: false,
      //     data: "survey_submitted",
      //     callback_ok: this.onOK,
      //     ok_btn_text: LSDuserinfoProvider.translations.ok
      // }, {
      //     cssClass: 'settings-modal'
      // });
      // sendMailFailed.present()

      //EOC (307) ERICA



  });
}

SurveyFactoryPut(itemSurveyArr) {
  // Updating survey from user to cms
  var LSDuserinfoProvider = JSON.parse(localStorage.getItem('userInfoProvider'));

  if ((localStorage.getItem("UserData")) != null) {
    this.currentUserData = JSON.parse(localStorage.getItem("UserData"));
}

  var user_language = "";
if (window.localStorage.getItem("selected_language_by_user") == null) {
    user_language = this.currentUserData.user.language;
} else {
    user_language = window.localStorage.getItem("selected_language_by_user");
}


this.restHeader = this.currentUserData.user.email + ':' + this.currentUserData.token + ':' + user_language


  var updateSurvey = this.restangular.withConfig((RestangularConfigurer) => {
      RestangularConfigurer.setDefaultHeaders({
          'Content-Type': 'application/json',
          'Authorization': this.restHeader
      });
  }).one(this.Urls.dailySurveyURL)
  updateSurvey.surveys = itemSurveyArr;
  updateSurvey.doPUT().toPromise().then((response) => {

      if (response.message == 'Successful') {

          //BOC (307) ERICA
          {

            var translationsLabels = JSON.parse(localStorage.getItem("UserData")).user.translations

            const dialogRef = this.matDialog.open(AlertDialogDynamicComponent, {
              data: { message: translationsLabels.feedback_submitted }
              });
              
              dialogRef.afterClosed().subscribe(result => {
              console.log(`Dialog result: ${result}`);
              this.location.back()
              //uzzo
              });


              // let sendMailFailed = this.modal.create(
              //     'ToastModal', {
              //         // message: LSDuserinfoProvider.translations.done,
              //         message: LSDuserinfoProvider.translations.feedback_submitted,
              //         showFailText: false
              //     }, {
              //         cssClass: 'settings-modal'
              //     }
              // );
              // sendMailFailed.present()

              // setTimeout(() => {
              //     sendMailFailed.dismiss()
              // }, 2000);


          } //EOC (307) ERICA

      }

  }, err => {
      // Error while updating the survey
  })

}





}
