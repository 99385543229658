import { Injectable } from '@angular/core';
import { Restangular } from 'ngx-restangular';
import { AppUrls} from '../app-storagekeys-urls';

@Injectable({
  providedIn: 'root'
})
export class ContactService {

  restHeader
  currentUserData

  Urls = AppUrls()

  constructor(private restangular: Restangular) { }

  



  ContactsFactoryListAll(callback){


    var id = localStorage.getItem('LStourListId');

    if ((localStorage.getItem("UserData")) != null) {
      this.currentUserData = JSON.parse(localStorage.getItem("UserData"));
  }

  var user_language = "";
  if (window.localStorage.getItem("selected_language_by_user") == null) {
      user_language = this.currentUserData.user.language;
  } else {
      user_language = window.localStorage.getItem("selected_language_by_user");
  }


  this.restHeader = this.currentUserData.user.email + ':' + this.currentUserData.token + ':' + user_language

 


    this.restangular.withConfig((RestangularConfigurer) => {
      RestangularConfigurer.setDefaultHeaders({
      'Content-Type': 'application/json',
      'Authorization':  this.restHeader
      });
    }).one(this.Urls.contactlistUrl).get({
      itinerary_id: id
    }).toPromise().then(function (response) {

      console.log("Load:: 2: ",response);

      console.log('Get contact list info in ContactFactory: ',response.contacts);
      callback(response.contacts);
    }, function (err) {
      console.log("Load:x: 2: ",err);

      console.log('Error ContactFactory: ',err);
      callback("error");
    });
  }



  StoreEmergencyContact(callback){
    var type_id = "Emergency Contact";
    var id = localStorage.getItem('LStourListId');

    if ((localStorage.getItem("UserData")) != null) {
      this.currentUserData = JSON.parse(localStorage.getItem("UserData"));
  }

  var user_language = "";
  if (window.localStorage.getItem("selected_language_by_user") == null) {
      user_language = this.currentUserData.user.language;
  } else {
      user_language = window.localStorage.getItem("selected_language_by_user");
  }


  this.restHeader = this.currentUserData.user.email + ':' + this.currentUserData.token + ':' + user_language


    this.restangular.withConfig((RestangularConfigurer) => {
      RestangularConfigurer.setDefaultHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.restHeader
      });
    }).one(this.Urls.contactlistUrl).get({
      type: type_id,
      itinerary_id: id
    }).toPromise().then(function (response) {

      console.log("Load:: 3: ",response);
      console.log('Get contact list info in ContactFactory: ',response.contacts);
      callback(response.contacts);
    }, function (err) {
      console.log("Load:x: 3: ",err);
      console.log('Error ContactFactory: ',err);
      callback('error')
    });
  }





}
