import { HttpEvent } from '@angular/common/http/src/response';
//(01): Erica Dynamic color for custom menu fix
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router'
import {ActivatedRoute} from '@angular/router'
import { CustomMenuService } from './../services/custom-menu.service';
import {DomSanitizer} from '@angular/platform-browser';
import { MatIconRegistry } from "@angular/material/icon";
import { SubMenuServiceService } from './../services/sub-menu-service.service';
import * as $ from 'jquery';
import { Location } from '@angular/common';



@Component({
  selector: 'app-custom',
  templateUrl: './custom.component.html',
  styleUrls: ['./custom.component.css']
})
export class CustomComponent implements OnInit {

  tourListProvider//(01)
  translationsLabels
  header_img

  constructor(private router: Router,
     private route : ActivatedRoute,
    public custommenuservice: CustomMenuService,//(03) Erica
    public domSanitizer:DomSanitizer,
    private matIconRegistry: MatIconRegistry,
    private submenuervice: SubMenuServiceService,
    private location: Location,) {


       // BOC Erica (01)
       var index = (localStorage.getItem('INDEX_WELCOME'))
       this.tourListProvider = JSON.parse(localStorage.getItem("tourListProvider"))

       this.translationsLabels = JSON.parse(localStorage.getItem("UserData")).user.translations

       this.header_img = JSON.parse(localStorage.getItem("LSDitinerarydata")).itinerary.imgSrc


       setTimeout(() => {
         
           if (this.tourListProvider[index].brand.length == undefined) {
               console.log("TEST IS ON", this.tourListProvider[index].brand.color)
               $('#Header').text('app-header .bbb svg{fill:'+ this.tourListProvider[index].brand.color + ' !important; stroke-opacity:0 !important}'+
            //  'app-custom .bbb svg rect{fill:'+ this.tourListProvider[index].brand.color +' !important ; stroke-opacity:0 !important}'+
            //    'app-custom .bbb svg polygon{fill:'+ this.tourListProvider[index].brand.color+ ' !important ; stroke-opacity:0 !important}'+
            //   'app-custom .bbb svg path{fill:'+ this.tourListProvider[index].brand.color +' !important; stroke-opacity:0 !important}'+
            //   'app-custom .bbb svg path:nth-of-type(n){fill:'+ this.tourListProvider[index].brand.color +' !important; stroke-opacity:0 !important}'+
            //   'app-custom .bbb svg path:nth-of-type(1){fill:'+ this.tourListProvider[index].brand.color +' !important; stroke-opacity:0 !important}'+
            //   'app-custom .bbb svg path:nth-of-type(2){fill:'+ this.tourListProvider[index].brand.color +' !important; stroke-opacity:0 !important}'+
            //   'app-custom .bbb svg path:nth-of-type(3){fill:'+ this.tourListProvider[index].brand.color +' !important; stroke-opacity:0 !important}'+
           
             
             
              'app-header .bbb svg{fill:'+ this.tourListProvider[index].brand.color + ' !important; stroke-opacity:0 !important}'+
              'app-header .bbb svg rect{fill:'+ this.tourListProvider[index].brand.color +' !important ; stroke-opacity:0 !important}'+
                'app-header .bbb svg polygon{fill:'+ this.tourListProvider[index].brand.color+ ' !important ; stroke-opacity:0 !important}'+
               'app-header .bbb svg path{fill:'+ this.tourListProvider[index].brand.color +' !important; stroke-opacity:0 !important}'+
               'app-header .bbb svg path:nth-of-type(n){fill:'+ this.tourListProvider[index].brand.color +' !important; stroke-opacity:0 !important}'+
               'app-header .bbb svg path:nth-of-type(1){fill:'+ this.tourListProvider[index].brand.color +' !important; stroke-opacity:0 !important}'+
               'app-header .bbb svg path:nth-of-type(2){fill:'+ this.tourListProvider[index].brand.color +' !important; stroke-opacity:0 !important}'+
               'app-header .bbb svg path:nth-of-type(3){fill:'+ this.tourListProvider[index].brand.color +' !important; stroke-opacity:0 !important}'+
            
              
              
               'app-header .ccc svg{fill:white !important; stroke-opacity:0 !important}'+
               'app-header .ccc svg rect{fill:white !important; stroke-opacity:0 !important}'+
               'app-header .ccc svg polygon:nth-of-type(n){fill:white !important; stroke-opacity:0 !important}'+
               'app-header .ccc svg polygon:nth-of-type(1){fill:white !important; stroke-opacity:0 !important}'+
               'app-header .ccc svg path:nth-of-type(1){fill:white !important; stroke-opacity:0 !important}'+
               'app-header .ccc svg path:nth-of-type(2){fill:white !important; stroke-opacity:0 !important}'+
               'app-header .ccc svg path:nth-of-type(3){fill:white !important; stroke-opacity:0 !important}'+
               'app-header .ccc svg path:nth-of-type(n){fill:white !important; stroke-opacity:0 !important}'+
                 'app-header .ccc svg polygon{fill:white !important; stroke-opacity:0 !important}'+
                'app-header .ccc svg path{fill:white !important; stroke-opacity:0 !important}'
                
           
           
               );
           } else {
               console.log("Entry else", JSON.parse(localStorage.getItem("primaryColor")))
               // $('#Header').text('app-header .bbb svg{fill:' + JSON.parse(localStorage.getItem("primaryColor")) + ' !important;}'
           
           
               // );
           }
           }, 500);
            // EOC Erica (01)


     
            setTimeout(()=>{this.check_height()},100) 

          }
      
          show_scroll_up_btn=false
          check_height(){

           
              if($(window).height()>= $(".mainContainer").height()+250){
                this.show_scroll_up_btn=false;
              }else if($(window).height()< $(".mainContainer").height()+250){
                this.show_scroll_up_btn=true;
              }
        
             
            }
      
            scroll_up(){
        
              $(window).scrollTop( $("body").offset().top );
          }
  content_type
description
display_name
icon
icon_url

menu_type
name
pdf_description
sub_menus
url


  custom_menu


pdf_url
 

  ngOnInit() {
    this.custom_menu=this.custommenuservice.getData();


    if(this.custom_menu!={}){

      this.svgregister_icon(this.custom_menu)

      if(this.custom_menu.menu_type== 0 && this.custom_menu.content_type == 0){
       
        var str = this.custom_menu.description; 
        // var res = str.replace("<a", "<a  target='_blank' ");
        var newStr = str.replace(/<a/g, "<a  target='_blank' ");
        
    
          this.description = newStr;
        
       
      }

      if(this.custom_menu.menu_type== 0 && this.custom_menu.content_type == 2){

        var str = this.custom_menu.url; 
        var res = str.replace("/watch?v=", "/embed/");
       
        
        // var url_san = this.domSanitizer.bypassSecurityTrustResourceUrl(res);

        this.url =this.updatelinksks(res);
      }

      if(this.custom_menu.menu_type== 0 && this.custom_menu.content_type == 1){
        this.pdf_url = this.domSanitizer.bypassSecurityTrustResourceUrl(this.custom_menu.pdf_description);
      }

      if(this.custom_menu.menu_type== 1 && this.custom_menu.content_type == 0){

        this.sub_menus = this.custom_menu.sub_menus



        this.svgregistercustom_icon(this.sub_menus)

      }
   


   

    

    }
     if(Object.keys(this.custom_menu).length === 0){

      this.location.back();
    }

    



    
  }

  svgregister_icon( customIconSubMenus) {

    
        this.matIconRegistry.addSvgIcon(
          customIconSubMenus.name,
            this.domSanitizer.bypassSecurityTrustResourceUrl(customIconSubMenus.icon_url)
        );
   
  }

  svgregistercustom_icon( customIconSubMenus) {

    customIconSubMenus.forEach(element => {
        this.matIconRegistry.addSvgIcon(
            element.name,
            this.domSanitizer.bypassSecurityTrustResourceUrl(element.icon_url)
        );
    });
  }

  

  openMenu(sub_custom_menu)
  {
     
         

          this.router.navigate(['/sub-custom-menu']);

          this.submenuervice.setData(sub_custom_menu);
      
          


    

  }

  
updatelinksks(link){
  var re = /\/\//gi; 
  var str = link;//"www.google.com" ;//"https://www.pinterest.ca/";//www.google.com
  if (str.search(re) == -1 ) { 

  
   str="http://"+str
  

   return str;
  
  } else { 
   
  
     var re = /https:\/\//gi; 
  
     var newstr = str.replace(re, "http://"); 
   

     return newstr
}
}




}
