﻿// import { Component } from '@angular/core';

import { HostListener, Component } from "@angular/core";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
    title = 'TineriWeb';

    @HostListener('click', ['$event.target'])
    scrollHandler() {
      //console.log('button', 'number of clicks:' );
   }

 
}
