import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CustomMenuService {

  sharedData ={}

  constructor() { 
    
  }

  setData(val){
    this.sharedData= val;
  }
  getData(){
    return this.sharedData;
  }
}
