import { Injectable } from '@angular/core';
import { Restangular } from 'ngx-restangular';
import { AppUrls} from '../app-storagekeys-urls'

@Injectable({
  providedIn: 'root'
})
export class TineriTipService {

  Urls = AppUrls()
  currentUserData
  restHeader

  constructor(private restangular: Restangular) { }


  getHasTag(hashTag, callback) {

    var id = localStorage.getItem('LStourListId');
    if ((localStorage.getItem("UserData")) != null) {
      this.currentUserData = JSON.parse(localStorage.getItem("UserData"));
  }

  var user_language = "";
  if (window.localStorage.getItem("selected_language_by_user") == null) {
      user_language = this.currentUserData.user.language;
  } else {
      user_language = window.localStorage.getItem("selected_language_by_user");
  }


  this.restHeader = this.currentUserData.user.email + ':' + this.currentUserData.token + ':' + user_language



    this.restangular.withConfig((RestangularConfigurer) => {
      RestangularConfigurer.setDefaultHeaders({
        'Content-Type': 'application/json',
        'Authorization': this.restHeader
      });
      
    }).one(this.Urls.urlHasTag).get({
      name: hashTag
    }).toPromise().then(function (response) {
      callback(response);
    }, function (error) {
      callback("error")
      console.log("HashTagFactory failed.!")
    });
  }
}
