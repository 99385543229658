﻿
export function AppUrls(){
  return{

    loginUrl: "/sessions",
    PasswordUrl: "/passwords",
    company: "/organizations/basic",
    TourList : "/itineraries/list",
    TourShow: "/itineraries/show",
    hotellist: '/hotels/list',
    explist: '/experiences/list',
    mealslist: '/meals/list',
    guideslist: '/contacts/list',
    languageURL: '/users/change_language',
    TipsSubUrl: '/hash_tags/list',
    transportSubUrl : '/transportations/list',//Added by Erica tranport feature from scratch
    flightsSubUrl : '/flights/list', // Added by Erica transport feature from scartch
    subUrl : '/itineraries/show', // Added by Erica transport feature from scartch
    custMenuUrl : 'custom_menus/list',// Added by Erica Custom menu feature from scratch
    resetPassword: "/passwords/reset",//Added by Hrushikesh Change password
    extrasUrl:'/extras/list',//Added By Erica
    docCategoriesUrl : '/documents/list_categories',// Added by Erica 
    documentListUrl : '/documents/list',// Added by Erica
    subGetusers : 'itineraries/fetch_users',//Added by  Erica
    subGetlist : '/documents/list_categories',//Added by  Erica
    subGet : '/documents/list',//Added by  Erica
    apiuploaddoc:AppRootUrls().stagingUrl,// Erica  Added 
    urlHasTag : '/hash_tags/show',// Erica Added 
    endofTourSurveyURL : "/final_surveys",// Added by Erica 
    urlEos : "/final_surveys",// Added by Erica
    dailySurveyURL : '/surveys/submit',// Added by Erica
    contactlistUrl : '/contacts/list',
   
  }
}

export function AppLocalStorageKeys(){
  return{
    restHeader :"restHeader"
  }
}

export function AppRootUrls(){
  return{
    productionUrl :"restHeader",
   // stagingUrl: "http://54.169.128.166/api/v1/"
    //stagingUrl: "http://54.169.46.114/api/v1/"

    // stagingUrl: "http://tinericmstest.co/api/v1/"

   stagingUrl:"https://tinericms.com/api/v1/"
    
  }
}


// export class AppStorageKeysAndUrls { }
