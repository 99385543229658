import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PassSelectedExtraService {

  day
  extra

  constructor() { }

  setData(day, extra){
    console.log("PASSSTTTT             ",day)
    console.log("PASSSTTTT             ",extra)
    this.day= day;
    this.extra= extra;
  }
  getDay(){
    return this.day;
  }
  getExtra(){
    return this.extra;
  }

}
