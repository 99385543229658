﻿import { EndOfTourComponent } from './../end-of-tour/end-of-tour.component';

//(01) Erica : Display Feature :  Implementation
//(02) Erica : Custom Menu :  Implementation
//(03) Erica : Custom Menu :  Implementation
//04: HRUSHIKESH 2-june-2020 Guides design change 
//05: HRUSHIKESH 28-May-2020 Organization color change
//06: Erica : Dynamic color for custom Menu
//07: Hrushikesh 24-06-2020 Change password
//(08) Erica : Document :  Implementation
import { Component, OnInit } from '@angular/core';
// import { Observable } from 'rxjs'
import { DataServiceService } from '../services/data-service.service'
import { AuthenticationService } from '../services/authentication.service'
import { Router } from '@angular/router'
import { Location } from '@angular/common';
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";
import * as moment from 'moment';
import { ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ChangeLanguageComponent } from '../change-language/change-language.component';

import { TourService } from '../services/tour.service';//(01) Erica
import { CompanyFactoryService } from './../services/company-factory.service';// (02) Erica
import * as $ from 'jquery';

import { CustomMenuService } from './../services/custom-menu.service';
import { PagenameService } from './../services/pagename.service';



@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class HeaderComponent implements OnInit {
    show
    currentUserData
    logo =''
    userinfo
    traveller
    pagename
    itinerarydata
    translationsLabels
    displayFeatures//Erica(01)
    customIconSubMenus//Erica(02)
    tourListProvider
    myCustomColor

    IsSinglePDF = "false"
    IsHomeDescPDF ="false"
    
    
    constructor(private dataService: DataServiceService, 
        private authService: AuthenticationService, private router: Router, 
        private location: Location, 
        private matIconRegistry: MatIconRegistry,
        private domSanitizer: DomSanitizer, 
        private matDialog: MatDialog,
        public tourService: TourService, //(01) Erica
        public  companyFactoryService:CompanyFactoryService ,//(02) Erica
        public custommenuservice: CustomMenuService,//(03) Erica
        public pageNameService:PagenameService) {

           

           // this.logo=JSON.parse(localStorage.getItem("companyProvider")).logo

        this.dataService.isLoggedIn.subscribe(data => {
           
            this.currentUserData = data
        })

        
        this.tourListProvider = JSON.parse(localStorage.getItem("tourListProvider"))

        // BOC Erica (06)
        var index = (localStorage.getItem('INDEX_WELCOME'))

        if (this.tourListProvider[index].brand.color != undefined) {
               
            this.logo=this.tourListProvider[index].brand.logo

           
        } else if(this.tourListProvider[index].brand.color == undefined) {
        

            this.logo=JSON.parse(localStorage.getItem("companyProvider")).logo

          
        }
        

        setTimeout(() => {
            if (this.tourListProvider[index].brand.color != undefined) {
               
                this.logo=this.tourListProvider[index].brand.logo

                $('#custommenu').text('app-header .bbb svg{fill:'+ this.tourListProvider[index].brand.color + ' !important; stroke-opacity:0 !important}'+
              'app-header .bbb svg rect{fill:'+ this.tourListProvider[index].brand.color +' !important ; stroke-opacity:0 !important}'+
                'app-header .bbb svg polygon{fill:'+ this.tourListProvider[index].brand.color+ ' !important ; stroke-opacity:0 !important}'+
               'app-header .bbb svg path{fill:'+ this.tourListProvider[index].brand.color +' !important; stroke-opacity:0 !important}'+
               'app-header .bbb svg path:nth-of-type(n){fill:'+ this.tourListProvider[index].brand.color +' !important; stroke-opacity:0 !important}'+
               'app-header .bbb svg path:nth-of-type(1){fill:'+ this.tourListProvider[index].brand.color +' !important; stroke-opacity:0 !important}'+
               'app-header .bbb svg path:nth-of-type(2){fill:'+ this.tourListProvider[index].brand.color +' !important; stroke-opacity:0 !important}'+
               'app-header .bbb svg path:nth-of-type(3){fill:'+ this.tourListProvider[index].brand.color +' !important; stroke-opacity:0 !important}'+
            
              
              
               'app-header .ccc svg{fill:white !important; stroke-opacity:0 !important}'+
               'app-header .ccc svg rect{fill:white !important; stroke-opacity:0 !important}'+
               'app-header .ccc svg polygon:nth-of-type(n){fill:white !important; stroke-opacity:0 !important}'+
               'app-header .ccc svg polygon:nth-of-type(1){fill:white !important; stroke-opacity:0 !important}'+
               'app-header .ccc svg path:nth-of-type(1){fill:white !important; stroke-opacity:0 !important}'+
               'app-header .ccc svg path:nth-of-type(2){fill:white !important; stroke-opacity:0 !important}'+
               'app-header .ccc svg path:nth-of-type(3){fill:white !important; stroke-opacity:0 !important}'+
               'app-header .ccc svg path:nth-of-type(n){fill:white !important; stroke-opacity:0 !important}'+
                 'app-header .ccc svg polygon{fill:white !important; stroke-opacity:0 !important}'+
                'app-header .ccc svg path{fill:white !important; stroke-opacity:0 !important}'+
                

                ' .contact_icons {color:'+ this.tourListProvider[index].brand.color +' !important;}'+

                '#colorful_divider { border-bottom: 1px solid'+ this.tourListProvider[index].brand.color  +' !important; opacity:0.5;}'

                +'li[aria-expanded="true"] a{ color: '+ this.tourListProvider[index].brand.color  +' !important; }'

                +'.custom-menu-description a{ color: '+ this.tourListProvider[index].brand.color  +' !important; }'

                // 'app-header .option_menu_active{color:'+this.tourListProvider[index].brand.color+'!important;}'
                
            
            
                );
            } else if(this.tourListProvider[index].brand.color == undefined) {
            

                this.logo=JSON.parse(localStorage.getItem("companyProvider")).logo


                $('#custommenu').text('app-header .bbb svg{fill:'+ JSON.parse(localStorage.getItem("primaryColor")) + ' !important; stroke-opacity:0 !important}'+
              'app-header .bbb svg rect{fill:'+ JSON.parse(localStorage.getItem("primaryColor")) +' !important ; stroke-opacity:0 !important}'+
                'app-header .bbb svg polygon{fill:'+ JSON.parse(localStorage.getItem("primaryColor"))+ ' !important ; stroke-opacity:0 !important}'+
               'app-header .bbb svg path{fill:'+ JSON.parse(localStorage.getItem("primaryColor")) +' !important; stroke-opacity:0 !important}'+
               'app-header .bbb svg path:nth-of-type(n){fill:'+ JSON.parse(localStorage.getItem("primaryColor")) +' !important; stroke-opacity:0 !important}'+
               'app-header .bbb svg path:nth-of-type(1){fill:'+ JSON.parse(localStorage.getItem("primaryColor")) +' !important; stroke-opacity:0 !important}'+
               'app-header .bbb svg path:nth-of-type(2){fill:'+ JSON.parse(localStorage.getItem("primaryColor")) +' !important; stroke-opacity:0 !important}'+
               'app-header .bbb svg path:nth-of-type(3){fill:'+ JSON.parse(localStorage.getItem("primaryColor")) +' !important; stroke-opacity:0 !important}'+
            
              
              
               'app-header .ccc svg{fill:white !important; stroke-opacity:0 !important}'+
               'app-header .ccc svg rect{fill:white !important; stroke-opacity:0 !important}'+
               'app-header .ccc svg polygon:nth-of-type(n){fill:white !important; stroke-opacity:0 !important}'+
               'app-header .ccc svg polygon:nth-of-type(1){fill:white !important; stroke-opacity:0 !important}'+
               'app-header .ccc svg path:nth-of-type(1){fill:white !important; stroke-opacity:0 !important}'+
               'app-header .ccc svg path:nth-of-type(2){fill:white !important; stroke-opacity:0 !important}'+
               'app-header .ccc svg path:nth-of-type(3){fill:white !important; stroke-opacity:0 !important}'+
               'app-header .ccc svg path:nth-of-type(n){fill:white !important; stroke-opacity:0 !important}'+
                 'app-header .ccc svg polygon{fill:white !important; stroke-opacity:0 !important}'+
                'app-header .ccc svg path{fill:white !important; stroke-opacity:0 !important}'+
                ' .contact_icons {color:'+  JSON.parse(localStorage.getItem("primaryColor")) +' !important;}'+
                '#colorful_divider { border-bottom: 1px solid'+  JSON.parse(localStorage.getItem("primaryColor")) +' !important;opacity:0.5;}'

                +'li[aria-expanded="true"] a{ color: '+ JSON.parse(localStorage.getItem("primaryColor"))   +' !important; }'

                +'.custom-menu-description a{ color: '+JSON.parse(localStorage.getItem("primaryColor"))   +' !important; }'
                // 'app-header .option_menu_active{color:'+JSON.parse(localStorage.getItem("primaryColor"))+'!important;}'
            
                );

                

                // $('#Header').text('app-header .bbb svg{fill:' + JSON.parse(localStorage.getItem("primaryColor")) + ' !important;}'
            
            
                // );
            }
            this.resetCss()
            }, 500);
             // EOC Erica (06)

        this.svgregister();

        this.myCustomColor='red'
      
        this.width_check();




        var scope=this
          $(window).resize(function(){
            

             
              scope.width_check();
          });

          this.scroll_up();

          this.check_single_itinerary_pdf();
          this.check_home_desc_pdf();

    }

    resetCss(){
        var index = (localStorage.getItem('INDEX_WELCOME'))
        setTimeout(() => {
            if (this.tourListProvider[index].brand.color != undefined) {
               
                this.logo=this.tourListProvider[index].brand.logo

                $('#custommenu').text('app-header .bbb svg{fill:'+ this.tourListProvider[index].brand.color + ' !important; stroke-opacity:0 !important}'+
              'app-header .bbb svg rect{fill:'+ this.tourListProvider[index].brand.color +' !important ; stroke-opacity:0 !important}'+
                'app-header .bbb svg polygon{fill:'+ this.tourListProvider[index].brand.color+ ' !important ; stroke-opacity:0 !important}'+
               'app-header .bbb svg path{fill:'+ this.tourListProvider[index].brand.color +' !important; stroke-opacity:0 !important}'+
               'app-header .bbb svg path:nth-of-type(n){fill:'+ this.tourListProvider[index].brand.color +' !important; stroke-opacity:0 !important}'+
               'app-header .bbb svg path:nth-of-type(1){fill:'+ this.tourListProvider[index].brand.color +' !important; stroke-opacity:0 !important}'+
               'app-header .bbb svg path:nth-of-type(2){fill:'+ this.tourListProvider[index].brand.color +' !important; stroke-opacity:0 !important}'+
               'app-header .bbb svg path:nth-of-type(3){fill:'+ this.tourListProvider[index].brand.color +' !important; stroke-opacity:0 !important}'+
            
              
              
               'app-header .ccc svg{fill:white !important; stroke-opacity:0 !important}'+
               'app-header .ccc svg rect{fill:white !important; stroke-opacity:0 !important}'+
               'app-header .ccc svg polygon:nth-of-type(n){fill:white !important; stroke-opacity:0 !important}'+
               'app-header .ccc svg polygon:nth-of-type(1){fill:white !important; stroke-opacity:0 !important}'+
               'app-header .ccc svg path:nth-of-type(1){fill:white !important; stroke-opacity:0 !important}'+
               'app-header .ccc svg path:nth-of-type(2){fill:white !important; stroke-opacity:0 !important}'+
               'app-header .ccc svg path:nth-of-type(3){fill:white !important; stroke-opacity:0 !important}'+
               'app-header .ccc svg path:nth-of-type(n){fill:white !important; stroke-opacity:0 !important}'+
                 'app-header .ccc svg polygon{fill:white !important; stroke-opacity:0 !important}'+
                'app-header .ccc svg path{fill:white !important; stroke-opacity:0 !important}'+
                

                ' .contact_icons {color:'+ this.tourListProvider[index].brand.color +' !important;}'+

                '#colorful_divider { border-bottom: 1px solid'+ this.tourListProvider[index].brand.color  +' !important; opacity:0.5;}'

                +'li[aria-expanded="true"] a{ color: '+ this.tourListProvider[index].brand.color  +' !important; }'

                +'.custom-menu-description a{ color: '+ this.tourListProvider[index].brand.color  +' !important; }'

                // 'app-header .option_menu_active{color:'+this.tourListProvider[index].brand.color+'!important;}'
                
            
            
                );
            } else if(this.tourListProvider[index].brand.color == undefined) {
            

                this.logo=JSON.parse(localStorage.getItem("companyProvider")).logo


                $('#custommenu').text('app-header .bbb svg{fill:'+ JSON.parse(localStorage.getItem("primaryColor")) + ' !important; stroke-opacity:0 !important}'+
              'app-header .bbb svg rect{fill:'+ JSON.parse(localStorage.getItem("primaryColor")) +' !important ; stroke-opacity:0 !important}'+
                'app-header .bbb svg polygon{fill:'+ JSON.parse(localStorage.getItem("primaryColor"))+ ' !important ; stroke-opacity:0 !important}'+
               'app-header .bbb svg path{fill:'+ JSON.parse(localStorage.getItem("primaryColor")) +' !important; stroke-opacity:0 !important}'+
               'app-header .bbb svg path:nth-of-type(n){fill:'+ JSON.parse(localStorage.getItem("primaryColor")) +' !important; stroke-opacity:0 !important}'+
               'app-header .bbb svg path:nth-of-type(1){fill:'+ JSON.parse(localStorage.getItem("primaryColor")) +' !important; stroke-opacity:0 !important}'+
               'app-header .bbb svg path:nth-of-type(2){fill:'+ JSON.parse(localStorage.getItem("primaryColor")) +' !important; stroke-opacity:0 !important}'+
               'app-header .bbb svg path:nth-of-type(3){fill:'+ JSON.parse(localStorage.getItem("primaryColor")) +' !important; stroke-opacity:0 !important}'+
            
              
              
               'app-header .ccc svg{fill:white !important; stroke-opacity:0 !important}'+
               'app-header .ccc svg rect{fill:white !important; stroke-opacity:0 !important}'+
               'app-header .ccc svg polygon:nth-of-type(n){fill:white !important; stroke-opacity:0 !important}'+
               'app-header .ccc svg polygon:nth-of-type(1){fill:white !important; stroke-opacity:0 !important}'+
               'app-header .ccc svg path:nth-of-type(1){fill:white !important; stroke-opacity:0 !important}'+
               'app-header .ccc svg path:nth-of-type(2){fill:white !important; stroke-opacity:0 !important}'+
               'app-header .ccc svg path:nth-of-type(3){fill:white !important; stroke-opacity:0 !important}'+
               'app-header .ccc svg path:nth-of-type(n){fill:white !important; stroke-opacity:0 !important}'+
                 'app-header .ccc svg polygon{fill:white !important; stroke-opacity:0 !important}'+
                'app-header .ccc svg path{fill:white !important; stroke-opacity:0 !important}'+
                ' .contact_icons {color:'+  JSON.parse(localStorage.getItem("primaryColor")) +' !important;}'+
                '#colorful_divider { border-bottom: 1px solid'+  JSON.parse(localStorage.getItem("primaryColor")) +' !important;opacity:0.5;}'

                +'li[aria-expanded="true"] a{ color: '+ JSON.parse(localStorage.getItem("primaryColor"))   +' !important; }'

                +'.custom-menu-description a{ color: '+JSON.parse(localStorage.getItem("primaryColor"))   +' !important; }'
                // 'app-header .option_menu_active{color:'+JSON.parse(localStorage.getItem("primaryColor"))+'!important;}'
            
                );

                

                // $('#Header').text('app-header .bbb svg{fill:' + JSON.parse(localStorage.getItem("primaryColor")) + ' !important;}'
            
            
                // );
            }
            }, 500);  
    }

    width_check(){
        var width=$(window).width();

        if(width>600){

          this.hide_vertical_navbar=true;
        }else if(width<=600){
          this.hide_vertical_navbar=false;
        }
    }

    hide_vertical_navbar

    toogle_lhs_navbar(){
        this.hide_vertical_navbar=!this.hide_vertical_navbar;
    }

 


    //07: BOC HRUSHIKESH
    changepassword() {

        this.menu_click_disable()

       
    
        const dialogRef = this.matDialog.open(ChangeLanguageComponent, {
            data: { message: "password" }
        });

        dialogRef.afterClosed().subscribe(result => {
       
        });
    }
    //EOC HRUSHIKESH
    
    ngOnInit() {

    

       
       //05: BOC HRUSHIKESH := Organization color applied if exist . Else company primary color applied
       var index = (localStorage.getItem('INDEX_WELCOME'))
     
       if (this.tourListProvider[index].brand.length == undefined) {
        
           $('#Header').text('.custom-icon-selected { fill:' + this.tourListProvider[index].brand.color + ' !important;}'
   
   
           );
          
       } else {
      
           $('#Header').text('.custom-icon-selected {fill:' + JSON.parse(localStorage.getItem("primaryColor")) + ' !important;}'
   
   
           );
       }
       //EOC HRUSHIKESH

        if (this.authService.userData) {
            this.currentUserData = JSON.parse(this.authService.userData);

        }
        this.translationsLabels = ""

        // BOC Erica (01)
        var TourListOffline = JSON.parse(localStorage.getItem('tourListProvider'));

        if(TourListOffline.length == 0 ){

         
            //If the user see's header before local storage values are set in welcome page 
            // we fetch the live values
            this.tourService.show(data => {

                {
                 
                    this.displayFeatures = data.itinerary.display_feature;

                    this.getCustomIconData(this.displayFeatures) ;//BOC ERICA (02)
                    localStorage.setItem("display_feature",JSON.stringify(data.itinerary.display_feature));
          
                    if (localStorage.getItem("user_role") != "Travellers" &&
                     localStorage.getItem("user_role") != "Admin" 
                     && localStorage.getItem("user_role") != "Guides") {
                      this.displayFeatures.itinerary.survey = false;
          
                    }
                    this.displayFeatures.contact_main = this.showMainFeatures( this.displayFeatures.contacts);
                    this.displayFeatures.destination_main = this.showMainFeatures( this.displayFeatures.destination_Information);
                    this.displayFeatures.documents_main = this.showMainFeatures( this.displayFeatures.documents);
                    this.displayFeatures.itinerary_main = this.showMainFeatures( this.displayFeatures.itinerary);
                    this.displayFeatures.transportation_main = this.showMainFeatures(this.displayFeatures.transportations);
          
                  }
    
              
               
            });
    
        }else if(TourListOffline.length != 0){

     
            {
                // The Local storage value is set in welcome page while coming to home page
                this.displayFeatures = JSON.parse(localStorage.getItem("display_feature"));
        
                this.getCustomIconData(this.displayFeatures) ;//BOC ERICA (02)
              
              
                if (localStorage.getItem("user_role") != "Travellers" && 
                localStorage.getItem("user_role") != "Admin" &&
                 localStorage.getItem("user_role") != "Guides") {
                  this.displayFeatures.itinerary.survey = false;
              
                }
                this.displayFeatures.contact_main = this.showMainFeatures( this.displayFeatures.contacts);
                this.displayFeatures.destination_main = this.showMainFeatures( this.displayFeatures.destination_Information);
                this.displayFeatures.documents_main = this.showMainFeatures( this.displayFeatures.documents);
                this.displayFeatures.itinerary_main = this.showMainFeatures( this.displayFeatures.itinerary);
                this.displayFeatures.transportation_main = this.showMainFeatures( this.displayFeatures.transportations);
              
              }
        }
        // EOC Erica (01)

       
        

     
        this.userinfo = JSON.parse(localStorage.getItem("UserData"))
        this.traveller = this.userinfo.user.fullname
    

        var companyinfo = JSON.parse(localStorage.getItem("companyProvider"))
        

        
        this.translationsLabels = JSON.parse(localStorage.getItem("UserData")).user.translations
    

        this.renderdata()
        if (this.router.url == "/home" || this.router.url == "/home-desc-pdf")
        {
            this.pagename = "ABOUT TRIP"
        } else if(this.router.url == "/itinerary" 
        || this.router.url == "/daily-survey" 
        || this.router.url == "/single-itinerary-pdf")
           {

            
                this.pagename = "ITINERARY"
        }
        else if (this.router.url == "/hotel") {
            this.pagename = "ACCOMMODATION"
        }
        else if (this.router.url == "/guides") {
            this.pagename = "GUIDES"
        }
        else if (this.router.url == "/experiences") {
            this.pagename = "EXPERIENCES"
        }
        else if (this.router.url == "/meals") {
            this.pagename = "MEALS"
        }
        else if (this.router.url == "/transport") {
            this.pagename = "TRANSPORT"
        }
        else if (this.router.url == "/custom-menu" || this.router.url =='/sub-custom-menu') {
            var custom_menu:any = this.custommenuservice.getData();

       


            this.pagename = custom_menu.name

        }
        else if (this.router.url == "/extra") {
            this.pagename = "EXTRA"
        }
        
        else if (this.router.url == "/document" ||
         this.router.url == "/document-list" ||
         this.router.url == "/document-view"||
         this.router.url == "/show-all-traveller-doc") {
            this.pagename = "DOCUMENT"
        }
       

        else if (this.router.url == "/end-of-tour") {
            this.pagename = "END-OF-TOUR"
        }

        else if (this.router.url == "/contact" 
        || this.router.url == "/contact-us"
        || this.router.url == "/contact-emergency"
        || this.router.url == "/contact-useful") {
            this.pagename = "CONTACT"
        }
      



       
        


    }

    flag

    getCustomIconData(displayFeatures) {

    

        if(displayFeatures.custom_menu == true){
            this.getcustomMenuList()

        }

    
      }

      page     
getcustomMenuList() { // Added by Midhun custom menu

   


    if (this.displayFeatures.custom_menu == true) {

      this.companyFactoryService.getcustommenu((data) => {
      
        if (data != 'error') {
          if (data.custom_menus) {
            this.customIconSubMenus = data.custom_menus

            this.svgregistercustom_icon(this.customIconSubMenus);
            localStorage.setItem('customIconData', JSON.stringify(data.custom_menus));

          
            this.page=this.pageNameService.getData();
          
            

            //  this.appendCssStyles()
          }
        }

      });


    } 

  }

 



    logout() {
      
        //  //05: BOC HRUSHIKESH organization slide index removed while loggin out
        //  localStorage.removeItem('INDEX_WELCOME')
        //  //EOC HRUSHIKESH      
        this.authService.logout()
    }

    open(pagename)
    {
        this.pageNameService.setData("No Custom Menu")

      
        if (pagename == "ITINERARY") {

            if(this.IsSinglePDF =="false"){
                // 
                this.router.navigate(['itinerary'])
            }

            if(this.IsSinglePDF=="true"  && (localStorage.getItem("pdf_description_url")!="null" || localStorage.getItem("pdf_description_url")!=undefined )){
                this.router.navigate(['single-itinerary-pdf'])
                
            } 

        }
        else if (pagename == "ABOUT TRIP") {

            
        

            // if(this.IsHomeDescPDF){
            //     this.router.navigate(['home-desc-pdf'])
                
            // }
            // if(!this.IsHomeDescPDF){
            //     this.router.navigate(['home'])
            // }


            if(this.IsHomeDescPDF=="true"  &&  (localStorage.getItem("pdf_description_url")!="null" || localStorage.getItem("pdf_description_url")!=undefined )){
                this.router.navigate(['home-desc-pdf'])
                
            }else  if(this.IsHomeDescPDF=="false"){
                
                this.router.navigate(['home'])
            }
        }
        else if (pagename == "ACCOMMODATION") 
            {
            this.router.navigate(['hotel'])
        }
        else if (pagename == "GUIDES") {
            this.router.navigate(['guides'])
        }
        else if (pagename == "EXPERIENCES") {
            this.router.navigate(['experiences'])
        }
        else if (pagename == "MEALS") {
            this.router.navigate(['meals'])
        }
        else if (pagename == "TRANSPORT") {
            this.router.navigate(['transport'])
        }
        else if (pagename == "EXTRA") {
            this.router.navigate(['extra'])
        }
        else if (pagename == "DOCUMENT") {

           

            if(localStorage.getItem('user_role')=="Travellers" ||
            localStorage.getItem('user_role')=="Guides"){
                this.router.navigate(['document'])    
            }else if(localStorage.getItem('user_role')=="Admin" ||
             localStorage.getItem('user_role')=="Users"){

                this.router.navigate(['show-all-traveller-doc'])  
            }
          

        }
        else if (pagename == "END-OF-TOUR") {
            this.router.navigate(['end-of-tour'])
        }
        else if (pagename == "CONTACT") {
            this.router.navigate(['contact'])
        }


    }

    custom_menu_str
    open_cutom_menu(custom_menu,cust_meny_index)
    {

        // document.getElementById("svg").setAttribute("fill", "green");
      
      
//05: BOC HRUSHIKESH := Organization color applied if exist . Else company primary color applied
var index = (localStorage.getItem('INDEX_WELCOME'))

// custom_menu.svg={strokes:this.tourListProvider[index].brand.color}
// setTimeout(() => {
// if (this.tourListProvider[index].brand.length == undefined) {

//     $('#Header').text('app-header .bbb svg{fill:'+ this.tourListProvider[index].brand.color + ' !important; stroke-opacity:0 !important}'+
//   'app-header .bbb svg rect{fill:'+ this.tourListProvider[index].brand.color +' !important ; stroke-opacity:0 !important}'+
//     'app-header .bbb svg polygon{fill:'+ this.tourListProvider[index].brand.color+ ' !important ; stroke-opacity:0 !important}'+
//    'app-header .bbb svg path{fill:'+ this.tourListProvider[index].brand.color +' !important; stroke-opacity:0 !important}'+
//    'app-header .bbb svg path:nth-of-type(n){fill:'+ this.tourListProvider[index].brand.color +' !important; stroke-opacity:0 !important}'+
//    'app-header .bbb svg path:nth-of-type(1){fill:'+ this.tourListProvider[index].brand.color +' !important; stroke-opacity:0 !important}'+
//    'app-header .bbb svg path:nth-of-type(2){fill:'+ this.tourListProvider[index].brand.color +' !important; stroke-opacity:0 !important}'+
//    'app-header .bbb svg path:nth-of-type(3){fill:'+ this.tourListProvider[index].brand.color +' !important; stroke-opacity:0 !important}'+

  
  
//    'app-header .ccc svg{fill:white !important; stroke-opacity:0 !important}'+
//    'app-header .ccc svg rect{fill:white !important; stroke-opacity:0 !important}'+
//    'app-header .ccc svg polygon:nth-of-type(n){fill:white !important; stroke-opacity:0 !important}'+
//    'app-header .ccc svg polygon:nth-of-type(1){fill:white !important; stroke-opacity:0 !important}'+
//    'app-header .ccc svg path:nth-of-type(1){fill:white !important; stroke-opacity:0 !important}'+
//    'app-header .ccc svg path:nth-of-type(2){fill:white !important; stroke-opacity:0 !important}'+
//    'app-header .ccc svg path:nth-of-type(3){fill:white !important; stroke-opacity:0 !important}'+
//    'app-header .ccc svg path:nth-of-type(n){fill:white !important; stroke-opacity:0 !important}'+
//      'app-header .ccc svg polygon{fill:white !important; stroke-opacity:0 !important}'+
//     'app-header .ccc svg path{fill:white !important; stroke-opacity:0 !important}'
     


//     );
// } else {

//     // $('#Header').text('app-header .bbb svg{fill:' + JSON.parse(localStorage.getItem("primaryColor")) + ' !important;}'


//     // );
// }
// }, 100);
//EOC HRUSHIKESH





            // this.router.navigate(['itinerary'])

            this.pageNameService.setData(custom_menu.display_name)

            this.router.navigateByUrl('/home', { skipLocationChange: false }).then(() => {
                this.router.navigate(['/custom-menu']);
            }); 

           

            // this.router.navigate(['/custom-menu']);
        
    //    this.setcustommenu(custom_menu)

       this.custommenuservice.setData(custom_menu)

    }

    



    back() {

        // old
        //05: BOC HRUSHIKESH organization slide index removed while loggin out
        //localStorage.removeItem('INDEX_WELCOME')
        //EOC HRUSHIKESH
    
        // if (this.pagename == "ABOUT TRIP")
        // {
        //     this.router.navigate(['welcome'])

        //     //clear local storage here

        // } else
        // {
        //     this.location.back()
        // }

        //new always welcome
        this.router.navigate(['welcome'])
 
    }





    renderdata() {


        this.itinerarydata = JSON.parse(localStorage.getItem("LSDitinerarydata")).itinerary



        var IDate

        var StartDate = this.itinerarydata.dateStart;


        var EndDate = this.itinerarydata.dateEnd;

        let monthS = this.itinerarydata.iti_start_month;
        let monthE = this.itinerarydata.iti_end_month;

        let YearS = this.itinerarydata.iti_start_year;
        let YearE = this.itinerarydata.iti_end_year;

        var dStart
        var dEnd

        if (monthS == monthE) {
            dStart = moment(StartDate).format("DD");
            dEnd = moment(EndDate).format("DD MMMM YYYY");
            let monthName = dEnd.split(' ')[1];
            dEnd = dEnd.replace(monthName, this.translationsLabels[monthName.toLowerCase()]);

            IDate = dStart + "-" + dEnd
          

        } else {
            dStart = moment(StartDate).format("DD MMMM");
            let monthNameStart = dStart.split(' ')[1];
            dStart = dStart.replace(monthNameStart, this.translationsLabels[monthNameStart.toLowerCase()]);

            dEnd = moment(EndDate).format("DD MMMM YYYY");
            let monthName = dEnd.split(' ')[1];
            dEnd = dEnd.replace(monthName, this.translationsLabels[monthName.toLowerCase()]);

            IDate = dStart + "-" + dEnd

        }


        if (YearS != YearE) {
            dStart = moment(StartDate).format("DD MMMM YYYY");
            let monthNameStart = dStart.split(' ')[1];
            dStart = dStart.replace(monthNameStart, this.translationsLabels[monthNameStart.toLowerCase()]);

            dEnd = moment(EndDate).format("DD MMMM YYYY");
            let monthName = dEnd.split(' ')[1];
            dEnd = dEnd.replace(monthName, this.translationsLabels[monthName.toLowerCase()]);

            IDate = dStart + "-" + dEnd
        }




        this.itinerarydata.formatDate = IDate;
     

    }




    svgregister() {

        this.matIconRegistry.addSvgIcon(
            "itinerarylist",
            this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/Images/svg/itinerary-list.svg")
        );


        this.matIconRegistry.addSvgIcon(
            "abouttrip",
            this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/Images/svg/abouttrip.svg")
        );




        this.matIconRegistry.addSvgIcon(
            "itinerary",
            this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/Images/svg/itinerary.svg")
        );


        this.matIconRegistry.addSvgIcon(
            "accommodation",
            this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/Images/svg/accomodation.svg")
        );
  

        this.matIconRegistry.addSvgIcon(
            "guide",
            this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/Images/svg/guides.svg")
        );


        this.matIconRegistry.addSvgIcon(
            "experinces",
            this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/Images/svg/experiences.svg")
        );


        this.matIconRegistry.addSvgIcon(
            "transport",
            this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/Images/svg/transportation.svg")
        );
      

        this.matIconRegistry.addSvgIcon(
            "contact",
            this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/Images/svg/contacts.svg")
        );


        this.matIconRegistry.addSvgIcon(
            "doccument",
            this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/Images/svg/documents.svg")
        );


        this.matIconRegistry.addSvgIcon(
            "extras",
            this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/Images/svg/extras.svg")
        );

        this.matIconRegistry.addSvgIcon(
            "meals",
            this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/Images/svg/meals.svg")
        );
//04:BOC HRUSHIKESH  :=New icons added for guides change
        this.matIconRegistry.addSvgIcon(
            "aboutme",
            this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/Images/svg/contactUs.svg")
        );

 this.matIconRegistry.addSvgIcon(
            "location",
            this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/Images/svg/location_1.svg")
        );


        this.matIconRegistry.addSvgIcon(
            "languages",
            this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/Images/svg/languages.svg")
        );


        this.matIconRegistry.addSvgIcon(
            "experincesG",
            this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/Images/svg/experiences_guides.svg")
        );
        //EOC HRUSHIKESH


        this.matIconRegistry.addSvgIcon(
            "tourSurvey",
            this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/Images/svg/toursurvey.svg")
        );

        this.matIconRegistry.addSvgIcon("menu",this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/Images/mixed/menu.svg"));
        
        this.matIconRegistry.addSvgIcon("menu_filled",this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/Images/mixed/menu_filled.svg"));
        
        this.matIconRegistry.addSvgIcon("back", this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/Images/mixed/back.svg"))
        
        this.matIconRegistry.addSvgIcon("up_arrow", this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/Images/mixed/up_arrow.svg"))

        this.matIconRegistry.addSvgIcon("close", this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/Images/mixed/close.svg"))

    }


    Changelanguage() {
        this.menu_click_disable()
      
        //this.dialog.open(DialogOverviewExampleDialog, {
        //    width: '250px',
        //    data: { name: this.name, animal: this.animal }
        //});



        const dialogRef = this.matDialog.open(ChangeLanguageComponent, {
            data: { message: "Empty" }
        });

        dialogRef.afterClosed().subscribe(result => {
           
        });
    }

    // BOC Erica (01)
    showMainFeatures( mainFeatures){
        var subFeatureExists = false;
    
    
        for(let key in mainFeatures){
    
          var value = mainFeatures[key]
          if (subFeatureExists == false) {
            if (value == true) {
              subFeatureExists = true;
            } else {
              subFeatureExists = false;
            }
          }
        }
    
        return subFeatureExists;
      }
      // BOC Erica (01)

      svgregistercustom_icon( customIconSubMenus) {

        customIconSubMenus.forEach(element => {
            this.matIconRegistry.addSvgIcon(
                element.name,
                this.domSanitizer.bypassSecurityTrustResourceUrl(element.icon_url)
            );
        });

       
        
        

    }

    scroll_up(){

  

    
        $(window).scrollTop( $("body").offset().top );
    }

    check_single_itinerary_pdf(){

     
        if (localStorage.getItem('IsSinglePDF') == 'true') {

            this.IsSinglePDF = "true"
    
        } 
          if (localStorage.getItem('IsSinglePDF') == 'false')  {
            this.IsSinglePDF = "false"
        }
       
    }

    check_home_desc_pdf(){
        
      
        if (localStorage.getItem('IsHomeDescPDF') == 'true') {

            this.IsHomeDescPDF = "true"
    
        } 
         if (localStorage.getItem('IsHomeDescPDF') == 'false'){
            this.IsHomeDescPDF = "false"
        }

    }

    option_menu_active=false

    menu_click(){

   
        this.option_menu_active = true

    }

    menu_click_disable(){

  
        this.option_menu_active = false

    }

}
