import { EndOfTourService } from './../services/end-of-tour.service';
import { DailySurveyService } from './../services/daily-survey.service';
import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';
import { Location } from '@angular/common';


@Component({
  selector: 'app-daily-survey',
  templateUrl: './daily-survey.component.html',
  styleUrls: ['./daily-survey.component.css']
})
export class DailySurveyComponent implements OnInit {

  survey 
  id
  day
  translationsLabels

  tourListProvider
  header_img

  constructor(private dailySurveyService :DailySurveyService , 
    private endOfTourService: EndOfTourService,
    private location: Location,) {

      this.header_img = JSON.parse(localStorage.getItem("LSDitinerarydata")).itinerary.imgSrc

 

    this.tourListProvider = JSON.parse(localStorage.getItem("tourListProvider"))

    console.log("color xxxx",this.tourListProvider)



        var index = (localStorage.getItem('INDEX_WELCOME'))

        console.log("color xxxx",index)

    setTimeout(()=>{

      console.log("color xxxx",this.tourListProvider[index].brand)
     
      
     if(this.tourListProvider[index].brand.color == undefined){
      $('#daily_survey').text(
       
       
        'app-daily-survey  .mat-slider-horizontal .mat-slider-track-fill {background: '+JSON.parse(localStorage.getItem("primaryColor"))+' !important;}'
        +'app-daily-survey  .mat-slider:not(.mat-slider-disabled) .mat-slider-thumb, .mat-slider:not(.mat-slider-disabled) .mat-slider-thumb-label {background: '+JSON.parse(localStorage.getItem("primaryColor"))+' !important;}'
        +'app-daily-survey  .mat-slider-thumb-label {background: '+JSON.parse(localStorage.getItem("primaryColor"))+' !important;}'
        +'app-daily-survey .submit_button{background :'+JSON.parse(localStorage.getItem("primaryColor"))+' !important;}'
       +'app-daily-survey  .mat-slider-track-wrapper { background:'+ JSON.parse(localStorage.getItem("primaryColor"))+'!important;}'
        );
       // (01): EOC: Erica Replace #build-wrap with in the selector in jquery page-end-of-tour #build-wrap



     }else if(this.tourListProvider[index].brand.color != undefined){

      $('#daily_survey').text(
       
       
        'app-daily-survey  .mat-slider-horizontal .mat-slider-track-fill {background: '+this.tourListProvider[index].brand.color+' !important;}'
        +'app-daily-survey  .mat-slider:not(.mat-slider-disabled) .mat-slider-thumb, .mat-slider:not(.mat-slider-disabled) .mat-slider-thumb-label {background: '+this.tourListProvider[index].brand.color+' !important;}'
        +'app-daily-survey  .mat-slider-thumb-label {background: '+this.tourListProvider[index].brand.color+' !important;}'
        +'app-daily-survey .submit_button{background :'+this.tourListProvider[index].brand.color+' !important;}'
        +'app-daily-survey  .mat-slider-track-wrapper { background:'+ this.tourListProvider[index].brand.color+'!important;}'

        );
       // (01): EOC: Erica Replace #build-wrap with in the selector in jquery page-end-of-tour #build-wrap



     }
     
    






},500);


    
    this.translationsLabels = JSON.parse(localStorage.getItem("UserData")).user.translations

    this.id=this.dailySurveyService.getDataid();
    this.survey=this.dailySurveyService.getDatasurvey();
    this.day=this.dailySurveyService.getDataDay();

    if(this.id==null || this.id ==undefined || 
      this.survey==null || this.survey ==undefined ||
      this.day==null || this.day ==undefined ){

        this.location.back();

      }

    
 

   if(this.survey!=undefined){

    this.isSurveyValid=true

    if(this.survey.length==0){
      this.isSurveyValid=false
    }

    for (let j = 0; j < this.survey.length; j++) {
      if (this.survey[j].point == null) {
        this.survey[j].point = 10
      }
    }

   }else{

    this.isSurveyValid=false

   }
   

    
   setTimeout(()=>{this.check_height()},100)
   setTimeout(()=>{this.check_height()},1000) 
  

  }

  check_height(){

   
    if($(window).height()>= $(document).height()){
      this.show_scroll_up_btn=false;
    }else if($(window).height()< $(document).height()){
      this.show_scroll_up_btn=true;
    }

   
  }

  show_scroll_up_btn=true;

  scroll_up(){

      $(window).scrollTop( $("body").offset().top );
    }

    isSurveyValid

  ngOnInit() {
   


  }

  
  buttonsList
  updateSetting(event, index, id) {



  

    this.buttonsList = document.getElementsByClassName("mat-slider-daily");

    // for(let currentButton of this.buttonsList) {
    //   //currentButton.classList.remove("cdk-mouse-focused");
    //    currentButton.classList.add("cdk-focused");
    //   // currentButton.classList.remove("cdk-program-focused");
    // }
    this.survey[index].point = event.value;

    //this.onSideNavOpened(id)
  }

  onSideNavOpened(id) {

    setTimeout(function(){
      this.buttonsList = document.getElementsByClassName("mat-slider-daily");

      for(let currentButton of this.buttonsList) {
        //currentButton.classList.remove("cdk-mouse-focused");
         currentButton.classList.remove("cdk-focused");
         
         
        // currentButton.classList.remove("cdk-program-focused");
      }


      for(let currentButton of this.buttonsList) {
        // currentButton.classList.add("cdk-focused");
        // currentButton.classList.add("cdk-program-focused");
      }
    
    }, 500);
   
    
  }

  submit() {
    var itinerary_id = localStorage.getItem('LStourListId');
    var itemServey = [];
    if (this.survey.length != 0) {
      for (var i = 0; i < this.survey.length; i++) {
        itemServey.push({
          'itinerary_id': itinerary_id,
          'survey_id': this.survey[i].id,
          'rating': parseInt(this.survey[i].point),
          'day_trip_id': this.id
        });
      }
      console.log('Iti survey Array : ', itemServey)
      this.endOfTourService.SurveyFactoryPut(itemServey)
    }
  }

  close_page(){
    this.location.back();
  }

  trigger(){
    this.check_height();
}


}
