﻿import { PassSelectedItemService } from './../services/pass-selected-item.service';
//01: HRUSHIKESH 28-May-2020 Organization color change
import { Component, OnInit,ChangeDetectorRef } from '@angular/core';
import { HotelService } from '../services/hotel.service'
import * as $ from 'jquery';
import { MatDialog } from '@angular/material/dialog';
import { AlertDailogComponent } from '../alert-dailog/alert-dailog.component';
import { TineriTipComponent } from './../tineri-tip/tineri-tip.component';
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";

@Component({
  selector: 'app-hotel',
  templateUrl: './hotel.component.html',
  styleUrls: ['./hotel.component.css']
})
export class HotelComponent implements OnInit {

    tourlistid
    itinerarydata
    formatDate
    translationsLabels
    traveller
    logo
    hoteldata
    leftPos = 0
    tourListProvider
    showMyClass :any[]=[];
    readMore :any[]=[];


    hotel
    header_img

    constructor(public hotelService: HotelService, private matDialog: MatDialog,
        private cdr:ChangeDetectorRef, //addedby sneha
        private matIconRegistry: MatIconRegistry,
        private domSanitizer: DomSanitizer,
        private pass_data_service: PassSelectedItemService
        ) {

          this.header_img = JSON.parse(localStorage.getItem("LSDitinerarydata")).itinerary.imgSrc


          this.hotel = this.pass_data_service.getData();
          this.pass_data_service.setData(undefined)

          if(this.hotel!=undefined){
         

            setTimeout(()=>{
               var x = $("#"+this.hotel.id).position();
      
  

             window.scrollTo(x.left, x.top+50)

  
          },1000);
          }



            this.svgregister();
        console.log("in WElcome USer", JSON.parse(localStorage.getItem("UserData")))
        console.log("in WElcome Company", JSON.parse(localStorage.getItem("companyProvider")))
        var userinfo = JSON.parse(localStorage.getItem("UserData"))
        var companyinfo = JSON.parse(localStorage.getItem("companyProvider"))
        this.translationsLabels = "";
        this.logo = companyinfo.logo
        this.traveller = userinfo.user.fullname

        this.translationsLabels = JSON.parse(localStorage.getItem("UserData")).user.translations
        console.log("this.translationsLabels2", this.translationsLabels)



        //console.log("this.LSDtipsList", JSON.parse(window.localStorage.getItem('LSDtipsList')))
        //console.log("this.LSDItinerarytipsList", JSON.parse(window.localStorage.getItem('LSDItinerarytipsList')))
        //console.log("this.LSDGuidestipsList", JSON.parse(window.localStorage.getItem('LSDGuidestipsList')))

        console.log("this.LSDHotelstipsList", JSON.parse(window.localStorage.getItem('LSDHotelstipsList')))

        //console.log("this.LSDExperiencetipsList", JSON.parse(window.localStorage.getItem('LSDExperiencetipsList')))
        //console.log("this.LSDMealstipsList", JSON.parse(window.localStorage.getItem('LSDMealstipsList')))
        this.tourListProvider = JSON.parse(localStorage.getItem("tourListProvider"))

        this.renderdata()

        var elementname=localStorage.getItem("Pname")
        console.log("elementname",elementname)
        var scrollINDEX
      
        // var t=9
        // console.log("tar",t)
        

        if(this.hoteldata !=undefined){
          for(var i=0;i<this.hoteldata.length;i++){
            if(this.hoteldata[i].name==elementname)
             {  var j=i+1;
             
                 if(this.hoteldata[i].description){
                     console.log("Break 1")
                     scrollINDEX=400*j
                 }
                 else if(this.hoteldata[i].pdf_description){
                     console.log("Break 2")
                     scrollINDEX=600*j
                 }
           
           
         }
     } 
        }
        
        
      


        if(localStorage.getItem('Pname')!=undefined && localStorage.getItem('Pname')!=null)
         {
             
            console.log("scrollINDEX2 ::",scrollINDEX)
            setTimeout(() => {
            window.scrollTo(0,scrollINDEX)
            localStorage.removeItem('Pname')
             },300);
            // document.querySelector('#target').scrollIntoView({ behavior: 'smooth', block: 'center' });
        
            // var t=localStorage.getItem('ITEM_INDEX')
            // const elementList = document.querySelectorAll(this.hoteldata[t]);
            // const element = elementList[0] as HTMLElement;
            // element.scrollIntoView({ behavior: 'smooth' });
            // localStorage.removeItem('ITEM_INDEX')
         }

         this.modify_content_description();
         if(this.hoteldata!=undefined){
          this.sethidedata(this.hoteldata.length)
         }
       

         setTimeout(()=>{this.check_height()},100) 

         
        $(".fontnormal a").each(function (index, el) {

          $(this).click(function (event) {

            if ($(this).attr('href') == "#") {
              
              event.preventDefault();
            }
          })
        });

        }
    
       

          check_height(){

           
              if($(window).height()>= $(".mainContainer").height()+250){
                this.show_scroll_up_btn=false;
              }else if($(window).height()< $(".mainContainer").height()+250){
                this.show_scroll_up_btn=true;
              }
        
           
            }

          scroll_up(){
  
            $(window).scrollTop( $("body").offset().top );
        }
      
          show_scroll_up_btn=true;

  ngOnInit() {
      //01: BOC HRUSHIKESH := Organization color applied if exist . Else company primary color applied
     var index = (localStorage.getItem('INDEX_WELCOME'))
     console.log("Slide index from welcome", index)
 
     if (this.tourListProvider[index].brand.length == undefined) {
         console.log("TEST IS ON", this.tourListProvider[index].brand.color)
         $('#extraCSS').text(
             'a {color:' + this.tourListProvider[index].brand.color + ' !important;}'
 
         );
     } else {
         console.log("Entry else", JSON.parse(localStorage.getItem("primaryColor")))
         $('#extraCSS').text(
             'a {color:' + JSON.parse(localStorage.getItem("primaryColor")) + ' !important;}'
         );
     }
     
     //EOC HRUSHIKESH
  }
  svgregister() {

   


    this.matIconRegistry.addSvgIcon(
        "phone",
        this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/Images/mixed/phone.svg")
    );
  }
  renderdata() {

      //this.hotelService.all(data => {
      //    console.log("hotelService List", data);
      //    console.log(JSON.parse(localStorage.getItem("LSDHotellistdata")));


      if(localStorage.getItem("LSDHotellistdata")!=undefined){

        this.itinerarydata = JSON.parse(localStorage.getItem("LSDitinerarydata")).itinerary
        this.hoteldata = JSON.parse(localStorage.getItem("LSDHotellistdata"))
        console.log("i am in",this.hoteldata)
        var day_trip_length = this.itinerarydata.dayTrips.length
        if (this.hoteldata.length != 0) {
        
            for (let a = 0; a < this.hoteldata.length; a++) {

            

  

                  // this.hoteldata[a].description=this.hoteldata[a].description.replace(/<a/g, "<a  target='_blank' ");
              

                  this.hoteldata[a].description =  this.hoteldata[a].description.replace(/<a href/g, '<u><a href');
                  this.hoteldata[a].description =   this.hoteldata[a].description.replace(/<a href="#"/g, '</u><a href="#" ');
                  this.hoteldata[a].description =   this.hoteldata[a].description.replace(/<\/a>/g, '</a></u>');
                  this.hoteldata[a].description =   this.hoteldata[a].description.replace(/<a href/g, '<a target="_blank" href');
  


                  if(this.hoteldata[a].pdf_description!= undefined){
                      this.hoteldata[a].pdf_description_iframe = this.domSanitizer.bypassSecurityTrustResourceUrl( this.hoteldata[a].pdf_description);
              
                  }


                this.hoteldata[a].localImageArray = []
                this.hoteldata[a].HotelContent = this.getHotelContent(this.hoteldata[a])
                this.hoteldata[a].days = '';
                if (this.hoteldata.imageArray != 0) {
                    for (let b = 0; b < this.hoteldata[a].imageArray.length; b++) {

                        console.log("this.hoteldata[a].imageArray", a, "::", b)

                        this.hoteldata[a].localImageArray.push(this.hoteldata[a].imageArray[b])
                    }

                    for (let c = 0; c < this.hoteldata[a].days_applicable.length; c++) {


                        this.hoteldata[a].days = this.hoteldata[a].days + this.hoteldata[a].days_applicable[c]
                    }
                }

                if (a <= this.hoteldata.length) {
                    this.hoteldata[a].localImageArray.unshift(this.hoteldata[a].image)
                    console.log("this.hoteldata", this.hoteldata)
                }


           


            }


            }



      }

      

          }

      //});







  getHotelContent(data) {

      var content = data.description;
      console.log("in getHotelContent", content)
      if (content != null) {
          content = content.replace(/<a href/g, '<u><a href');
          content = content.replace(/<a href="#"/g, '<b></u><a href="#" ');
          content = content.replace(/<\/a>/g, '</a></u></b>');
          content = content.replace(/<ol/g, '<ol class="ordered_list"');
          content = content.replace(/<ul/g, '<ul class="unordered_list"');
          content = content.replace(/<em/g, '<em class="make_italic"')
      }
      
      //  var LSDtipsListoffline = JSON.parse(window.localStorage.getItem('LSDHotelstipsList'))
      var HotelDescription = content;
    
      var popupShown = false;
      let scope = this
      var isOnline = false
    
      setTimeout(function() {
          $("app-hotel .content_a a").each(function(index, el) {
              
              //  $(this).css('color', companyFactoryInfo.color);
             // $(this).css('color', localStorage.getItem("primaryColor"));
              $(this).css('text-decoration', 'none');
              $(this).click(function(event) {

                  console.log("TIP 1")

                  if ($(this).attr('href') == "#") {
                      event.preventDefault();
                      if (!popupShown) {
                          popupShown = true;

                          {
                      
                          }
                          console.log("TIP 2")
                 
                          {
                              var hashTagId = $(this).data("compare")
                              var display_name = $(this).text()

                          }
              

                          // scope.tineritipAnalytics(hashTagId);

                       
                       
                          
                              var LSDtipsListoffline = JSON.parse(window.localStorage.getItem('LSDHotelstipsList'))
                              var tipsdaytrip = LSDtipsListoffline.hotels_hash_tag;
                              tipsdaytrip.forEach(tipValue => {
                                  var tipname = tipValue.name
                                  if (tipname == hashTagId) {
                                      var content = tipValue.content;

                                      if (content != null) {
                                          content = content.replace(/<a/g, '<a class="tineri_tip_a"');
                                          content = content.replace(/<\/a>/g, '</a>');

                                          content = content.replace(/<ol/g, '<ol class="ordered_list"');
                                          content = content.replace(/<ul/g, '<ul class="unordered_list"');

                                          content = content.replace(/<em/g, '<em class="make_italic"')
                                      }

                           
                                      const dialogRef = this.matDialog.open(AlertDailogComponent, {
                                          data: { message: content }
                                      });

                                      dialogRef.afterClosed().subscribe(result => {
                                          console.log(`Dialog result: ${result}`);
                                      });
                                      //BOC ERICA (318)
                                      //let profileModal = modalPopup.create('TineriTipsPage',
                                      //    {
                                      //        valueData: tipValue,
                                      //        htmlContent: content,
                                      //        title: display_name
                                      //    }, { cssClass: 'settings-modal' });
                                      ////EOC ERICA (318)

                                      //profileModal.onDidDismiss(() => {
                                      //    popupShown = false;
                                      //    scope.sign.registerBackButton(scope.platform, scope.navCtrl, scope.alertCtrl)
                                      //});
                                      //profileModal.present();
                                  }
                              });
                          

                      } else {
                          popupShown = false;
                      }
                  } 

              })
          });
        
      }, 100);

      return HotelDescription

  }













  
  scrollL() {

      console.log("i am in scroll L", this.leftPos)

      this.leftPos = $('.inner-container').scrollLeft();
      //this.leftPos = 0
      $(".inner-container").animate({
          scrollLeft: this.leftPos + 332
      }, 500);
  }

  scrollR() {

      console.log("i am in scroll R", this.leftPos)

      this.leftPos = $('.inner-container').scrollLeft();
      //this.leftPos = 0
      $(".inner-container").animate({
          scrollLeft: this.leftPos - 332
      }, 500);
  }
  
  ngAfterViewInit() {
    

    if(this.hoteldata!=undefined){
      this.hoteldata.forEach((data, index)=> {
        //198 is the height declared of the block
        if(data.description && data.pdf_description == undefined){
             var desc_height=307-document.getElementById("hotel_details"+index).offsetHeight
             var desc= document.getElementById("description"+index).offsetHeight
             if(desc<desc_height){
                this.readMore[index]=false
              }else{
                if (document.getElementById("day_numbers"+index).offsetWidth<500){
                  $("#description_box"+index).css("height","300px")
                }
                else{
                $("#description_box"+index).css("height",desc_height+"px")
                }
              }
            // var perimeter=2*(document.getElementById("description"+index).offsetHeight +document.getElementById("description"+index).offsetWidth)   
            // if(perimeter<1480){
            // this.readMore[index]=false
            // }
        }
    } );
    }
  

    this.cdr.detectChanges()
  }
  sethidedata(len){
    
    for (var i = 0; i < len; i++) {
        this.showMyClass.push(true);
        this.readMore.push(true);

      }
  }
  showMore(i) {
    this.showMyClass[i] = !this.showMyClass[i];
    if (this.showMyClass[i]==false){
      this.hoteldata[i].description_height=document.getElementById("description_box"+i).offsetHeight
      $("#description_box"+i).css("height","auto")
    }else{
      
      var desc_height=this.hoteldata[i].description_height
      $("#description_box"+i).css("height",desc_height+"px")
      
    }
  } 
  
  modify_content_description(){


    setTimeout(() => {

         

        $(".fontnormal a").each(function (index, el) {

        

        
          $(this).css('color',  localStorage.getItem("primaryColor"));
  
          $(this).css('text-decoration', 'none');
          $(this).click(function (event) {

           


  
  
            if ($(this).attr('href') == "#") {
                      
              
              event.preventDefault();
  
            //   if (!popupShown)
               {
  
               //Before Tineri Tip CR
                {
                  // var hashTagId = $(this).text().replace('#', '')
                }
  
                //After Tineri Tip CR
                {
                  //BOC BUG FIX #
                // var hashTagId = $(this).text().replace('#', '')
  
                 var hashTagId = $(this).data("compare")

                
                var display_name = $(this).text()

  
                //BOC BUG FIX # end
                }
  
              
                // if (isOnline == true) 
                // {
                 
                //   tipsFactory.getHasTag(display_name, (response) => {

                
  
                //     if (response != "error") {
                //       var content = response.hash_tag.content;
                //       if (content != null) {
                //         content = content.replace(/<a/g, '<b><u><a ');
                //         content = content.replace(/<\/a>/g, '</a></u></b>');
                //         content = content.replace(/<ol/g, '<ol class="ordered_list"');
                //         content = content.replace(/<ul/g, '<ul class="unordered_list"');
                //         content = content.replace(/<em/g, '<em class="make_italic"')
                //       }
  
                //       //Before Tineri Tip CR
                //       {
                //         // let profileModal = modalPopup.create('TineriTipsPage', {
                //         //   valueData: response.hash_tag,
                //         //   htmlContent: content
                //         // });
                //       }
  
                //       //After Tineri Tip CR
  
                //         // let profileModal = modalPopup.create('TineriTipsPage', {
                //         //   valueData: response.hash_tag,
                //         //   htmlContent: content,
                //         //   title: display_name
                //         // });
                //       //end
  
  
                //       // profileModal.onDidDismiss(() => {
                //       //   popupShown = false;
                //       // });
                //       // profileModal.present();
  
                //     } else {
                //       // popupShown = false;
                //     }
                //   })
  
                // }
                
                //else 
                {


  
                  //---------------When offline show tineri tips from localstorage---------------------------
                  var LSDtipsListoffline = JSON.parse(window.localStorage.getItem('LSDHotelstipsList'))
  

  

                  var tipsdaytrip = LSDtipsListoffline;

               

                  
                  tipsdaytrip.forEach(tipValue => {
  
                    var tipname = tipValue.name
                    var tipidcms = tipValue.id
  
                    if (tipname == hashTagId) {
                      // var content = tipValue.content;


                      let dialogRef = matdialogscope.open(TineriTipComponent, {
                        data: {
                            tineriTip: tipValue,
                            id:hashTagId,
                            display_name:display_name
                        }
                    });


  
                      // if (content != null) {
  
                      //   content = content.replace(/<a/g, '<a class="tineri_tip_a"');
                      //   content = content.replace(/<\/a>/g, '</a>');
  
                      //   content = content.replace(/<ol/g, '<ol class="ordered_list"');
                      //   content = content.replace(/<ul/g, '<ul class="unordered_list"');
  
                      //   content = content.replace(/<em/g, '<em class="make_italic"')
                      // }
  
                      // spinner.dismiss();
  
                      {
                        // let profileModal = modalPopup.create('TineriTipsPage', {
                        //   valueData: tipValue,
                        //   htmlContent: content
                        // }, {
                        //   cssClass: 'settings-modal'
                        // });
                      }
  
                      // let profileModal = modalPopup.create('TineriTipsPage', {
                      //   valueData: tipValue,
                      //   htmlContent: content,
                      //   title: display_name
                      // }, {
                      //   cssClass: 'settings-modal'
                      // });
  
  
  
                      // profileModal.onDidDismiss(() => {
  
                      //   popupShown = false;
                      //   scope.sign.registerBackButton(scope.platform, scope.navCtrl, scope.alertCtrl)
  
                      // });


                      // profileModal.present();
                    }
                  });
  
  
                  //---------------When offline show tineri tips from localstorage CLOSE---------------------------
                }
  
              }
              
            }
  
          })
        });
  
      }, 100);



    
    
    var matdialogscope =this.matDialog;
    
    
    // {
    
   
    
    
    
    // setTimeout(() => {
    
   
    
    // $(".collapse a").each(function (index, el) {
  
    
    // $(this).css('color', localStorage.getItem("primaryColor"));
    
    // $(this).css('text-decoration', 'none');
    // $(this).click(function (event) {
    
   
    
    
    
    
    // if ($(this).attr('href') == "#") {
    
    
    
    // event.preventDefault();
    
    // // if (!popupShown)
    // {
    
    // //Before Tineri Tip CR
    // {
    // // var hashTagId = $(this).text().replace('#', '')
    // }
    
    // //After Tineri Tip CR
    // {
    // //BOC BUG FIX #
    // // var hashTagId = $(this).text().replace('#', '')
    
    // var hashTagId = $(this).data("compare")
    
    
    // var display_name = $(this).text()
    
    
    
    // //BOC BUG FIX # end
    // }
    
    
    // // if (isOnline == true)
    // // {
    
    // // tipsFactory.getHasTag(display_name, (response) => {
    
    
    
    // // if (response != "error") {
    // // var content = response.hash_tag.content;
    // // if (content != null) {
    // // content = content.replace(/<a/g, '<b><u><a ');
    // // content = content.replace(/<\/a>/g, '</a></u></b>');
    // // content = content.replace(/<ol/g, '<ol class="ordered_list"');
    // // content = content.replace(/<ul/g, '<ul class="unordered_list"');
    // // content = content.replace(/<em/g, '<em class="make_italic"')
    // // }
    
    // // //Before Tineri Tip CR
    // // {
    // // // let profileModal = modalPopup.create('TineriTipsPage', {
    // // // valueData: response.hash_tag,
    // // // htmlContent: content
    // // // });
    // // }
    
    // // //After Tineri Tip CR
    
    // // // let profileModal = modalPopup.create('TineriTipsPage', {
    // // // valueData: response.hash_tag,
    // // // htmlContent: content,
    // // // title: display_name
    // // // });
    // // //end
    
    
    // // // profileModal.onDidDismiss(() => {
    // // // popupShown = false;
    // // // });
    // // // profileModal.present();
    
    // // } else {
    // // // popupShown = false;
    // // }
    // // })
    
    // // }
    
    // //else
    // {
    
    
    
    // //---------------When offline show tineri tips from localstorage---------------------------
    // var LSDtipsListoffline = JSON.parse(window.localStorage.getItem('LSDHotelstipsList'))
    // // var LSDtipsListoffline = JSON.parse(window.localStorage.getItem('LSDGuidestipsList'))
    // // var LSDtipsListoffline = JSON.parse(window.localStorage.getItem('LSDExperiencetipsList'))
    // // var LSDtipsListoffline = JSON.parse(window.localStorage.getItem('LSDtipsList'))
    // // var LSDtipsListoffline = JSON.parse(window.localStorage.getItem('LSDItinerarytipsList'))
    
    
    
    
    // var tipsdaytrip = LSDtipsListoffline;
    
   
    
    // tipsdaytrip.forEach(tipValue => {
    
    // var tipname = tipValue.name
    // var tipidcms = tipValue.id
    
    // if (tipname == hashTagId) {
    // // var content = tipValue.content;
    
    
    // let dialogRef = matdialogscope.open(TineriTipComponent, {
    // data: {
    // tineriTip: tipValue,
    // id:hashTagId,
    // display_name:display_name
    // }
    // });
    
    
    
    // // if (content != null) {
    
    // // content = content.replace(/<a/g, '<a class="tineri_tip_a"');
    // // content = content.replace(/<\/a>/g, '</a>');
    
    // // content = content.replace(/<ol/g, '<ol class="ordered_list"');
    // // content = content.replace(/<ul/g, '<ul class="unordered_list"');
    
    // // content = content.replace(/<em/g, '<em class="make_italic"')
    // // }
    
    // // spinner.dismiss();
    
    // {
    // // let profileModal = modalPopup.create('TineriTipsPage', {
    // // valueData: tipValue,
    // // htmlContent: content
    // // }, {
    // // cssClass: 'settings-modal'
    // // });
    // }
    
    // // let profileModal = modalPopup.create('TineriTipsPage', {
    // // valueData: tipValue,
    // // htmlContent: content,
    // // title: display_name
    // // }, {
    // // cssClass: 'settings-modal'
    // // });
    
    
    
    // // profileModal.onDidDismiss(() => {
    
    // // popupShown = false;
    // // scope.sign.registerBackButton(scope.platform, scope.navCtrl, scope.alertCtrl)
    
    // // });
    
    
    // // profileModal.present();
    // }
    // });
    
    
    // //---------------When offline show tineri tips from localstorage CLOSE---------------------------
    // }
    
    // }
    
    // }
    
    // })
    // });
    
    // }, 100);
    
    
    
    // }

    
    }


    trigger(){
        this.check_height();
    }

  
}
