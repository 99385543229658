import { UploadDocumentComponent } from './../upload-document/upload-document.component';
import { UserIdService } from './../services/user-id.service';
import { DocumentService } from './../services/document.service';
import { Component, OnInit } from '@angular/core';
import { MatIconRegistry } from "@angular/material/icon";
import {DomSanitizer} from '@angular/platform-browser';
import { Router } from '@angular/router';
import * as $ from 'jquery';
import { DocumentCategoryService } from './../services/document-category.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Location } from '@angular/common';
import { saveAs } from 'file-saver';
import { ViewChild,} from '@angular/core';
import { MatAccordion } from '@angular/material';


@Component({
  selector: 'app-document',
  templateUrl: './document.component.html',
  styleUrls: ['./document.component.css']
})
export class DocumentComponent implements OnInit {

  tourListProvider
  show_scroll_up_btn=false

  userinfo

  translationsLabels

  show_close_button=false


  groupDocArr

  main_doc_array =[]

  sub_doc_array

  user_id

  docArrlength

  docArr=[]

  allDocs

  expand=0
  searchText=''

  filtered_doc_cat_ary
  @ViewChild('accordion') Accordion: MatAccordion;

  header_img

  constructor( private documentService:DocumentService,
    private matIconRegistry: MatIconRegistry,
    public domSanitizer:DomSanitizer,
    private router: Router, 
    private location: Location,
    
    private documentCategoryService: DocumentCategoryService,
    private userIdService:UserIdService,
    private matDialog: MatDialog) {

      this.header_img = JSON.parse(localStorage.getItem("LSDitinerarydata")).itinerary.imgSrc

      var companyinfo = JSON.parse(localStorage.getItem("companyProvider"))
      this.userinfo = JSON.parse(localStorage.getItem("UserData"))

    this.translationsLabels = this.userinfo.user.translations


  // BOC Erica (01)
  var index = (localStorage.getItem('INDEX_WELCOME'))
  this.tourListProvider = JSON.parse(localStorage.getItem("tourListProvider"))


  // setTimeout(() => {
  //     if (this.tourListProvider[index].brand.length == undefined) {

  //         $('#Header').text('app-header .bbb svg{fill:'+ this.tourListProvider[index].brand.color + ' !important; stroke-opacity:0 !important}'+
  //       'app-document .bbb svg rect{fill:'+ this.tourListProvider[index].brand.color +' !important ; stroke-opacity:0 !important}'+
  //         'app-document .bbb svg polygon{fill:'+ this.tourListProvider[index].brand.color+ ' !important ; stroke-opacity:0 !important}'+
  //        'app-document .bbb svg path{fill:'+ this.tourListProvider[index].brand.color +' !important; stroke-opacity:0 !important}'+
  //        'app-document .bbb svg path:nth-of-type(n){fill:'+ this.tourListProvider[index].brand.color +' !important; stroke-opacity:0 !important}'+
  //        'app-document .bbb svg path:nth-of-type(1){fill:'+ this.tourListProvider[index].brand.color +' !important; stroke-opacity:0 !important}'+
  //        'app-document .bbb svg path:nth-of-type(2){fill:'+ this.tourListProvider[index].brand.color +' !important; stroke-opacity:0 !important}'+
  //        'app-document .bbb svg path:nth-of-type(3){fill:'+ this.tourListProvider[index].brand.color +' !important; stroke-opacity:0 !important}'+
      
        
        
  //        'app-document .bbb svg{fill:'+ this.tourListProvider[index].brand.color + ' !important; stroke-opacity:0 !important}'+
  //        'app-document .bbb svg rect{fill:'+ this.tourListProvider[index].brand.color +' !important ; stroke-opacity:0 !important}'+
  //          'app-document .bbb svg polygon{fill:'+ this.tourListProvider[index].brand.color+ ' !important ; stroke-opacity:0 !important}'+
  //         'app-document .bbb svg path{fill:'+ this.tourListProvider[index].brand.color +' !important; stroke-opacity:0 !important}'+
  //         'app-document .bbb svg path:nth-of-type(n){fill:'+ this.tourListProvider[index].brand.color +' !important; stroke-opacity:0 !important}'+
  //         'app-document .bbb svg path:nth-of-type(1){fill:'+ this.tourListProvider[index].brand.color +' !important; stroke-opacity:0 !important}'+
  //         'app-document .bbb svg path:nth-of-type(2){fill:'+ this.tourListProvider[index].brand.color +' !important; stroke-opacity:0 !important}'+
  //         'app-document .bbb svg path:nth-of-type(3){fill:'+ this.tourListProvider[index].brand.color +' !important; stroke-opacity:0 !important}'+
       
         
         
  //         'app-document .ccc svg{fill:white !important; stroke-opacity:0 !important}'+
  //         'app-document .ccc svg rect{fill:white !important; stroke-opacity:0 !important}'+
  //         'app-document .ccc svg polygon:nth-of-type(n){fill:white !important; stroke-opacity:0 !important}'+
  //         'app-document .ccc svg polygon:nth-of-type(1){fill:white !important; stroke-opacity:0 !important}'+
  //         'app-document .ccc svg path:nth-of-type(1){fill:white !important; stroke-opacity:0 !important}'+
  //         'app-document .ccc svg path:nth-of-type(2){fill:white !important; stroke-opacity:0 !important}'+
  //         'app-document .ccc svg path:nth-of-type(3){fill:white !important; stroke-opacity:0 !important}'+
  //         'app-document .ccc svg path:nth-of-type(n){fill:white !important; stroke-opacity:0 !important}'+
  //           'app-document .ccc svg polygon{fill:white !important; stroke-opacity:0 !important}'+
  //          'app-document .ccc svg path{fill:white !important; stroke-opacity:0 !important}'

           
           
      
      
  //         );
  //     } else {

         
         
         
  //         // $('#Header').text('app-header .bbb svg{fill:' + JSON.parse(localStorage.getItem("primaryColor")) + ' !important;}'
      
      
  //         // );
  //     }
  //     }, 1000);
       // EOC Erica (01)


       setTimeout(()=>{this.check_height()},100) 

      }
  
     
      

        check_height(){

         
            if($(window).height()>= $(".mainContainer").height()+250){
              this.show_scroll_up_btn=false;
            }else if($(window).height()< $(".mainContainer").height()+250){
              this.show_scroll_up_btn=true;
            }
      
            
          }
  
        scroll_up(){
    
          $(window).scrollTop( $("body").offset().top );
      }

      trigger(){
        setTimeout(()=>{this.check_height()},100) 
      }

  

  ngOnInit() {

    this.svgregister_icon();

    
        
    

   

  

         localStorage.getItem("user_role");

  


    if(localStorage.getItem('user_role')=="Travellers" ||
    localStorage.getItem('user_role')=="Guides"){

      this.getdoc(); 
      this.show_close_button=false
    }else if(localStorage.getItem('user_role')=="Admin" ||
     localStorage.getItem('user_role')=="Users"){

      this.userIdService.getData();

      //this.getdocadmin()
      this.getdocadmin_new()
      this.show_close_button=true;

        // this.router.navigate(['show-all-traveller-doc'])  
    }


  }

  close_page(){
    this.location.back();
  }



  getdoc() {


        this.documentService.all((data) => {

        

          if(data=="ERROR"){
            this.groupDocArr =[]

            this.main_doc_array=[]
          }else{
            

            

            if(data!="document categories not found"){


              this.groupDocArr = data

        
  
              this.main_doc_array= data

             
  
  
              
          for (let z = 0; z < this.groupDocArr.length; z++) {
            
  
            this.documentService.DocumentFactoryAll(this.groupDocArr[z].id, (data) => {
  
              
  
             
              if (data == 'document not found') {
  
                this.docArr = [];
  
                this.sub_doc_array=[]
  
              } else if (data != 'error') {
  
                this.sub_doc_array=data
  
                for(var i=0; i<this.sub_doc_array.length; i++){
  
                  var extension = this.ext(this.sub_doc_array[i].image).toLowerCase();
  
                   this.sub_doc_array[i].localurl = this.sub_doc_array[i].image
  
                  //this.sub_doc_array[i].localurl = this.domSanitizer.bypassSecurityTrustResourceUrl('data:image/png;base64,'+ this.sub_doc_array[i].image);
  
  
                  this.sub_doc_array[i].Islocal = 0;
                  this.sub_doc_array[i].docType = "";
  
                  if (extension == '.jpg' || extension == '.png' || extension == '.jpeg' || extension == '.bmp') {
                    this.sub_doc_array[i].icon_type ="img"
                    this.sub_doc_array[i].pdf_url = this.domSanitizer.bypassSecurityTrustResourceUrl( this.sub_doc_array[i].localurl);
  
                  } else if (extension == '.doc' || extension == '.docx') {
                    this.sub_doc_array[i].icon_type ="doc"
                  } else if (extension == '.xls' || extension == '.xlsx') {
                    this.sub_doc_array[i].icon_type ="xls"
                  } else if (extension == '.ppt' || extension == '.pptx') {
                    this.sub_doc_array[i].icon_type ="ppt"
                  } else if (extension == '.pdf') {
                    this.sub_doc_array[i].icon_type ="pdf"
  
                    this.sub_doc_array[i].pdf_url = this.domSanitizer.bypassSecurityTrustResourceUrl( this.sub_doc_array[i].localurl);
  
  
                  } else if (extension == '.txt') {
                    this.sub_doc_array[i].icon_type ="txt"
                  }
  
  
                }
                
  
                for (let i = 0; i < data.length; i++) {
                  var extension = this.ext(data[i].image).toLowerCase();
                  
                
  
  
                 
                  data[i].localurl = data[i].image
                  data[i].Islocal = 0;
                  data[i].docType = "";
  
                  if (window.localStorage.getItem('LSDDocumentList') != null) {
  
                    let p = 0;
  
                    for (let j = 0; j < (JSON.parse(window.localStorage.getItem('LSDDocumentList'))).length; j++) {
                      if (JSON.parse(window.localStorage.getItem('LSDDocumentList'))[j].id == data[i].id) {
                      
                        p = 1
                      }
  
                    }
  
  
                    if (p != 1) {
                    
  
                      var N
                      N = JSON.parse(window.localStorage.getItem('LSDDocumentList'));
                      N.push(data[i])
                      window.localStorage.setItem('LSDDocumentList', JSON.stringify(N))
  
                 
  
                    }
  
                  } else {
                    var arr = []
                    arr.push(data[i])
                    window.localStorage.setItem('LSDDocumentList', JSON.stringify(arr))
  
                  }
  
  
  
                  if (i == data.length - 1 && z == this.groupDocArr.length - 1 )
                  {
  
  
                    var LSDdocListoffline = JSON.parse(window.localStorage.getItem('LSDDocumentList'));
  
                    this.docArr=[]
                    if (LSDdocListoffline) {
                      for (let x = 0; x < LSDdocListoffline.length; x++) {
  
                        var extension = this.ext(LSDdocListoffline[x].image).toLowerCase();
                      
                        if (extension == '.jpg' || extension == '.png' || extension == '.jpeg' || extension == '.bmp') {
                          LSDdocListoffline[x].icon_type ="img"
                        } else if (extension == '.doc' || extension == '.docx') {
                          LSDdocListoffline[x].icon_type ="doc"
                        } else if (extension == '.xls' || extension == '.xlsx') {
                          LSDdocListoffline[x].icon_type ="xls"
                        } else if (extension == '.ppt' || extension == '.pptx') {
                          LSDdocListoffline[x].icon_type ="ppt"
                        } else if (extension == '.pdf') {
                          LSDdocListoffline[x].icon_type ="pdf"
                        } else if (extension == '.txt') {
                          LSDdocListoffline[x].icon_type ="txt"
                        }
  
  
                          this.docArr.push(LSDdocListoffline[x])
                          this.allDocs =this.docArr
  
                          if (i == (LSDdocListoffline.length - 1) )
                          {
                            this.allDocs =this.docArr
                      
                          }
  
                      }
  
  
                  }
  
  
                }
  
  
  
  
                }
               
              } else {
            
              }
  
  
              
              this.main_doc_array[z].sub_doc_array=this.sub_doc_array

            
  
  
              
  
            })
  
  
  
        }
  
  
       
  
      
              this.svgregistercustom_icon(data);
            }

           
          }
       


      });
      }
      

      svgregistercustom_icon( document_categories) {

        if(document_categories!=undefined){
          document_categories.forEach(element => {
            this.matIconRegistry.addSvgIcon(
                element.name+"doc_category",
                this.domSanitizer.bypassSecurityTrustResourceUrl(element.icon_url)
            );
        });
        }

       
      }

      svgregister_icon( ) {

     
    
        this.matIconRegistry.addSvgIcon(
          "doc",
          this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/Images/docs/doc.svg")
        );
    
          this.matIconRegistry.addSvgIcon(
            "pdf",
            this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/Images/docs/pdf.svg")
        );
    
        this.matIconRegistry.addSvgIcon(
          "ppt",
          this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/Images/docs/ppt.svg")
        );
        this.matIconRegistry.addSvgIcon(
          "txt",
          this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/Images/docs/txt.svg")
        );
    
        this.matIconRegistry.addSvgIcon(
          "xls",
          this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/Images/docs/xls.svg")
        );
    
        this.matIconRegistry.addSvgIcon(
          "download",
          this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/Images/docs/download.svg")
        );
    
        this.matIconRegistry.addSvgIcon(
          "png",
          this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/Images/docs/png.svg")
        );
        this.matIconRegistry.addSvgIcon(
          "img",
          this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/Images/docs/img.svg")
        );
        this.matIconRegistry.addSvgIcon(
          "jpg",
          this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/Images/docs/jpg.svg")
        );
    
        this.matIconRegistry.addSvgIcon(
          "bmp",
          this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/Images/docs/bmp.svg")
        );
    
        this.matIconRegistry.addSvgIcon(
          "delete",
          this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/Images/docs/delete.svg")
        );
    
    
        this.matIconRegistry.addSvgIcon(
          "plus",
          this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/Images/docs/plus.svg")
        );


        
        this.matIconRegistry.addSvgIcon(
          "eye",
          this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/Images/mixed/view.svg")
      );

      this.matIconRegistry.addSvgIcon(
          "hide",
          this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/Images/mixed/hide.svg")
      );

      this.matIconRegistry.addSvgIcon(
        "expand",
        this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/Images/mixed/expand.svg")
    )
    this.matIconRegistry.addSvgIcon(
      "upload",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/Images/mixed/upload.svg")
  );

    


    
          }

      openDocumentList(doc_cat){
        this.router.navigate(['document-list']);

        
        this.documentCategoryService.setData(doc_cat);
      

      }

      //Admin and USer Mode

     


      getdocadmin() {
        this.user_id = this.userIdService.getData()
        window.localStorage.removeItem('LSDDocumentList')
    
            // this.groupDocArr = this.document.GroupDocument(this.displayFeatures).all();
            // this.groupDocArr = this.document.all();
            this.documentService.allAdmin(this.user_id ,(data) => {


              if(data =="document categories not found"){

              

              }else if(data!="document categories not found"){

                this.groupDocArr = data

                this.svgregistercustom_icon(data);
      
                this.docArrlength = this.groupDocArr.length;
        
                for (let z = 0; z < this.groupDocArr.length; z++) {
        
                  this.documentService.DocumentFactoryAllTraveller(this.groupDocArr[z].id , this.user_id, (data) => {
                  // this.DocumentsProvider.DocumentFactoryAll(this.groupDocArr[z].id, (data) => {
        
                    
        
                    if (data == 'document not found') {
        
                      this.docArr = [];
        
                    } else if (data != 'error') {
                    // this.documentsAvailableForOfflineDownload = true //Midhun(10)
                      var docArr = [];
                      var doc_img = [];
                      var fileName = 'Documentsimg_'
        
                      for (let i = 0; i < data.length; i++) {
                        var extension = this.ext(data[i].image).toLowerCase();
                     
                        if (extension == '.jpg' || extension == '.png' || extension == '.jpeg' || extension == '.bmp') {
                          data[i].showImage = "{'background-image':'url(" + data[i].image + ")', 'background-size': 'contain'}";
                        } else if (extension == '.doc' || extension == '.docx') {
                          data[i].showImage = "{'background-image':'url(\"assets/img/docs/doc_icon.png\")', 'background-size': 'initial'}";
                        } else if (extension == '.xls' || extension == '.xlsx') {
                          data[i].showImage = "{'background-image':'url(\"assets/img/docs/xls_icon.png\")', 'background-size': 'initial'}";
                        } else if (extension == '.ppt' || extension == '.pptx') {
                          data[i].showImage = "{'background-image':'url(\"assets/img/docs/ppt_icon.png\")', 'background-size': 'initial'}";
                        } else if (extension == '.pdf') {
                          data[i].showImage = "{'background-image':'url(\"assets/img/docs/pdf_icon.png\")', 'background-size': 'initial'}";
                        } else if (extension == '.txt') {
                          data[i].showImage = "{'background-image':'url(\"assets/img/docs/txt_icon.png\")', 'background-size': 'initial'}";
                        }
        
        
                     
                        data[i].localurl = data[i].image
                        data[i].Islocal = 0;
                        data[i].docType = "";
        
                        if (window.localStorage.getItem('LSDDocumentList') != null) {
        
                          let p = 0;
        
                          for (let j = 0; j < (JSON.parse(window.localStorage.getItem('LSDDocumentList'))).length; j++) {
                            if (JSON.parse(window.localStorage.getItem('LSDDocumentList'))[j].id == data[i].id) {
                           
                              p = 1
                            }
        
                          }
        
        
                          if (p != 1) {
                            
        
                            var N
                            N = JSON.parse(window.localStorage.getItem('LSDDocumentList'));
                            N.push(data[i])
                            window.localStorage.setItem('LSDDocumentList', JSON.stringify(N))
        
        
        
                          }
        
                        } else {
                          var arr = []
                          arr.push(data[i])
                          window.localStorage.setItem('LSDDocumentList', JSON.stringify(arr))
  
  
                        
                        }
        
        
                        
                    
                        if (i == data.length - 1 && z == this.groupDocArr.length - 1 )
                        {
        
        
                          var LSDdocListoffline = JSON.parse(window.localStorage.getItem('LSDDocumentList'));
        
                          this.docArr=[]
                          if (LSDdocListoffline) {
                            for (let x = 0; x < LSDdocListoffline.length; x++) {
        
                              var extension = this.ext(LSDdocListoffline[x].image).toLowerCase();
                  
                              if (extension == '.jpg' || extension == '.png' || extension == '.jpeg' || extension == '.bmp') {
                                LSDdocListoffline[x].icon_type ="img"
                              } else if (extension == '.doc' || extension == '.docx') {
                                LSDdocListoffline[x].icon_type ="doc"
                              } else if (extension == '.xls' || extension == '.xlsx') {
                                LSDdocListoffline[x].icon_type ="xls"
                              } else if (extension == '.ppt' || extension == '.pptx') {
                                LSDdocListoffline[x].icon_type ="ppt"
                              } else if (extension == '.pdf') {
                                LSDdocListoffline[x].icon_type ="pdf"
                              } else if (extension == '.txt') {
                                LSDdocListoffline[x].icon_type ="txt"
                              }
        
        
                                this.docArr.push(LSDdocListoffline[x])
                                this.allDocs =this.docArr
        
                                if (i == (LSDdocListoffline.length - 1) )
                                {
                                  this.allDocs =this.docArr
                             
                                }
        
                            }
        
        
                        }
        
        
                      }
        
        
        
        
                      }
                
                    } else {
                  
                    }
        
        
                  })
        
        
        
              }
  

              }
    
            

      
          });
      }






          getdocadmin_new() {
            this.user_id = this.userIdService.getData()

            if(this.user_id==""){
              this.location.back();
            }

           
           
            window.localStorage.removeItem('LSDDocumentList')
        
                // this.groupDocArr = this.document.GroupDocument(this.displayFeatures).all();
                // this.groupDocArr = this.document.all();
                this.documentService.allAdmin(this.user_id ,(data) => {

                  if(data!="document categories not found"){

                    this.groupDocArr = data

                    this.main_doc_array =data

             
      
                    this.svgregistercustom_icon(data);
          
                  this.docArrlength = this.groupDocArr.length;
                 
          
                  for (let z = 0; z < this.groupDocArr.length; z++) {
                    
          
                    this.documentService.DocumentFactoryAllTraveller(this.groupDocArr[z].id , this.user_id, (data) => {
                    // this.DocumentsProvider.DocumentFactoryAll(this.groupDocArr[z].id, (data) => {
          
                      
          
                      if (data == 'document not found') {
          
                        this.docArr = [];
                        this.sub_doc_array=[]
          
                      } else if (data != 'error') {
                       // this.documentsAvailableForOfflineDownload = true //Midhun(10)
  
  
                       this.sub_doc_array=data
  
                       for(var i=0; i<this.sub_doc_array.length; i++){
         
                         var extension = this.ext(this.sub_doc_array[i].image).toLowerCase();
         
                          this.sub_doc_array[i].localurl = this.sub_doc_array[i].image
         
                         //this.sub_doc_array[i].localurl = this.domSanitizer.bypassSecurityTrustResourceUrl('data:image/png;base64,'+ this.sub_doc_array[i].image);
         
         
                         this.sub_doc_array[i].Islocal = 0;
                         this.sub_doc_array[i].docType = "";
         
                         if (extension == '.jpg' || extension == '.png' || extension == '.jpeg' || extension == '.bmp') {
                           this.sub_doc_array[i].icon_type ="img"
                           this.sub_doc_array[i].pdf_url = this.domSanitizer.bypassSecurityTrustResourceUrl( this.sub_doc_array[i].localurl);
         
                         } else if (extension == '.doc' || extension == '.docx') {
                           this.sub_doc_array[i].icon_type ="doc"
                         } else if (extension == '.xls' || extension == '.xlsx') {
                           this.sub_doc_array[i].icon_type ="xls"
                         } else if (extension == '.ppt' || extension == '.pptx') {
                           this.sub_doc_array[i].icon_type ="ppt"
                         } else if (extension == '.pdf') {
                           this.sub_doc_array[i].icon_type ="pdf"
         
                           this.sub_doc_array[i].pdf_url = this.domSanitizer.bypassSecurityTrustResourceUrl( this.sub_doc_array[i].localurl);
         
         
                         } else if (extension == '.txt') {
                           this.sub_doc_array[i].icon_type ="txt"
                         }
         
         
                       }
                       
  
  
  
  
                        var docArr = [];
                        var doc_img = [];
                        var fileName = 'Documentsimg_'
          
                        for (let i = 0; i < data.length; i++) {
                          var extension = this.ext(data[i].image).toLowerCase();
             
                          if (extension == '.jpg' || extension == '.png' || extension == '.jpeg' || extension == '.bmp') {
                            data[i].showImage = "{'background-image':'url(" + data[i].image + ")', 'background-size': 'contain'}";
                          } else if (extension == '.doc' || extension == '.docx') {
                            data[i].showImage = "{'background-image':'url(\"assets/img/docs/doc_icon.png\")', 'background-size': 'initial'}";
                          } else if (extension == '.xls' || extension == '.xlsx') {
                            data[i].showImage = "{'background-image':'url(\"assets/img/docs/xls_icon.png\")', 'background-size': 'initial'}";
                          } else if (extension == '.ppt' || extension == '.pptx') {
                            data[i].showImage = "{'background-image':'url(\"assets/img/docs/ppt_icon.png\")', 'background-size': 'initial'}";
                          } else if (extension == '.pdf') {
                            data[i].showImage = "{'background-image':'url(\"assets/img/docs/pdf_icon.png\")', 'background-size': 'initial'}";
                          } else if (extension == '.txt') {
                            data[i].showImage = "{'background-image':'url(\"assets/img/docs/txt_icon.png\")', 'background-size': 'initial'}";
                          }
          
          
                   
                          data[i].localurl = data[i].image
                          data[i].Islocal = 0;
                          data[i].docType = "";
          
                          if (window.localStorage.getItem('LSDDocumentList') != null) {
          
                            let p = 0;
          
                            for (let j = 0; j < (JSON.parse(window.localStorage.getItem('LSDDocumentList'))).length; j++) {
                              if (JSON.parse(window.localStorage.getItem('LSDDocumentList'))[j].id == data[i].id) {
                            
                                p = 1
                              }
          
                            }
          
          
                            if (p != 1) {
                        
          
                              var N
                              N = JSON.parse(window.localStorage.getItem('LSDDocumentList'));
                              N.push(data[i])
                              window.localStorage.setItem('LSDDocumentList', JSON.stringify(N))
          
          
          
                            }
          
                          } else {
                            var arr = []
                            arr.push(data[i])
                            window.localStorage.setItem('LSDDocumentList', JSON.stringify(arr))
      
      
                           
                          }
          
          
                          
                       
                          if (i == data.length - 1 && z == this.groupDocArr.length - 1 )
                          {
          
          
                            var LSDdocListoffline = JSON.parse(window.localStorage.getItem('LSDDocumentList'));
          
                            this.docArr=[]
                            if (LSDdocListoffline) {
                              for (let x = 0; x < LSDdocListoffline.length; x++) {
          
                                var extension = this.ext(LSDdocListoffline[x].image).toLowerCase();
                            
                                if (extension == '.jpg' || extension == '.png' || extension == '.jpeg' || extension == '.bmp') {
                                  LSDdocListoffline[x].icon_type ="img"
                                } else if (extension == '.doc' || extension == '.docx') {
                                  LSDdocListoffline[x].icon_type ="doc"
                                } else if (extension == '.xls' || extension == '.xlsx') {
                                  LSDdocListoffline[x].icon_type ="xls"
                                } else if (extension == '.ppt' || extension == '.pptx') {
                                  LSDdocListoffline[x].icon_type ="ppt"
                                } else if (extension == '.pdf') {
                                  LSDdocListoffline[x].icon_type ="pdf"
                                } else if (extension == '.txt') {
                                  LSDdocListoffline[x].icon_type ="txt"
                                }
          
          
                                  this.docArr.push(LSDdocListoffline[x])
                                  this.allDocs =this.docArr
          
                                  if (i == (LSDdocListoffline.length - 1) )
                                  {
                                    this.allDocs =this.docArr
                                    
                                  }
          
                              }
          
          
                          }
          
          
                        }
          
          
          
          
                        }
                  
                      } else {
                    
                      }
                      this.main_doc_array[z].sub_doc_array=this.sub_doc_array

                   
          
                    });
          
          
          
                }
  
                  }
        
                 

        
              });
              }
    

          ext(url) {
            return (url = url.substr(1 + url.lastIndexOf("/")).split('?')[0]).split('#')[0].substr(url.lastIndexOf("."))
          }

          upload_doc() {
          
      
            const dialogRef = this.matDialog.open(UploadDocumentComponent, {
                data: {
                    message: "upload_doc"
                }
            });
      
            dialogRef.afterClosed().subscribe(result => {
            

                setTimeout(()=>{

                  this.refresh_Data();

                },1000)


            


            });
        }

        refresh_Data(){
          if(localStorage.getItem('user_role')=="Travellers" ||
          localStorage.getItem('user_role')=="Guides"){
         
            this.getdoc(); 
          }else if(localStorage.getItem('user_role')=="Admin" ||
           localStorage.getItem('user_role')=="Users"){
      
            this.userIdService.getData();
         
      
            //this.getdocadmin()
            this.getdocadmin_new()
      
              // this.router.navigate(['show-all-traveller-doc'])  
          }
      
        }


   
       

        searchUsers(event){


          let val = event.target.value;
      
          this.searchText = val
      
      
         
          if(this.searchText==''){
            this.docArr=this.allDocs;
          }else if(this.searchText!=''){
            // arr.name.toLowerCase().indexOf(this.searchText.toLowerCase()) > -1
            var  b = this.allDocs.filter(arr => arr.name.toLowerCase().indexOf(this.searchText.toLowerCase()) > -1)
           
            this.docArr=b;
          }

          if(this.searchText==''){
            this.filtered_doc_cat_ary=this.groupDocArr;
          }else if(this.searchText!=''){
            // arr.name.toLowerCase().indexOf(this.searchText.toLowerCase()) > -1
            this.filtered_doc_cat_ary = this.groupDocArr.filter(arr => arr.name.toLowerCase().indexOf(this.searchText.toLowerCase()) > -1)
            
          }

          //danger
          setTimeout(()=>{this.check_height()},100) 
      
        
      
        }

       

        download(url){
          var FileSaver = require('file-saver');
          FileSaver.saveAs(url);
        }

        closeAllPanels() {
          this.expand = 0
          this.Accordion.closeAll();

       
        
        }
        
        openAllPanels() {
          this.expand = 1
          this.Accordion.openAll();
        
        }

        togglePanel(doc,value){
          doc.panelOpenState = value;
          const anyOpen = this.main_doc_array.some(day => day.panelOpenState);

         
          
          if (anyOpen){
              this.expand = 1
          }else{
              this.expand = 0
          }
        }
        
        

}
