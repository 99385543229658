import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, CanActivate,Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class InsidePagesGuard implements CanActivate {


  constructor(private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const token = localStorage.getItem('UserData');
    
    
    if (token == undefined || token == null) {
    this.router.navigate(['']);
    return false;
    }


    var index = (localStorage.getItem('INDEX_WELCOME'))

    if (token != undefined && (index ==undefined || index ==null )) {
      this.router.navigate(['welcome']);
      return false;
      }

    return true;
    }
  


  
}
