import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PassSelectedGuideService {

 
  guide

  constructor() { }

  setData( guide){
    
    console.log("PASSSTTTT             ",guide)
   
    this.guide= guide;
  }
  getGuide(){
    return this.guide;
  }
 
}
