import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SubMenuServiceService {

  sharedData ={}
  //erica

  constructor() { }

  setData(val){
    this.sharedData= val;
  }
  getData(){
    return this.sharedData;
  }
  
}
