import { DeleteDocumentComponent } from './../delete-document/delete-document.component';
import { UserIdService } from './../services/user-id.service';
import { DocumentCategoryService } from './../services/document-category.service';
import { Component, OnInit } from '@angular/core';
import { DocumentService } from './../services/document.service';
import { MatIconRegistry } from "@angular/material/icon";
import {DomSanitizer} from '@angular/platform-browser';
import { Router } from '@angular/router';
import * as $ from 'jquery';
import { DocumentViewService } from './../services/document-view.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Location } from '@angular/common';

@Component({
  selector: 'app-document-list',
  templateUrl: './document-list.component.html',
  styleUrls: ['./document-list.component.css']
})
export class DocumentListComponent implements OnInit {

  header_img

  constructor( private documentService:DocumentService,
    private matIconRegistry: MatIconRegistry,
    public domSanitizer:DomSanitizer,
    private router: Router, 
    private documentCategoryService: DocumentCategoryService,
    private documentViewService: DocumentViewService,
    private userIdService:UserIdService,
    private location: Location,
    private matDialog: MatDialog) {

      this.header_img = JSON.parse(localStorage.getItem("LSDitinerarydata")).itinerary.imgSrc


    setTimeout(()=>{this.check_height()},100) 

    setTimeout(()=>{this.check_height()},1000) 

    }

    show_scroll_up_btn
    check_height(){
     
        if($(window).height()>= $(document).height()){
          this.show_scroll_up_btn=false;
        }else if($(window).height()< $(document).height()){
          this.show_scroll_up_btn=true;
        }
  
       
      }

      scroll_up(){
  
        $(window).scrollTop( $("body").offset().top );
    }

    close_page(){
      this.location.back();
    }

  documentCategory

  groupDocArr=[]

  docArr=[]
  tourListProvider
  ngOnInit() {

    this.svgregistercustom_icon();

    var index = (localStorage.getItem('INDEX_WELCOME'))

    this.tourListProvider = JSON.parse(localStorage.getItem("tourListProvider"))


    // setTimeout(() => {
    //     if (this.tourListProvider[index].brand.length == undefined) {
    //         console.log("TEST IS ON", this.tourListProvider[index].brand.color)
    //         $('#Header').text('app-header .bbb svg{fill:'+ this.tourListProvider[index].brand.color + ' !important; stroke-opacity:0 !important}'+
    //       'app-document-list .bbb svg rect{fill:'+ this.tourListProvider[index].brand.color +' !important ; stroke-opacity:0 !important}'+
    //         'app-document-list .bbb svg polygon{fill:'+ this.tourListProvider[index].brand.color+ ' !important ; stroke-opacity:0 !important}'+
    //        'app-document-list .bbb svg path{fill:'+ this.tourListProvider[index].brand.color +' !important; stroke-opacity:0 !important}'+
    //        'app-document-list .bbb svg path:nth-of-type(n){fill:'+ this.tourListProvider[index].brand.color +' !important; stroke-opacity:0 !important}'+
    //        'app-document-list .bbb svg path:nth-of-type(1){fill:'+ this.tourListProvider[index].brand.color +' !important; stroke-opacity:0 !important}'+
    //        'app-document-list .bbb svg path:nth-of-type(2){fill:'+ this.tourListProvider[index].brand.color +' !important; stroke-opacity:0 !important}'+
    //        'app-document-list .bbb svg path:nth-of-type(3){fill:'+ this.tourListProvider[index].brand.color +' !important; stroke-opacity:0 !important}'+
        
          
          
    //        'app-document-list .bbb svg{fill:'+ this.tourListProvider[index].brand.color + ' !important; stroke-opacity:0 !important}'+
    //        'app-document-list .bbb svg rect{fill:'+ this.tourListProvider[index].brand.color +' !important ; stroke-opacity:0 !important}'+
    //          'app-document-list .bbb svg polygon{fill:'+ this.tourListProvider[index].brand.color+ ' !important ; stroke-opacity:0 !important}'+
    //         'app-document-list .bbb svg path{fill:'+ this.tourListProvider[index].brand.color +' !important; stroke-opacity:0 !important}'+
    //         'app-document-list .bbb svg path:nth-of-type(n){fill:'+ this.tourListProvider[index].brand.color +' !important; stroke-opacity:0 !important}'+
    //         'app-document-list .bbb svg path:nth-of-type(1){fill:'+ this.tourListProvider[index].brand.color +' !important; stroke-opacity:0 !important}'+
    //         'app-document-list .bbb svg path:nth-of-type(2){fill:'+ this.tourListProvider[index].brand.color +' !important; stroke-opacity:0 !important}'+
    //         'app-document-list .bbb svg path:nth-of-type(3){fill:'+ this.tourListProvider[index].brand.color +' !important; stroke-opacity:0 !important}'+
         
           
           
    //         'app-document-list .ccc svg{fill:white !important; stroke-opacity:0 !important}'+
    //         'app-document-list .ccc svg rect{fill:white !important; stroke-opacity:0 !important}'+
    //         'app-document-list .ccc svg polygon:nth-of-type(n){fill:white !important; stroke-opacity:0 !important}'+
    //         'app-document-list .ccc svg polygon:nth-of-type(1){fill:white !important; stroke-opacity:0 !important}'+
    //         'app-document-list .ccc svg path:nth-of-type(1){fill:white !important; stroke-opacity:0 !important}'+
    //         'app-document-list .ccc svg path:nth-of-type(2){fill:white !important; stroke-opacity:0 !important}'+
    //         'app-document-list .ccc svg path:nth-of-type(3){fill:white !important; stroke-opacity:0 !important}'+
    //         'app-document-list .ccc svg path:nth-of-type(n){fill:white !important; stroke-opacity:0 !important}'+
    //           'app-document-list .ccc svg polygon{fill:white !important; stroke-opacity:0 !important}'+
    //          'app-document-list .ccc svg path{fill:white !important; stroke-opacity:0 !important}'
             
        
        
    //         );
    //     } else {
    //         console.log("Entry else", JSON.parse(localStorage.getItem("primaryColor")))
    //         // $('#Header').text('app-header .bbb svg{fill:' + JSON.parse(localStorage.getItem("primaryColor")) + ' !important;}'
        
        
    //         // );
    //     }
    //     }, 100);
         // EOC Erica (01)


    if(this.documentCategoryService.getData()=={}){
      this.location.back();

    }

    this.documentCategory = this.documentCategoryService.getData();

    if(localStorage.getItem('user_role')=="Travellers" ||
    localStorage.getItem('user_role')=="Guides"){
      this.getdoc(); 
    }else if(localStorage.getItem('user_role')=="Admin" ||
     localStorage.getItem('user_role')=="Users"){

      this.userIdService.getData();

      this.getdocadmin()

        // this.router.navigate(['show-all-traveller-doc'])  
    }

  

  }

  ext(url) {
    return (url = url.substr(1 + url.lastIndexOf("/")).split('?')[0]).split('#')[0].substr(url.lastIndexOf("."))
  }

  seticonflag(image) {
    var extension = this.ext(image).toLowerCase();
    if (extension == '.jpg' || extension == '.png' || extension == '.jpeg' || extension == '.bmp') {
      return true;
    } else if (extension == '.doc' || extension == '.docx' ||extension == '.xls' || extension == '.xlsx'
    || extension == '.ppt' || extension == '.pptx' || extension == '.pdf'|| extension == '.txt' ) {
      return false;
    }
  }


  getdoc(){
    this.documentService.documentList(this.documentCategory.id,(data)=>{


      if(data=="error" || data=="document not found"){
        this.docArr=[]

        this.location.back();

      } else if (data != 'error' ||  data=="document not found"){
        

        {
        
  
          for (let i = 0; i < data.length; i++) {
            
            var extension = this.ext(data[i].image).toLowerCase();
            console.log(extension);
            if (extension == '.jpg' || extension == '.png' || extension == '.jpeg' || extension == '.bmp') {
              data[i].showImage = "{'background-image':'url(" + data[i].image + ")', 'background-size': 'contain'}";
              data[i].icon_type ="img"
            } else if (extension == '.doc' || extension == '.docx') {
              data[i].showImage = "{'background-image':'url(\"assets/img/docs/doc_icon.png\")', 'background-size': 'initial'}";
              data[i].icon_type ="doc"
            } else if (extension == '.xls' || extension == '.xlsx') {
              data[i].showImage = "{'background-image':'url(\"assets/img/docs/xls_icon.png\")', 'background-size': 'initial'}";
              data[i].icon_type ="xls"
            } else if (extension == '.ppt' || extension == '.pptx') {
              data[i].icon_type ="ppt"
              data[i].showImage = "{'background-image':'url(\"assets/img/docs/ppt_icon.png\")', 'background-size': 'initial'}";
            } else if (extension == '.pdf') {
              data[i].icon_type ="pdf"
              data[i].showImage = "{'background-image':'url(\"assets/img/docs/pdf_icon.png\")', 'background-size': 'initial'}";
            } else if (extension == '.txt') {
              data[i].icon_type ="txt"
              data[i].showImage = "{'background-image':'url(\"assets/img/docs/txt_icon.png\")', 'background-size': 'initial'}";
            }
  
            data[i].iconflag=this.seticonflag(data[i].image);
  
            console.log("XIA: docArr",data)
  
  
            data[i].localurl = data[i].image
            data[i].Islocal = 0;
            data[i].docType = "";
  
  
            if (window.localStorage.getItem('LSDDocumentList') != null) {
  
              let p = 0;
  
              for (let j = 0; j < (JSON.parse(window.localStorage.getItem('LSDDocumentList'))).length; j++) {
                if (JSON.parse(window.localStorage.getItem('LSDDocumentList'))[j].id == data[i].id) {
                  
                  p = 1
                }
  
  
  
              }
  
  
              if (p != 1) {
                var N
                N = JSON.parse(window.localStorage.getItem('LSDDocumentList'));
                N.push(data[i])
                window.localStorage.setItem('LSDDocumentList', JSON.stringify(N))
  
              }
  
            } else 
            {
              var arr = []
              arr.push(data[i])
              window.localStorage.setItem('LSDDocumentList', JSON.stringify(arr))
            }
  
  
          }
  
          var LSDocAll = []
          LSDocAll = JSON.parse(window.localStorage.getItem('LSDDocumentList'))
  
  
  
  
  
          for (let k = 0; k < LSDocAll.length; k++) {
  
  
            if (LSDocAll[k].category_type_id == this.documentCategory.id) {
             
  
              var extension = this.ext(LSDocAll[k].image).toLowerCase();
            console.log(extension);
            if (extension == '.jpg' || extension == '.png' || extension == '.jpeg' || extension == '.bmp') {
              LSDocAll[k].icon_type ="img"
            } else if (extension == '.doc' || extension == '.docx') {
              LSDocAll[k].icon_type ="doc"
            } else if (extension == '.xls' || extension == '.xlsx') {
              LSDocAll[k].icon_type ="xls"
            } else if (extension == '.ppt' || extension == '.pptx') {
              LSDocAll[k].icon_type ="ppt"
            } else if (extension == '.pdf') {
              LSDocAll[k].icon_type ="pdf"
            } else if (extension == '.txt') {
              LSDocAll[k].icon_type ="txt"
            }
  
            //Main Assignment
              this.docArr.push(LSDocAll[k])
             
            }
  
          }
  
        
        } 
       
      }



    })
  }


  svgregistercustom_icon( ) {

    // document_list.forEach(element => {
    //     this.matIconRegistry.addSvgIcon(
    //         element.name+"doc_category",
    //         this.domSanitizer.bypassSecurityTrustResourceUrl(element.icon_url)
    //     );
    // });

    this.matIconRegistry.addSvgIcon(
      "doc",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/Images/docs/doc.svg")
    );

      this.matIconRegistry.addSvgIcon(
        "pdf",
        this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/Images/docs/pdf.svg")
    );

    this.matIconRegistry.addSvgIcon(
      "ppt",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/Images/docs/ppt.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "txt",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/Images/docs/txt.svg")
    );

    this.matIconRegistry.addSvgIcon(
      "xls",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/Images/docs/xls.svg")
    );

    this.matIconRegistry.addSvgIcon(
      "download",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/Images/docs/download.svg")
    );

    this.matIconRegistry.addSvgIcon(
      "png",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/Images/docs/png.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "img",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/Images/docs/img.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "jpg",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/Images/docs/jpg.svg")
    );

    this.matIconRegistry.addSvgIcon(
      "bmp",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/Images/docs/bmp.svg")
    );

    this.matIconRegistry.addSvgIcon(
      "delete",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/Images/docs/delete.svg")
    );

    

      }


      openDocumentview(document){

        this.router.navigate(['document-view']);

        
         this.documentViewService.setData(document);

      }


      
  getdocadmin() {

   

    this.documentService.DocumentFactoryAllTraveller(this.documentCategory.id, this.userIdService.getData(), (data) => {
      if (data == 'document not found') {
        this.docArr = [];
        
      } else if (data != 'error') {
        var docArr = [];
        for (let i = 0; i < data.length; i++) {
          var extension = this.ext(data[i].image).toLowerCase();
          console.log(extension);
          if (extension == '.jpg' || extension == '.png' || extension == '.jpeg' || extension == '.bmp') {
            data[i].showImage = "{'background-image':'url(" + data[i].image + ")', 'background-size': 'contain'}";
            data[i].icon_type ="img"
          } else if (extension == '.doc' || extension == '.docx') {
            data[i].showImage = "{'background-image':'url(\"assets/img/docs/doc_icon.png\")', 'background-size': 'initial'}";
            data[i].icon_type ="doc"
          } else if (extension == '.xls' || extension == '.xlsx') {
            data[i].showImage = "{'background-image':'url(\"assets/img/docs/xls_icon.png\")', 'background-size': 'initial'}";
            data[i].icon_type ="xls"
          } else if (extension == '.ppt' || extension == '.pptx') {
            data[i].icon_type ="ppt"
            data[i].showImage = "{'background-image':'url(\"assets/img/docs/ppt_icon.png\")', 'background-size': 'initial'}";
          } else if (extension == '.pdf') {
            data[i].icon_type ="pdf"
            data[i].showImage = "{'background-image':'url(\"assets/img/docs/pdf_icon.png\")', 'background-size': 'initial'}";
          } else if (extension == '.txt') {
            data[i].icon_type ="txt"
            data[i].showImage = "{'background-image':'url(\"assets/img/docs/txt_icon.png\")', 'background-size': 'initial'}";
          }
          data[i].iconflag=this.seticonflag(data[i].image);
          docArr.push(data[i]);
        }

        //Main Assignment
        this.docArr = docArr

       

       
      } else {
       
       
      }
    })

  }


  delete_doc(doc_id) {
    console.log("open dailog Changepassword",doc_id)

    var LSDuserinfoProvider = JSON.parse(localStorage.getItem('userInfoProvider'));
          var LSDdocListoffline1 = JSON.parse(window.localStorage.getItem('LSDDocumentList'));

    let dialogRef = this.matDialog.open(DeleteDocumentComponent, {
        data: {
            message: doc_id
        }
    });

    dialogRef.afterClosed().subscribe(data => {

          for (let j = 0; j < this.docArr.length; j++) {
            if (this.docArr[j].id == data) {
              this.docArr.splice(j, 1)
            }
          }

          if (LSDdocListoffline1) {
            for (let i = 0; i < LSDdocListoffline1.length; i++) {


              if (LSDdocListoffline1[i].id == doc_id) {
                let index_doc = LSDdocListoffline1.lastIndexOf(LSDdocListoffline1[i].id)
                LSDdocListoffline1.splice(i, 1)
                window.localStorage.setItem("LSDDocumentList", JSON.stringify(LSDdocListoffline1))
              }

            }
          }

    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`); // Pizza!
    });
}





}
