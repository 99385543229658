﻿import { AlertDialogDynamicComponent } from './../alert-dialog-dynamic/alert-dialog-dynamic.component';
import { Location } from '@angular/common';
//01:HRUSHIKESH 24-06-2020 change language
import { Component, OnInit, Inject } from '@angular/core';

import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthenticationService } from '../services/authentication.service'

import { TourService } from '../services/tour.service'
import * as moment from 'moment';
import * as $ from 'jquery';
import { Router } from '@angular/router'
import { HotelService } from '../services/hotel.service'
import { GuideService } from '../services/guide.service'
import { ExperiencesService } from '../services/experiences.service'
import { MealsService } from '../services/meals.service'
import { AlertDailogComponent } from '../alert-dailog/alert-dailog.component';



@Component({
  selector: 'app-change-language',
  templateUrl: './change-language.component.html',
  styleUrls: ['./change-language.component.css']
})
export class ChangeLanguageComponent implements OnInit {
    org_langs
    translationsLabels
    selectedLang
    passDATA

    tourListProvider

    //01 : BOC Hrushikesh
    user_pass = {
        email: JSON.parse(localStorage.getItem("UserData")).user.email,
        new_password: '',
        reenter_password: '',
        org_id:''
      }
      //EOC HRUSHIKESH
    message: any;
    userinfo: any;

    constructor( @Inject(MAT_DIALOG_DATA) public data: any, public authService: AuthenticationService,
        public tourService: TourService,public hotelService: HotelService, public GuideService: GuideService
        , public experiencesService: ExperiencesService, public mealsService: MealsService, private router: Router,
        private matDialog: MatDialog,
        private location: Location,
    )

    {
        //01 : BOC Hrushikesh
        
        this.passDATA=data
        //EOC HRUSHIKESH
        var userinfo= JSON.parse(localStorage.getItem("UserData"))
         this.userinfo = JSON.parse(localStorage.getItem("UserData"))
        
        var companyinfo = JSON.parse(localStorage.getItem("companyProvider"))

        this.translationsLabels = userinfo.user.translations
        this.org_langs = userinfo.user.org_languages

      

        this.selectedLang =localStorage.getItem("displayed_language");

       


        this.tourListProvider = JSON.parse(localStorage.getItem("tourListProvider"))

        // BOC Erica (06)
        var index = (localStorage.getItem('INDEX_WELCOME'))

        setTimeout(() => {
            if (this.tourListProvider[index].brand.color != undefined) {
           

                $('#custommenu').text('app-header .bbb svg{fill:'+ this.tourListProvider[index].brand.color + ' !important; stroke-opacity:0 !important}'+
              'app-header .bbb svg rect{fill:'+ this.tourListProvider[index].brand.color +' !important ; stroke-opacity:0 !important}'+
                'app-header .bbb svg polygon{fill:'+ this.tourListProvider[index].brand.color+ ' !important ; stroke-opacity:0 !important}'+
               'app-header .bbb svg path{fill:'+ this.tourListProvider[index].brand.color +' !important; stroke-opacity:0 !important}'+
               'app-header .bbb svg path:nth-of-type(n){fill:'+ this.tourListProvider[index].brand.color +' !important; stroke-opacity:0 !important}'+
               'app-header .bbb svg path:nth-of-type(1){fill:'+ this.tourListProvider[index].brand.color +' !important; stroke-opacity:0 !important}'+
               'app-header .bbb svg path:nth-of-type(2){fill:'+ this.tourListProvider[index].brand.color +' !important; stroke-opacity:0 !important}'+
               'app-header .bbb svg path:nth-of-type(3){fill:'+ this.tourListProvider[index].brand.color +' !important; stroke-opacity:0 !important}'+
            
              
              
               'app-header .ccc svg{fill:white !important; stroke-opacity:0 !important}'+
               'app-header .ccc svg rect{fill:white !important; stroke-opacity:0 !important}'+
               'app-header .ccc svg polygon:nth-of-type(n){fill:white !important; stroke-opacity:0 !important}'+
               'app-header .ccc svg polygon:nth-of-type(1){fill:white !important; stroke-opacity:0 !important}'+
               'app-header .ccc svg path:nth-of-type(1){fill:white !important; stroke-opacity:0 !important}'+
               'app-header .ccc svg path:nth-of-type(2){fill:white !important; stroke-opacity:0 !important}'+
               'app-header .ccc svg path:nth-of-type(3){fill:white !important; stroke-opacity:0 !important}'+
               'app-header .ccc svg path:nth-of-type(n){fill:white !important; stroke-opacity:0 !important}'+
                 'app-header .ccc svg polygon{fill:white !important; stroke-opacity:0 !important}'+
                'app-header .ccc svg path{fill:white !important; stroke-opacity:0 !important}'+
                'app-change-language  .popup_button {background:'+ this.tourListProvider[index].brand.color +' !important; }'

                
            
            
                );
            } else if(this.tourListProvider[index].brand.color == undefined) {
             


                $('#custommenu').text('app-header .bbb svg{fill:'+ JSON.parse(localStorage.getItem("primaryColor")) + ' !important; stroke-opacity:0 !important}'+
              'app-header .bbb svg rect{fill:'+ JSON.parse(localStorage.getItem("primaryColor")) +' !important ; stroke-opacity:0 !important}'+
                'app-header .bbb svg polygon{fill:'+ JSON.parse(localStorage.getItem("primaryColor"))+ ' !important ; stroke-opacity:0 !important}'+
               'app-header .bbb svg path{fill:'+ JSON.parse(localStorage.getItem("primaryColor")) +' !important; stroke-opacity:0 !important}'+
               'app-header .bbb svg path:nth-of-type(n){fill:'+ JSON.parse(localStorage.getItem("primaryColor")) +' !important; stroke-opacity:0 !important}'+
               'app-header .bbb svg path:nth-of-type(1){fill:'+ JSON.parse(localStorage.getItem("primaryColor")) +' !important; stroke-opacity:0 !important}'+
               'app-header .bbb svg path:nth-of-type(2){fill:'+ JSON.parse(localStorage.getItem("primaryColor")) +' !important; stroke-opacity:0 !important}'+
               'app-header .bbb svg path:nth-of-type(3){fill:'+ JSON.parse(localStorage.getItem("primaryColor")) +' !important; stroke-opacity:0 !important}'+
            
              
              
               'app-header .ccc svg{fill:white !important; stroke-opacity:0 !important}'+
               'app-header .ccc svg rect{fill:white !important; stroke-opacity:0 !important}'+
               'app-header .ccc svg polygon:nth-of-type(n){fill:white !important; stroke-opacity:0 !important}'+
               'app-header .ccc svg polygon:nth-of-type(1){fill:white !important; stroke-opacity:0 !important}'+
               'app-header .ccc svg path:nth-of-type(1){fill:white !important; stroke-opacity:0 !important}'+
               'app-header .ccc svg path:nth-of-type(2){fill:white !important; stroke-opacity:0 !important}'+
               'app-header .ccc svg path:nth-of-type(3){fill:white !important; stroke-opacity:0 !important}'+
               'app-header .ccc svg path:nth-of-type(n){fill:white !important; stroke-opacity:0 !important}'+
                 'app-header .ccc svg polygon{fill:white !important; stroke-opacity:0 !important}'+
                'app-header .ccc svg path{fill:white !important; stroke-opacity:0 !important}'+
                'app-change-language  .popup_button {background:'+ JSON.parse(localStorage.getItem("primaryColor")) +' !important; }'

            
            
                );

                

                // $('#Header').text('app-header .bbb svg{fill:' + JSON.parse(localStorage.getItem("primaryColor")) + ' !important;}'
            
            
                // );
            }
            }, 100);
             // EOC Erica (06)


    }

  ngOnInit() {
      
   
  }


  clickedLang(lang) {
      this.selectedLang = lang
      // this.checkedLang=lang
  }
//01 : BOC Hrushikesh
sendOldAndNewPasswords() {

    this.user_pass = {
        "email": this.user_pass.email,
        "new_password": this.user_pass.new_password,
        "reenter_password": this.user_pass.reenter_password,
        "org_id": this.userinfo.user.org_id
    }

    if (this.user_pass.new_password == undefined || this.user_pass.new_password.length < 4) {


        this.message = this.translationsLabels.password_less_char,
            // this.hideSpinner = true
            this.openDialog()
    } else {
        this.authService.resetPass(this.user_pass)

    }
}
//EOC HRUSHIKESH


openDialog() {
    
    
    const dialogRef = this.matDialog.open(AlertDialogDynamicComponent, {
        data: { message: this.message }
    });

    dialogRef.afterClosed().subscribe(result => {
      
    });
}



  changeLang(changeTxt) {

      if (changeTxt != 'cancel' &&
       this.selectedLang != window.localStorage.getItem("selected_language_by_user")) {
        
     
          window.localStorage.setItem("selected_language_by_user", this.selectedLang)
          this.authService.changelanguage(data => {
        
              this.Refresh() 

          });



      } else {
     
 
      }

  }


  Refresh() {
    
 
  

      this.tourService.show(data => {
          

          this.hotelService.all(data => {

        

          });

          this.experiencesService.all(data => {

       

          });


          this.mealsService.all(data => {

         
          });

          this.GuideService.all(data => {

         

          });

          //This is proper
        //  this.router.navigate(['home'])

        this.check_home_desc_pdf();
      });


     


  }


  check_home_desc_pdf(){

  
        
        

    if (localStorage.getItem('IsHomeDescPDF') == 'true') {

      
        this.router.navigate(['home-desc-pdf'])

    } else {
      
        this.router.navigate(['home'])
    }

    //the trick
    window.location.reload();
}


}
