import { DocumentService } from './../services/document.service';
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthenticationService } from '../services/authentication.service'
import { Router } from '@angular/router'

import { DomSanitizer,SafeHtml  } from "@angular/platform-browser";
import * as $ from 'jquery';


@Component({
  selector: 'app-delete-document',
  templateUrl: './delete-document.component.html',
  styleUrls: ['./delete-document.component.css']
})
export class DeleteDocumentComponent implements OnInit {

  passDATA

  userinfo

  translationsLabels

  tourListProvider

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
  public DomSanitizer:DomSanitizer, public authService: AuthenticationService,
       private router: Router,
      private matDialog: MatDialog,public AuthenticationService:AuthenticationService,
      private documentService:DocumentService,
      private dialogRef: MatDialogRef<DeleteDocumentComponent>)  { 

        this.passDATA=data

        this.doc_id=this.passDATA.message

        this.userinfo = JSON.parse(localStorage.getItem("UserData"))
        
        var companyinfo = JSON.parse(localStorage.getItem("companyProvider"))

        this.translationsLabels = this.userinfo.user.translations


        this.tourListProvider = JSON.parse(localStorage.getItem("tourListProvider"))

        // BOC Erica (06)
        var index = (localStorage.getItem('INDEX_WELCOME'))

        setTimeout(() => {
            if (this.tourListProvider[index].brand.color != undefined) {

                $('#custommenu').text('app-header .bbb svg{fill:'+ this.tourListProvider[index].brand.color + ' !important; stroke-opacity:0 !important}'+
              'app-header .bbb svg rect{fill:'+ this.tourListProvider[index].brand.color +' !important ; stroke-opacity:0 !important}'+
                'app-header .bbb svg polygon{fill:'+ this.tourListProvider[index].brand.color+ ' !important ; stroke-opacity:0 !important}'+
               'app-header .bbb svg path{fill:'+ this.tourListProvider[index].brand.color +' !important; stroke-opacity:0 !important}'+
               'app-header .bbb svg path:nth-of-type(n){fill:'+ this.tourListProvider[index].brand.color +' !important; stroke-opacity:0 !important}'+
               'app-header .bbb svg path:nth-of-type(1){fill:'+ this.tourListProvider[index].brand.color +' !important; stroke-opacity:0 !important}'+
               'app-header .bbb svg path:nth-of-type(2){fill:'+ this.tourListProvider[index].brand.color +' !important; stroke-opacity:0 !important}'+
               'app-header .bbb svg path:nth-of-type(3){fill:'+ this.tourListProvider[index].brand.color +' !important; stroke-opacity:0 !important}'+
            
              
              
               'app-header .ccc svg{fill:white !important; stroke-opacity:0 !important}'+
               'app-header .ccc svg rect{fill:white !important; stroke-opacity:0 !important}'+
               'app-header .ccc svg polygon:nth-of-type(n){fill:white !important; stroke-opacity:0 !important}'+
               'app-header .ccc svg polygon:nth-of-type(1){fill:white !important; stroke-opacity:0 !important}'+
               'app-header .ccc svg path:nth-of-type(1){fill:white !important; stroke-opacity:0 !important}'+
               'app-header .ccc svg path:nth-of-type(2){fill:white !important; stroke-opacity:0 !important}'+
               'app-header .ccc svg path:nth-of-type(3){fill:white !important; stroke-opacity:0 !important}'+
               'app-header .ccc svg path:nth-of-type(n){fill:white !important; stroke-opacity:0 !important}'+
                 'app-header .ccc svg polygon{fill:white !important; stroke-opacity:0 !important}'+
                'app-header .ccc svg path{fill:white !important; stroke-opacity:0 !important}'+
                'app-delete-document  .popup_button {background:'+ this.tourListProvider[index].brand.color +' !important; }'

                
            
            
                );
            } else if(this.tourListProvider[index].brand.color == undefined) {
                console.log("Entry else", JSON.parse(localStorage.getItem("primaryColor")))


                $('#custommenu').text('app-header .bbb svg{fill:'+ JSON.parse(localStorage.getItem("primaryColor")) + ' !important; stroke-opacity:0 !important}'+
              'app-header .bbb svg rect{fill:'+ JSON.parse(localStorage.getItem("primaryColor")) +' !important ; stroke-opacity:0 !important}'+
                'app-header .bbb svg polygon{fill:'+ JSON.parse(localStorage.getItem("primaryColor"))+ ' !important ; stroke-opacity:0 !important}'+
               'app-header .bbb svg path{fill:'+ JSON.parse(localStorage.getItem("primaryColor")) +' !important; stroke-opacity:0 !important}'+
               'app-header .bbb svg path:nth-of-type(n){fill:'+ JSON.parse(localStorage.getItem("primaryColor")) +' !important; stroke-opacity:0 !important}'+
               'app-header .bbb svg path:nth-of-type(1){fill:'+ JSON.parse(localStorage.getItem("primaryColor")) +' !important; stroke-opacity:0 !important}'+
               'app-header .bbb svg path:nth-of-type(2){fill:'+ JSON.parse(localStorage.getItem("primaryColor")) +' !important; stroke-opacity:0 !important}'+
               'app-header .bbb svg path:nth-of-type(3){fill:'+ JSON.parse(localStorage.getItem("primaryColor")) +' !important; stroke-opacity:0 !important}'+
            
              
              
               'app-header .ccc svg{fill:white !important; stroke-opacity:0 !important}'+
               'app-header .ccc svg rect{fill:white !important; stroke-opacity:0 !important}'+
               'app-header .ccc svg polygon:nth-of-type(n){fill:white !important; stroke-opacity:0 !important}'+
               'app-header .ccc svg polygon:nth-of-type(1){fill:white !important; stroke-opacity:0 !important}'+
               'app-header .ccc svg path:nth-of-type(1){fill:white !important; stroke-opacity:0 !important}'+
               'app-header .ccc svg path:nth-of-type(2){fill:white !important; stroke-opacity:0 !important}'+
               'app-header .ccc svg path:nth-of-type(3){fill:white !important; stroke-opacity:0 !important}'+
               'app-header .ccc svg path:nth-of-type(n){fill:white !important; stroke-opacity:0 !important}'+
                 'app-header .ccc svg polygon{fill:white !important; stroke-opacity:0 !important}'+
                'app-header .ccc svg path{fill:white !important; stroke-opacity:0 !important}'+
                'app-delete-document  .popup_button {background:'+ JSON.parse(localStorage.getItem("primaryColor")) +' !important; }'

            
            
                );

                

                // $('#Header').text('app-header .bbb svg{fill:' + JSON.parse(localStorage.getItem("primaryColor")) + ' !important;}'
            
            
                // );
            }
            }, 100);
             // EOC Erica (06)


      }

  ngOnInit() {
  }

  doc_id
  deleteDoc(){

    


    this.documentService.deleteuploadedDocuments(this.doc_id,  (data) => {
      console.log("Getting user name from cms:", data)
      if (data.status == true) {


        this.dialogRef.close(  );

      
        
      //  message: LSDuserinfoProvider.translations.doc_delete_success,
         
         
          // for (let j = 0; j < this.docArr.length; j++) {
          //   if (this.docArr[j].id == this.docid) {
          //     this.docArr.splice(j, 1)
          //   }
          // }

       
        //EOC Erica (307)

        this.router.navigate(['document-list']);


      }


    })


  }

}
