﻿import { Injectable } from '@angular/core';
import { Restangular } from 'ngx-restangular';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router'

import { AppUrls} from '../app-storagekeys-urls'

import { DataServiceService } from '../services/data-service.service'

@Injectable({
  providedIn: 'root'
})
export class HotelService {
  Urls = AppUrls()
  currentUserData
  restHeader
  constructor(private restangular: Restangular, private http: HttpClient, private router:Router,
    private dataService:DataServiceService) {
    console.log("Coming")
  }



  //all(callback) {
  //    var itinery_id = localStorage.getItem('LStourListId');
  //    console.log('getting tourlistId in Hotels Provider : ', itinery_id)
  //    this.restangular.withConfig((RestangularConfigurer) => {
  //        RestangularConfigurer.setDefaultHeaders({
  //            'Content-Type': 'application/json',
  //            'Authorization': localStorage.getItem('restHeader')
  //        });
  //    }).one(this.subUrl).get({ itinerary_id: itinery_id }).toPromise().then(response => {
  //        console.log("Load:: 7: ", response);
  //        callback(response.hotels);
  //    }, (err) => {
  //        console.log("Load:x: 7: ", err);
  //        console.log('geting err in hotels provider : ', err)
  //        callback(err);
  //    })
  //}




  all(callback) {

      var id = localStorage.getItem("LStourListId");
      if ((localStorage.getItem("UserData")) != null) {
          this.currentUserData = JSON.parse(localStorage.getItem("UserData"));
      }

      var user_language = "";
      if (window.localStorage.getItem("selected_language_by_user") == null) {
          user_language = this.currentUserData.user.language;
      } else {
          user_language = window.localStorage.getItem("selected_language_by_user");
      }


      this.restHeader = this.currentUserData.user.email + ':' + this.currentUserData.token + ':' + user_language

      console.log("restHeader",this.restHeader)

      this.restangular.withConfig((RestangularConfigurer) => {
          console.log("COMING TO REST ANGULAR ")
          RestangularConfigurer.setDefaultHeaders({
              'Content-Type': 'application/json',
              'Authorization': this.restHeader
          });
      }).one(this.Urls.hotellist).get({itinerary_id: id}).toPromise().then((response) => {

          console.log("Load:: Hotel: ", response);
          localStorage.setItem("LSDHotellistdata", JSON.stringify(response.hotels));
          callback(response)
     
         
      }, (err) => {
          console.log("ERROR:x: 1: ", err);
         

          //nav.setRoot('SignIn')// Commenting this did not solve the refresh bug
      });


  }



  show(callback) {
      // Getting itineries data of current booking organization
      var id = localStorage.getItem("LStourListId");

      if ((localStorage.getItem("UserData")) != null) {
          this.currentUserData = JSON.parse(localStorage.getItem("UserData"));
      }

      var user_language = "";
      if (window.localStorage.getItem("selected_language_by_user") == null) {
          user_language = this.currentUserData.user.language;
      } else {
          user_language = window.localStorage.getItem("selected_language_by_user");
      }


      this.restHeader = this.currentUserData.user.email + ':' + this.currentUserData.token + ':' + user_language

   



      this.restangular.withConfig((RestangularConfigurer) => {
          RestangularConfigurer.setDefaultHeaders({
              'Content-Type': 'application/json',
              'Authorization': this.restHeader
          });
      }).one(this.Urls.TourShow).get({
          itinerary_id: id
      }).toPromise().then(function (response) {
          localStorage.setItem("LSDitinerarydata", JSON.stringify(response));
          callback(response);
      }, function (response) {

      });

  }



}
