﻿import { TineriTipComponent } from './../tineri-tip/tineri-tip.component';
import { TineriTipService } from './../services/tineri-tip.service';
//01: HRUSHIKESH 28-May-2020 Organization color change
//(02) : ERICA: Content Data open in seperate tab
import { Component, OnInit } from '@angular/core';

import { ActivatedRoute, Router } from '@angular/router';
import { TourService } from '../services/tour.service'
import { HotelService } from '../services/hotel.service'

import * as moment from 'moment';
import { MatDialog } from '@angular/material/dialog';
import * as $ from 'jquery';





import { GuideService } from '../services/guide.service'
import { ExperiencesService } from '../services/experiences.service'
import { MealsService } from '../services/meals.service'



@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})



export class HomeComponent implements OnInit {
    tourlistid
    itinerarydata
    formatDate
    translationsLabels
    traveller
    logo

    animal: string;
    name: string;
    tourListProvider

    content_description //(02)

    show_scroll_up_btn=true;

    index_from_welcome
    Itineraryid

     //Erica keep a variable with color
     current_color="#000000"

     //header Image
     header_img


    constructor(private router: Router, 
      private route: ActivatedRoute,
       public tourService: TourService, 
       public hotelService: HotelService,
        public matDialog: MatDialog,//Added by Erica
        protected tineriTipService:TineriTipService,//Added by Erica
       
         public GuideService: GuideService
        , public experiencesService: ExperiencesService,
         public mealsService: MealsService,

        ) {



        this.translationsLabels=""
        var userinfo = JSON.parse(localStorage.getItem("UserData"))

        //Erica: If user is logged out then user should be navigated to login page on using direct urls
        
        // if(userinfo == null){
        //     this.router.navigate([''])
        // }

        var companyinfo = JSON.parse(localStorage.getItem("companyProvider"))

        this.logo = companyinfo.logo
        this.traveller = userinfo.user.fullname

        this.translationsLabels = JSON.parse(localStorage.getItem("UserData")).user.translations
        
        this.tourListProvider = JSON.parse(localStorage.getItem("tourListProvider"))
      

        var index = (localStorage.getItem('INDEX_WELCOME'))

        this.Itineraryid =localStorage.getItem("LStourListId")

        this.index_from_welcome=index
    
    
        if (this.tourListProvider[index].brand.length == undefined) {
     
            $('#extraCSS').text(
                'a {color:' + this.tourListProvider[index].brand.color + ' !important;}'
              
    
            );
        } else {
      
            $('#extraCSS').text(
                'a {color:' + JSON.parse(localStorage.getItem("primaryColor")) + ' !important;}'
               
              
            );
        }

        this.refresh_color()

        setTimeout(()=>{this.check_height()},100) 


        $(".fontnormal a").each(function (index, el) {

            $(this).click(function (event) {

              if ($(this).attr('href') == "#") {
                
                event.preventDefault();
              }
            })
          });

    }

    

      check_height(){

       
          if($(window).height()>= $(".mainContainer").height()+250){
            this.show_scroll_up_btn=false;
          }else if($(window).height()< $(".mainContainer").height()+250){
            this.show_scroll_up_btn=true;
          }
    
         
        }
  
     

    refresh_color(){
      setTimeout(()=>{

        this.callHome(this.Itineraryid, this.index_from_welcome)
        this.tourListProvider = JSON.parse(localStorage.getItem("tourListProvider"))
      

        var index = (localStorage.getItem('INDEX_WELCOME'))
     
    
        if (this.tourListProvider[index].brand.length == undefined) {
         
            $('#extraCSS').text(
                '.fontnormal a {color:' + this.tourListProvider[index].brand.color + ' !important;}'
    
            );
        } else {
         
            $('#extraCSS').text(
                '.fontnormal a {color:' + JSON.parse(localStorage.getItem("primaryColor")) + ' !important;}'
            );
        }
      },500)
    }


 
   

  ngOnInit() {
      localStorage.setItem("showheader", "1") 


      var index = (localStorage.getItem('INDEX_WELCOME'))
    
  
      if (this.tourListProvider[index].brand.length == undefined) {
       
          $('#extraCSS').text(
              '.fontnormal a {color:' + this.tourListProvider[index].brand.color + ' !important;}'

  
          );
          this.current_color=this.tourListProvider[index].brand.color

      } else {
        
          $('#extraCSS').text(
              '.fontnormal a {color:' + JSON.parse(localStorage.getItem("primaryColor")) + ' !important;}'
          );

          this.current_color=JSON.parse(localStorage.getItem("primaryColor"))

      }

      
  

      this.tourlistid = localStorage.getItem("LStourListId")
     
      if (localStorage.getItem("LSDitinerarydata") == null ||
       localStorage.getItem("LSDitinerarydata") == undefined) {
          this.loaddata()
      } else
      {
          this.renderdata()

      
      }
      
  }

  
  renderdata()
  {



          this.itinerarydata = JSON.parse(localStorage.getItem("LSDitinerarydata")).itinerary

          this.header_img = this.itinerarydata.imgSrc


          this.content_description= this.itinerarydata.description

          this.modify_content_description();

          var IDate

          var StartDate = this.itinerarydata.dateStart;


          var EndDate = this.itinerarydata.dateEnd;

          let monthS = this.itinerarydata.iti_start_month;
          let monthE = this.itinerarydata.iti_end_month;

          let YearS = this.itinerarydata.iti_start_year;
          let YearE = this.itinerarydata.iti_end_year;

          var dStart
          var dEnd

          if (monthS == monthE) {
              dStart = moment(StartDate).format("DD");
              dEnd = moment(EndDate).format("DD MMMM YYYY");
              let monthName = dEnd.split(' ')[1];
              dEnd = dEnd.replace(monthName, this.translationsLabels[monthName.toLowerCase()]);

              IDate = dStart + "-" + dEnd
           

          } else {
              dStart = moment(StartDate).format("DD MMMM");
              let monthNameStart = dStart.split(' ')[1];
              dStart = dStart.replace(monthNameStart, this.translationsLabels[monthNameStart.toLowerCase()]);

              dEnd = moment(EndDate).format("DD MMMM YYYY");
              let monthName = dEnd.split(' ')[1];
              dEnd = dEnd.replace(monthName, this.translationsLabels[monthName.toLowerCase()]);

              IDate = dStart + "-" + dEnd

          }


          if (YearS != YearE) {
              dStart = moment(StartDate).format("DD MMMM YYYY");
              let monthNameStart = dStart.split(' ')[1];
              dStart = dStart.replace(monthNameStart, this.translationsLabels[monthNameStart.toLowerCase()]);

              dEnd = moment(EndDate).format("DD MMMM YYYY");
              let monthName = dEnd.split(' ')[1];
              dEnd = dEnd.replace(monthName, this.translationsLabels[monthName.toLowerCase()]);

              IDate = dStart + "-" + dEnd
          }




      
          this.itinerarydata.formatDate = IDate;





  }

  loaddata()
  {

    
          this.tourService.show(data => {

        
              this.itinerarydata = data.itinerary

              this.content_description= this.itinerarydata.description

              this.modify_content_description();



              var IDate

              var StartDate = this.itinerarydata.dateStart;


              var EndDate = this.itinerarydata.dateEnd;

              let monthS = this.itinerarydata.iti_start_month;
              let monthE = this.itinerarydata.iti_end_month;

              let YearS = this.itinerarydata.iti_start_year;
              let YearE = this.itinerarydata.iti_end_year;

              var dStart
              var dEnd

              if (monthS == monthE) {
                  dStart = moment(StartDate).format("DD");
                  dEnd = moment(EndDate).format("DD MMMM YYYY");
                  let monthName = dEnd.split(' ')[1];
                  dEnd = dEnd.replace(monthName, this.translationsLabels[monthName.toLowerCase()]);

                  IDate = dStart + "-" + dEnd
              

              } else {
                  dStart = moment(StartDate).format("DD MMMM");
                  let monthNameStart = dStart.split(' ')[1];
                  dStart = dStart.replace(monthNameStart, this.translationsLabels[monthNameStart.toLowerCase()]);

                  dEnd = moment(EndDate).format("DD MMMM YYYY");
                  let monthName = dEnd.split(' ')[1];
                  dEnd = dEnd.replace(monthName, this.translationsLabels[monthName.toLowerCase()]);

                  IDate = dStart + "-" + dEnd

              }


              if (YearS != YearE) {
                  dStart = moment(StartDate).format("DD MMMM YYYY");
                  let monthNameStart = dStart.split(' ')[1];
                  dStart = dStart.replace(monthNameStart, this.translationsLabels[monthNameStart.toLowerCase()]);

                  dEnd = moment(EndDate).format("DD MMMM YYYY");
                  let monthName = dEnd.split(' ')[1];
                  dEnd = dEnd.replace(monthName, this.translationsLabels[monthName.toLowerCase()]);

                  IDate = dStart + "-" + dEnd
              }




         
              this.itinerarydata.formatDate = IDate;


              this.loaddetials()



          });

   
  }

  loaddetials() {

    

        this.hotelService.all(data => {

          
        });

        }

  modify_content_description(){

    

    var tipsFactory = this.tineriTipService
    var matdialogscope =this.matDialog;

      this.content_description 
      if(this.content_description !=undefined){

        var str =  this.content_description ;
        
        this.content_description  =  this.content_description.replace(/<a href/g, '<u><a href');
        this.content_description  =  this.content_description.replace(/<a href="#"/g, '</u><a href="#" ');
        this.content_description  =  this.content_description.replace(/<\/a>/g, '</a></u>');
         this.content_description  =  this.content_description.replace(/<a href/g, '<a target="_blank" href');

               


 //  var newStr = this.content_description.replace(/<a/g, "<a   target='_blank' style='border-bottom: 0.5px solid;' ");
        

        // var step2 = str.replace(/data-compare/g, " style='border-bottom: 0.5px solid green;'  data-compare");
        
        
    
        //   this.content_description = step2;



        

        setTimeout(() => {

         

            $(".fontnormal a").each(function (index, el) {

            

            
              $(this).css('color',  localStorage.getItem("primaryColor"));
              if ($(this).attr('href') == "#") {
                 $(this).css('text-decoration', 'none');

              }
            //   $(this).css('text-decoration', 'none');
              $(this).click(function (event) {

               

    
      
      
                if ($(this).attr('href') == "#") {
                  

                  event.preventDefault();
      
               
                   {
      
                  
      
                    //After Tineri Tip CR
                    {
                   
      
                     var hashTagId = $(this).data("compare")

                    
                    var display_name = $(this).text()

      
                   
                    }
      
                  
                   
                    {


      
                      //---------------When offline show tineri tips from localstorage---------------------------
                      var LSDtipsListoffline = JSON.parse(window.localStorage.getItem('LSDItinerarytipsList'))
      

      

                      var tipsdaytrip = LSDtipsListoffline;

                   

                      
                      tipsdaytrip.forEach(tipValue => {
      
                        var tipname = tipValue.name
                        var tipidcms = tipValue.id
      
                        if (tipname == hashTagId) {
                          // var content = tipValue.content;


                          let dialogRef = matdialogscope.open(TineriTipComponent, {
                            data: {
                                tineriTip: tipValue,
                                id:hashTagId,
                                display_name:display_name
                            }
                        });


      
                         
      
                         
                         
                        }
                      });
      
      
                      //---------------When offline show tineri tips from localstorage CLOSE---------------------------
                    }
      
                  }
                  
                }
      
              })
            });
      
          }, 1000);



      }
  }


  callHome(Itineraryid,index)
  {

     



     


      this.tourService.show(data => {

          // BOC Erica (01)
        
        localStorage.setItem("display_feature",JSON.stringify(data.itinerary.display_feature));

        // EOC Erica (01)

        localStorage.setItem("itinerary_data",JSON.stringify(data.itinerary));// Added by  Erica

        // EOC Erica (01)
         

         

          this.hotelService.all(data => {
    
          
          });

          this.experiencesService.all(data => {

          
          });


          this.mealsService.all(data => {
       
           
          });

          this.GuideService.all(data => {
             
           

          });

          this.udfTipsOFFline()

         
      });

  



  }

  udfTipsOFFline() {

    
    this.tourService.TipsFactoryAll((data) => {
        if (data != 'error') {
        


            if (data != undefined &&
                data != null &&
                data.day_trip_hash_tag != undefined &&
                data.day_trip_hash_tag.length != 0) {

                //------------------TIPS FOR TRIP -------------------------------------
                var tripHashTagArry = [];
                var countDayTrip = 0;
                 tripHashTagArry = data.day_trip_hash_tag
                 window.localStorage.setItem('LSDtipsList', JSON.stringify(tripHashTagArry))

            } else if (data == undefined ||
                data == null ||
                data.day_trip_hash_tag == undefined ||
                data.day_trip_hash_tag.length == 0) {
              

            }

            //------------------TIPS FOR ITINERARY -------------------------------------


            let itineraryTipsArry = [];
            if (data != undefined &&
                data != null &&
                data.itinerary_hash_tag != undefined &&
                data.itinerary_hash_tag.length != 0) {




                {
                    if (data.itinerary_hash_tag != "no hash tags for itinerary") {

                        let countIti = 0;
                        itineraryTipsArry = data.itinerary_hash_tag
                        window.localStorage.setItem('LSDItinerarytipsList', JSON.stringify(itineraryTipsArry));
                    }
                }

            } else if (data == undefined ||
                data == null ||
                data.itinerary_hash_tag == undefined ||
                data.itinerary_hash_tag.length == 0) {

            }
        }


        //------------------TIPS FOR GUIDES -------------------------------------
        if (data != undefined &&
            data != null &&
            data.guides_hash_tag != undefined &&
            data.guides_hash_tag.length != 0) {
            {
                let guideTipsArry = [];
                let countGuidesHash = 0;
                guideTipsArry = data.guides_hash_tag
                window.localStorage.setItem('LSDGuidestipsList', JSON.stringify(guideTipsArry));

            }
        } else if (data == undefined ||
            data == null ||
            data.guides_hash_tag == undefined ||
            data.guides_hash_tag.length == 0) {
        }



        //------------------TIPS FOR HOTELS -------------------------------------

        if (data != undefined &&
            data != null &&
            data.hotels_hash_tag != undefined &&
            data.hotels_hash_tag.length != 0) {
            {
                let hotelTipsArry = [];
                let countHotelsHash = 0;
                hotelTipsArry = data.hotels_hash_tag
                window.localStorage.setItem('LSDHotelstipsList', JSON.stringify(hotelTipsArry));

            }
        } else if (data == undefined ||
            data == null ||
            data.hotels_hash_tag == undefined ||
            data.hotels_hash_tag.length == 0) {
           
        }



        //--------------------------- Tip List Experiences ------------------------------------


        if (data != undefined &&
            data != null &&
            data.experiences_hash_tag != undefined &&
            data.experiences_hash_tag.length != 0) {
            {
                ExperienceTipsArry = [];
                let countExperiencesHash = 0;
                var ExperienceTipsArry = data.experiences_hash_tag
                window.localStorage.setItem('LSDExperiencetipsList', JSON.stringify(ExperienceTipsArry));

            }
        } else if (data == undefined ||
            data == null ||
            data.experiences_hash_tag == undefined ||
            data.experiences_hash_tag.length == 0) {

        }



        //--------------------------- Tip List Meals ------------------------------------


        if (data != undefined &&
            data != null &&
            data.meals_hash_tag != undefined &&
            data.meals_hash_tag.length != 0) {
            {
                let MealsTipsArry = [];
                let countMealsHash = 0;
                MealsTipsArry = data.meals_hash_tag
                window.localStorage.setItem('LSDMealstipsList', JSON.stringify(MealsTipsArry));

            }
        } else if (data == undefined ||
            data == null ||
            data.meals_hash_tag == undefined ||
            data.meals_hash_tag.length == 0) {

        }





    });

}


scroll_up(){
  
    $(window).scrollTop( $("body").offset().top );
}




}
