﻿import { NgModule } from '@angular/core';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { MatSlideToggleModule} from '@angular/material/slide-toggle';
import { HttpClientModule } from "@angular/common/http";


import {
  MatAutocompleteModule,
  MatInputModule,
  MatRippleModule,
  MatButtonModule,
  MatGridListModule,
  MatToolbarModule,
  MatMenuModule,
  MatIconModule,
  MatStepperModule,
  MatSelectModule,
  MatSidenavModule,
  MatListModule,
  MatRadioModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatCardModule,
  MatBadgeModule,
  MatFormFieldModule,
  MatChipsModule,
  MatTooltipModule,
  MatTableModule,
  MatPaginatorModule,
  MatExpansionModule, MatDialogModule, MatSnackBarModule, MatTabsModule, MatProgressSpinnerModule
  
} from '@angular/material';


@NgModule({
    imports: [
        MatButtonModule,
        MatToolbarModule,
        MatIconModule,
        MatSidenavModule,
        MatBadgeModule,
        MatListModule,
        MatGridListModule,
        MatFormFieldModule,
        MatInputModule,
        MatSelectModule,
        MatRadioModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatChipsModule,
        MatTooltipModule,
        MatTableModule,
        MatPaginatorModule,
        MatCardModule,
        HttpClientModule,
        MatExpansionModule,
        MatSlideToggleModule,
        MatSnackBarModule,
        MatDialogModule, MatTabsModule, MatProgressSpinnerModule,
    ],
  exports: [
    MatAutocompleteModule,
    MatInputModule,
    MatRippleModule,
    MatGridListModule,
    MatToolbarModule,
    MatMenuModule,
    MatButtonModule,
    MatIconModule,
    MatStepperModule,
    MatSelectModule,
      CdkStepperModule,
      MatSidenavModule,
      MatBadgeModule,
      MatListModule,
      MatFormFieldModule,
      MatRadioModule,
      MatDatepickerModule,
      MatChipsModule,
      MatTooltipModule,
      MatTableModule,
      MatPaginatorModule,
      MatCardModule, 
      MatExpansionModule,
      MatSlideToggleModule,
      MatSnackBarModule,
      MatDialogModule,
       MatTabsModule,
        MatProgressSpinnerModule,


      ]
})

export class MaterialModule { }
