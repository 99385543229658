﻿// This is the login page
import { Component, OnInit, Inject, TemplateRef} from '@angular/core';

import { FormBuilder, FormGroup, FormControl, Validators, FormGroupDirective, NgForm} from '@angular/forms';
import { AuthenticationService } from '../services/authentication.service'
import { Router } from '@angular/router'
import { ViewEncapsulation } from '@angular/core';
import { MatDialog} from '@angular/material/dialog';
import { AlertDailogComponent } from '../alert-dailog/alert-dailog.component';
import { SpinnerService } from '../services/spinner.service';
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";
//import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';




@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
  encapsulation: ViewEncapsulation.None

})
export class LoginComponent implements OnInit {

    SigninForm: FormGroup
    message
    ErrorMsg
    hideSpinner = true

    showPassword=false// Erica 

    constructor(fb: FormBuilder,
        private matIconRegistry: MatIconRegistry,
        private domSanitizer: DomSanitizer, 
         public authService: AuthenticationService,
         private router: Router, private spinnerService: SpinnerService,
        private matDialog: MatDialog ) {

        this.SigninForm = fb.group({
            email: new FormControl("", Validators.compose([
                Validators.required,
                Validators.email
            ])),
            password: new FormControl("", Validators.compose([
                Validators.required
            ]))
        });

        this.svgregister();


    }

    tooglepassword(){
        this.showPassword=!this.showPassword
    }


  



    ngOnInit() {
        this.ErrorMsg="There is an Error please check"
    }



    onClickSubmit()
    { }

    login(signinForm) {
        this.hideSpinner = false

       
        if (signinForm.email == '' || signinForm.email == undefined) {

            this.message = "Login Failed: One or more credential fields are empty."
            this.hideSpinner = true
            this.openDialog()
        }
        else if (signinForm.password == '' || signinForm.password == undefined)
        {

            this.message = "Login Failed: One or more credential fields are empty."
            this.hideSpinner = true
            this.openDialog()
        }
        else 
        {
        

          

            this.authService.login(signinForm)

            setTimeout(() => {
                this.hideSpinner = true
            
            }, 2000);

        }

    }


    forgotpassword()
    {
        this.router.navigate(['forgotpassword'])
    }


    openDialog() {
        
     
        const dialogRef = this.matDialog.open(AlertDailogComponent, {
            data: { message: this.message }
        });

        dialogRef.afterClosed().subscribe(result => {
        
        });
    }

    svgregister() {




        this.matIconRegistry.addSvgIcon(
            "eye",
            this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/Images/mixed/eye.svg")
        );

        this.matIconRegistry.addSvgIcon(
            "hide",
            this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/Images/mixed/hide.svg")
        );
        
        

    }




 
}





