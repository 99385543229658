
//01: HRUSHIKESH 28-May-2020 Organization color change
import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { HotelService } from '../services/hotel.service';
import * as $ from 'jquery';
import { TineriTipComponent } from './../tineri-tip/tineri-tip.component';
import { MatDialog } from '@angular/material/dialog';
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";
import { PassSelectedItemService } from './../services/pass-selected-item.service';
import { unwatchFile } from 'fs';

@Component({
  selector: 'app-experiences',
  templateUrl: './experiences.component.html',
  styleUrls: ['./experiences.component.css']
})
export class ExperiencesComponent implements OnInit {


    tourlistid
    itinerarydata
    formatDate
    translationsLabels
    traveller
    logo
    expdata
    leftPos = 0
    tourListProvider: any;
    showMyClass :any[]=[];
    readMore :any[]=[];

    experience
    header_img

    constructor(public hotelService: HotelService,
        public matDialog: MatDialog,//Added by Erica
        private cdr:ChangeDetectorRef, //addedby sneha
        private matIconRegistry: MatIconRegistry,
        private domSanitizer: DomSanitizer,
        private pass_data_service: PassSelectedItemService
        ) {

          this.header_img = JSON.parse(localStorage.getItem("LSDitinerarydata")).itinerary.imgSrc


          this.experience = this.pass_data_service.getData();
          this.pass_data_service.setData(undefined)

          if(this.experience!=undefined){
            console.log("PASSSTTTT             ",this.experience)

            setTimeout(()=>{
               var x = $("#"+this.experience.id).position();
            console.log(" PASSSTTTT  Top: " + x.top + " Left: " + x.left);
  

             window.scrollTo(x.left, x.top+100)

  
          },1000);
          }


            this.svgregister();
   
        var userinfo = JSON.parse(localStorage.getItem("UserData"))
        var companyinfo = JSON.parse(localStorage.getItem("companyProvider"))
        this.translationsLabels = ""
        this.logo = companyinfo.logo
        this.traveller = userinfo.user.fullname

        this.tourListProvider = JSON.parse(localStorage.getItem("tourListProvider"))
        this.translationsLabels = JSON.parse(localStorage.getItem("UserData")).user.translations
        console.log("this.translationsLabels", this.translationsLabels)
        this.renderdata()

        var elementname=localStorage.getItem("Pname")
        console.log("elementname",elementname)
        var scrollINDEX
      
        // var t=9
        // console.log("tar",t)
        
        if(this.expdata!=undefined){
          for(var i=0;i<this.expdata.length;i++){
            if(this.expdata[i].name==elementname)
             {  var j=i+1;
             
           scrollINDEX=300*j
           
         }
     }
     
        }
        
 


        if(localStorage.getItem('Pname')!=undefined && localStorage.getItem('Pname')!=null)
         {
             
        
            setTimeout(() => {
            window.scrollTo(0,scrollINDEX)
            localStorage.removeItem('Pname')
             },300);
            // document.querySelector('#target').scrollIntoView({ behavior: 'smooth', block: 'center' });
        
            // var t=localStorage.getItem('ITEM_INDEX')
            // const elementList = document.querySelectorAll(this.expdata[t]);
            // const element = elementList[0] as HTMLElement;
            // element.scrollIntoView({ behavior: 'smooth' });
            // localStorage.removeItem('ITEM_INDEX')
         }

         this.modify_content_description();
         if(this.expdata!=undefined){
          this.sethidedata(this.expdata.length)
         }
       

         setTimeout(()=>{this.check_height()},100) 

         $(".fontnormal a").each(function (index, el) {

          $(this).click(function (event) {

            if ($(this).attr('href') == "#") {
              
              event.preventDefault();
            }
          })
        });

        }
    
      

          check_height(){

           
              if($(window).height()>= $(".mainContainer").height()+250){
                this.show_scroll_up_btn=false;
              }else if($(window).height()< $(".mainContainer").height()+250){
                this.show_scroll_up_btn=true;
              }
        
            
            }
          scroll_up(){
  
            $(window).scrollTop( $("body").offset().top );
        }
      
          show_scroll_up_btn=true;
    ngOnInit() {
        //01: BOC HRUSHIKESH := Organization color applied if exist . Else company primary color applied
        var index = (localStorage.getItem('INDEX_WELCOME'))
        console.log("Slide index from welcome", index)
        
        if (this.tourListProvider[index].brand.length == undefined) {
            console.log("TEST IS ON", this.tourListProvider[index].brand.color)
            $('#extraCSS').text(
                'a {color:' + this.tourListProvider[index].brand.color + ' !important;}'
        
            );
        } else {
            console.log("Entry else", JSON.parse(localStorage.getItem("primaryColor")))
            $('#extraCSS').text(
                'a {color:' + JSON.parse(localStorage.getItem("primaryColor")) + ' !important;}'
            );
        }
        //EOC HRUSHIKESH
    }
    svgregister() {

   


        this.matIconRegistry.addSvgIcon(
            "phone",
            this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/Images/mixed/phone.svg")
        );
      }
    renderdata() {


      if(localStorage.getItem("LSDExplistdata")!=undefined){
        this.itinerarydata = JSON.parse(localStorage.getItem("LSDitinerarydata")).itinerary
        this.expdata = JSON.parse(localStorage.getItem("LSDExplistdata"))


        console.log("i am in", this.expdata)
        var day_trip_length = this.itinerarydata.dayTrips.length
        if (this.expdata.length != 0) {
         
            for (let a = 0; a < this.expdata.length; a++) {

                 var description = this.expdata[a].description
  
                description.replace(/<a/g,  "<a  target='_blank' ")

                // this.expdata[a].description=description.replace(/<a/g,  "<a  target='_blank' ")


                this.expdata[a].description =  this.expdata[a].description.replace(/<a href/g, '<u><a href');
                this.expdata[a].description =   this.expdata[a].description.replace(/<a href="#"/g, '</u><a href="#" ');
                this.expdata[a].description =   this.expdata[a].description.replace(/<\/a>/g, '</a></u>');
                this.expdata[a].description =  this.expdata[a].description.replace(/<a href/g, '<a target="_blank" href');


                if(this.expdata[a].pdf_description!= undefined){
                  this.expdata[a].pdf_description_iframe = this.domSanitizer.bypassSecurityTrustResourceUrl( this.expdata[a].pdf_description);
          
              }


                console.log("this.hoteldata.length", a)
                this.expdata[a].localImageArray = []
                this.expdata[a].days = '';
                if (this.expdata.imageArray != 0) {
                    for (let b = 0; b < this.expdata[a].imageArray.length; b++) {

                        console.log("this.hoteldata[a].imageArray", a, "::", b)

                        this.expdata[a].localImageArray.push(this.expdata[a].imageArray[b])
                    }

                    for (let c = 0; c < this.expdata[a].days_applicable.length; c++) {


                        this.expdata[a].days = this.expdata[a].days + this.expdata[a].days_applicable[c]
                    }
                }

                if (a <= this.expdata.length) {
                    this.expdata[a].localImageArray.unshift(this.expdata[a].image)
                   
                }





            }








        }

      }

      


    }

    ngAfterViewInit() {
    
      if(this.expdata!=undefined){
        this.expdata.forEach((data, index)=> {
            //198 is the height declared of the block
            if(data.description && data.pdf_description == undefined){
              var desc_height=307-document.getElementById("exp_details"+index).offsetHeight
             var desc= document.getElementById("description"+index).offsetHeight
             if(desc<desc_height){
                this.readMore[index]=false
              }else{
                if (document.getElementById("day_numbers"+index).offsetWidth<500){
                  $("#description_box"+index).css("height","300px")
                }
                else{
                $("#description_box"+index).css("height",desc_height+"px")
                }
              }
                // var perimeter=2*(document.getElementById("description"+index).offsetHeight +document.getElementById("description"+index).offsetWidth)   
                // if(perimeter<1480){
                // this.readMore[index]=false
                // }
            }
        } );
      }
        this.cdr.detectChanges()
      }
      sethidedata(len){
        
        for (var i = 0; i < len; i++) {
            this.showMyClass.push(true);
            this.readMore.push(true);
    
          }
      }
      showMore(i) {
        this.showMyClass[i] = !this.showMyClass[i];
        if (this.showMyClass[i]==false){
          this.expdata[i].description_height=document.getElementById("description_box"+i).offsetHeight
          $("#description_box"+i).css("height","auto")
        }else{
          
          var desc_height=this.expdata[i].description_height
          $("#description_box"+i).css("height",desc_height+"px")
          
        }
      } 
    scrollL() {

        console.log("i am in scroll L", this.leftPos)

        this.leftPos = $('.inner-container').scrollLeft();
        //this.leftPos = 0
        $(".inner-container").animate({
            scrollLeft: this.leftPos + 332
        }, 500);
    }

    scrollR() {

        console.log("i am in scroll R", this.leftPos)

        this.leftPos = $('.inner-container').scrollLeft();
        //this.leftPos = 0
        $(".inner-container").animate({
            scrollLeft: this.leftPos - 332
        }, 500);
    }

    modify_content_description(){

        

        setTimeout(() => {

         

            $(".fontnormal a").each(function (index, el) {
    
            
    
            
              $(this).css('color',  localStorage.getItem("primaryColor"));
      
              $(this).css('text-decoration', 'none');
              $(this).click(function (event) {
    
               
    
    
      
      
                if ($(this).attr('href') == "#") {
                          
                  
                  event.preventDefault();
      
                //   if (!popupShown)
                   {
      
                   //Before Tineri Tip CR
                    {
                      // var hashTagId = $(this).text().replace('#', '')
                    }
      
                    //After Tineri Tip CR
                    {
                      //BOC BUG FIX #
                    // var hashTagId = $(this).text().replace('#', '')
      
                     var hashTagId = $(this).data("compare")
    
                    
                    var display_name = $(this).text()
    
      
                    //BOC BUG FIX # end
                    }
      
                  
                    // if (isOnline == true) 
                    // {
                     
                    //   tipsFactory.getHasTag(display_name, (response) => {
    
                    
      
                    //     if (response != "error") {
                    //       var content = response.hash_tag.content;
                    //       if (content != null) {
                    //         content = content.replace(/<a/g, '<b><u><a ');
                    //         content = content.replace(/<\/a>/g, '</a></u></b>');
                    //         content = content.replace(/<ol/g, '<ol class="ordered_list"');
                    //         content = content.replace(/<ul/g, '<ul class="unordered_list"');
                    //         content = content.replace(/<em/g, '<em class="make_italic"')
                    //       }
      
                    //       //Before Tineri Tip CR
                    //       {
                    //         // let profileModal = modalPopup.create('TineriTipsPage', {
                    //         //   valueData: response.hash_tag,
                    //         //   htmlContent: content
                    //         // });
                    //       }
      
                    //       //After Tineri Tip CR
      
                    //         // let profileModal = modalPopup.create('TineriTipsPage', {
                    //         //   valueData: response.hash_tag,
                    //         //   htmlContent: content,
                    //         //   title: display_name
                    //         // });
                    //       //end
      
      
                    //       // profileModal.onDidDismiss(() => {
                    //       //   popupShown = false;
                    //       // });
                    //       // profileModal.present();
      
                    //     } else {
                    //       // popupShown = false;
                    //     }
                    //   })
      
                    // }
                    
                    //else 
                    {
    
    
      
                      //---------------When offline show tineri tips from localstorage---------------------------
                      var LSDtipsListoffline = JSON.parse(window.localStorage.getItem('LSDExperiencetipsList'))
      
    
      
    
                      var tipsdaytrip = LSDtipsListoffline;
    
                   
    
                      
                      tipsdaytrip.forEach(tipValue => {
      
                        var tipname = tipValue.name
                        var tipidcms = tipValue.id
      
                        if (tipname == hashTagId) {
                          // var content = tipValue.content;
    
    
                          let dialogRef = matdialogscope.open(TineriTipComponent, {
                            data: {
                                tineriTip: tipValue,
                                id:hashTagId,
                                display_name:display_name
                            }
                        });
    
    
      
                          // if (content != null) {
      
                          //   content = content.replace(/<a/g, '<a class="tineri_tip_a"');
                          //   content = content.replace(/<\/a>/g, '</a>');
      
                          //   content = content.replace(/<ol/g, '<ol class="ordered_list"');
                          //   content = content.replace(/<ul/g, '<ul class="unordered_list"');
      
                          //   content = content.replace(/<em/g, '<em class="make_italic"')
                          // }
      
                          // spinner.dismiss();
      
                          {
                            // let profileModal = modalPopup.create('TineriTipsPage', {
                            //   valueData: tipValue,
                            //   htmlContent: content
                            // }, {
                            //   cssClass: 'settings-modal'
                            // });
                          }
      
                          // let profileModal = modalPopup.create('TineriTipsPage', {
                          //   valueData: tipValue,
                          //   htmlContent: content,
                          //   title: display_name
                          // }, {
                          //   cssClass: 'settings-modal'
                          // });
      
      
      
                          // profileModal.onDidDismiss(() => {
      
                          //   popupShown = false;
                          //   scope.sign.registerBackButton(scope.platform, scope.navCtrl, scope.alertCtrl)
      
                          // });
    
    
                          // profileModal.present();
                        }
                      });
      
      
                      //---------------When offline show tineri tips from localstorage CLOSE---------------------------
                    }
      
                  }
                  
                }
      
              })
            });
      
          }, 100);
    
    
    
      
        
        var matdialogscope =this.matDialog;
        
        
        // {
        
       
        
        
        
        // setTimeout(() => {
        
      
        
        // $(".collapse a").each(function (index, el) {
        
       
        
        
        // $(this).css('color', localStorage.getItem("primaryColor"));
        
        // $(this).css('text-decoration', 'none');
        // $(this).click(function (event) {
        
       
        
        
        
        // if ($(this).attr('href') == "#") {
        
         
        
        // event.preventDefault();
        
        // // if (!popupShown)
        // {
        
        // //Before Tineri Tip CR
        // {
        // // var hashTagId = $(this).text().replace('#', '')
        // }
        
        // //After Tineri Tip CR
        // {
        // //BOC BUG FIX #
        // // var hashTagId = $(this).text().replace('#', '')
        
        // var hashTagId = $(this).data("compare")
        
        
        // var display_name = $(this).text()
        
        
        // //BOC BUG FIX # end
        // }
        
        
        // // if (isOnline == true)
        // // {
        
        // // tipsFactory.getHasTag(display_name, (response) => {
        
      
        
        // // if (response != "error") {
        // // var content = response.hash_tag.content;
        // // if (content != null) {
        // // content = content.replace(/<a/g, '<b><u><a ');
        // // content = content.replace(/<\/a>/g, '</a></u></b>');
        // // content = content.replace(/<ol/g, '<ol class="ordered_list"');
        // // content = content.replace(/<ul/g, '<ul class="unordered_list"');
        // // content = content.replace(/<em/g, '<em class="make_italic"')
        // // }
        
        // // //Before Tineri Tip CR
        // // {
        // // // let profileModal = modalPopup.create('TineriTipsPage', {
        // // // valueData: response.hash_tag,
        // // // htmlContent: content
        // // // });
        // // }
        
        // // //After Tineri Tip CR
        
        // // // let profileModal = modalPopup.create('TineriTipsPage', {
        // // // valueData: response.hash_tag,
        // // // htmlContent: content,
        // // // title: display_name
        // // // });
        // // //end
        
        
        // // // profileModal.onDidDismiss(() => {
        // // // popupShown = false;
        // // // });
        // // // profileModal.present();
        
        // // } else {
        // // // popupShown = false;
        // // }
        // // })
        
        // // }
        
        // //else
        // {
        
        
        
        // //---------------When offline show tineri tips from localstorage---------------------------
        // var LSDtipsListoffline = JSON.parse(window.localStorage.getItem('LSDExperiencetipsList'))
        // // var LSDtipsListoffline = JSON.parse(window.localStorage.getItem('LSDtipsList'))
        // // var LSDtipsListoffline = JSON.parse(window.localStorage.getItem('LSDItinerarytipsList'))
        
        
        
        
        // var tipsdaytrip = LSDtipsListoffline;
        
        
        
        // tipsdaytrip.forEach(tipValue => {
        
        // var tipname = tipValue.name
        // var tipidcms = tipValue.id
        
        // if (tipname == hashTagId) {
        // // var content = tipValue.content;
        
        
        // let dialogRef = matdialogscope.open(TineriTipComponent, {
        // data: {
        // tineriTip: tipValue,
        // id:hashTagId,
        // display_name:display_name
        // }
        // });
        
        
        
        // // if (content != null) {
        
        // // content = content.replace(/<a/g, '<a class="tineri_tip_a"');
        // // content = content.replace(/<\/a>/g, '</a>');
        
        // // content = content.replace(/<ol/g, '<ol class="ordered_list"');
        // // content = content.replace(/<ul/g, '<ul class="unordered_list"');
        
        // // content = content.replace(/<em/g, '<em class="make_italic"')
        // // }
        
        // // spinner.dismiss();
        
        // {
        // // let profileModal = modalPopup.create('TineriTipsPage', {
        // // valueData: tipValue,
        // // htmlContent: content
        // // }, {
        // // cssClass: 'settings-modal'
        // // });
        // }
        
        // // let profileModal = modalPopup.create('TineriTipsPage', {
        // // valueData: tipValue,
        // // htmlContent: content,
        // // title: display_name
        // // }, {
        // // cssClass: 'settings-modal'
        // // });
        
        
        
        // // profileModal.onDidDismiss(() => {
        
        // // popupShown = false;
        // // scope.sign.registerBackButton(scope.platform, scope.navCtrl, scope.alertCtrl)
        
        // // });
        
        
        // // profileModal.present();
        // }
        // });
        
        
        // //---------------When offline show tineri tips from localstorage CLOSE---------------------------
        // }
        
        // }
        
        // }
        
        // })
        // });
        
        // }, 100);
        
        
        
        // }
        }
    
trigger(){
    this.check_height();
}


}
