import { Component, OnInit } from '@angular/core';
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";
import * as $ from 'jquery';
import { Location } from '@angular/common';

@Component({
  selector: 'app-contact-emergency',
  templateUrl: './contact-emergency.component.html',
  styleUrls: ['./contact-emergency.component.css']
})
export class ContactEmergencyComponent implements OnInit {

  displayFeatures
  contactList
  translationsLabels
  tourListProvider
  header_img

  constructor(private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private location: Location,){


    

      this.header_img = JSON.parse(localStorage.getItem("LSDitinerarydata")).itinerary.imgSrc


      
      var LSDContactListEmergency = JSON.parse(window.localStorage.getItem('LSDContactListEmergency'))

      
      {
        this.displayFeatures = JSON.parse(localStorage.getItem("display_feature"));
        }

        var contactList = [];
      
     
       

   


      if (LSDContactListEmergency && this.displayFeatures) {
        for (let i = 0; i < LSDContactListEmergency.length; i++) {
          if (LSDContactListEmergency[i].phone != '' && this.displayFeatures.messages.operators == true) {
            contactList.push(LSDContactListEmergency[i])
          } else if (LSDContactListEmergency[i].phone != '' && this.displayFeatures.messages.operators == false) {
            contactList.push(LSDContactListEmergency[i])
          } else if (LSDContactListEmergency[i].phone == '' && this.displayFeatures.messages.operators == true) {
            contactList.push(LSDContactListEmergency[i])
          }
        }
      }

      this.contactList = contactList



      this.svgregister();

      setTimeout(()=>{this.check_height()},100) 

    }

    show_scroll_up_btn
    

      check_height(){

       
       
          if($(window).height()>= $(".mainContainer").height()+250){
            this.show_scroll_up_btn=false;
          }else if($(window).height()< $(".mainContainer").height()+250){
            this.show_scroll_up_btn=true;
          }
    
         
        }

      scroll_up(){
  
        $(window).scrollTop( $("body").offset().top );
    }

    close_page(){
      this.location.back();
    }
  
  ngOnInit() {
    this.translationsLabels = ""
    this.translationsLabels = JSON.parse(localStorage.getItem("UserData")).user.translations

  }

  svgregister() {

  

    this.matIconRegistry.addSvgIcon(
      "phone_ec",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/Images/mixed/phone.svg")
    );
    
    this.matIconRegistry.addSvgIcon(
      "mail_c",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/Images/mixed/mail.svg")
    );
    // *************************************
    
    
    
        
    
    }

  


}
