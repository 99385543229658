﻿﻿import { PassSelectedItemService } from './../services/pass-selected-item.service';
import { PassSelectedGuideService } from './../services/pass-selected-guide.service';
import { PassSelectedTransportService } from './../services/pass-selected-transport.service';
import { PassSelectedExtraService } from './../services/pass-selected-extra.service';
import { DailySurveyService } from './../services/daily-survey.service';
//(01) Erica : Display Feature :  Implementation
﻿//02: HRUSHIKESH 28-May-2020 Organization color change
//b1: HRUSHIKESH 15-June-2020 Bug fix Itinerary expand all required old syntax of angular 7
import { Component, OnInit, ViewEncapsulation ,ChangeDetectorRef} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ViewChild } from '@angular/core';
import { TourService } from '../services/tour.service'
import * as moment from 'moment';
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";
import { MatAccordion } from '@angular/material';
import { MatSlideToggleModule } from '@angular/material';

import { FormBuilder, FormControl, FormGroup, Validators, FormArray } from '@angular/forms';
import * as $ from 'jquery';
import { TineriTipComponent } from './../tineri-tip/tineri-tip.component';
import { MatDialog } from '@angular/material/dialog';







@Component({

  selector: 'app-itinerary',
  templateUrl: './itinerary.component.html',
  styleUrls: ['./itinerary.component.css'],
  encapsulation: ViewEncapsulation.None
})


export class ItineraryComponent implements OnInit {
    tourlistid
    itinerarydata
    formatDate
    translationsLabels
    traveller
    logo
    showMyClass :any[]=[];
    readMore :any[]=[];


   firstFormGroup: FormGroup;
   secondFormGroup: FormGroup;
 expand=0
 tourListProvider
 displayFeatures //(01) Erica Added
// @ViewChild('accordion', { static: true }) Accordion: MatAccordion
//(b1): BOC HRUSHIKESH 
@ViewChild('accordion') Accordion: MatAccordion;
//EOC

header_img
    
    constructor(private router: Router, private route: ActivatedRoute, 
        public tourService: TourService, private matIconRegistry: MatIconRegistry,
        private domSanitizer: DomSanitizer,
        public matDialog: MatDialog,//Added by Erica
        public dailySurveyService:DailySurveyService,
        private cdr:ChangeDetectorRef, //addedby sneha
        private pass_extra_service:PassSelectedExtraService,
        private pass_trasnport_service: PassSelectedTransportService,
        private pass_guide_service: PassSelectedGuideService,
        private pass_data_service: PassSelectedItemService
        ) {

            this.header_img = JSON.parse(localStorage.getItem("LSDitinerarydata")).itinerary.imgSrc


        this.translationsLabels = ""
        console.log("in WElcome USer", JSON.parse(localStorage.getItem("UserData")))
        console.log("in WElcome Company", JSON.parse(localStorage.getItem("companyProvider")))
        var userinfo = JSON.parse(localStorage.getItem("UserData"))
        var companyinfo = JSON.parse(localStorage.getItem("companyProvider"))

        this.logo = companyinfo.logo
        this.traveller = userinfo.user.fullname
        // this.showMyClass = true;
        this.translationsLabels = JSON.parse(localStorage.getItem("UserData")).user.translations
        this.tourListProvider = JSON.parse(localStorage.getItem("tourListProvider"))
        console.log("this.translationsLabels", this.translationsLabels)
        
        this.svgregister()

        this.renderdata()
        this.modify_content_description()
        this.sethidedata(this.itinerarydata.dayTrips.length)


        setTimeout(()=>{this.check_height()},100) 

        
        $(".fontnormal a").each(function (index, el) {

            $(this).click(function (event) {

              if ($(this).attr('href') == "#") {
                
                event.preventDefault();
              }
            })
          });

    }

  
    check_height(){

       
          if($(window).height()>= $(".mainContainer").height()+250){
            this.show_scroll_up_btn=false;
          }else if($(window).height()< $(".mainContainer").height()+250){
            this.show_scroll_up_btn=true;
          }
    
      
        }

    show_scroll_up_btn=true;

    scroll_up(){
  
        $(window).scrollTop( $("body").offset().top );
      }



    showTimeline: any[]=[];
    ShowHideTimeline(e ,i) {
        
        this.showTimeline[i] = e.checked
     }
     ngAfterViewInit() {
        
        if(this.itinerarydata.dayTrips.length>0){
        this.itinerarydata.dayTrips.forEach((data, index)=> {
            //277 is the height declared of the block
            if(data.content && data.pdf_description == undefined){
                
                var desc_height=301-document.getElementById("day_details"+index).offsetHeight
                var desc= document.getElementById("description"+index).offsetHeight
                if(desc<desc_height){
                   this.readMore[index]=false
                 }else{
                   if (document.getElementById("mat_title"+index).offsetWidth<500){
                     $("#description_box"+index).css("height","300px")
                   }
                   else{
                   $("#description_box"+index).css("height",desc_height+"px")
                   }
                 }
            }else{
                
                if(data.content==''){
                    this.readMore[index]=false
                }
            }
        } );
         }
    this.cdr.detectChanges()
      }

    sethidedata(len){
        for (var i = 0; i < len; i++) {
            this.showMyClass.push(true);
            this.readMore.push(true);
          }
     }
    showMore(i) {
        this.showMyClass[i] = !this.showMyClass[i];
        if (this.showMyClass[i]==false){

            this.itinerarydata.dayTrips[i].description_height=document.getElementById("description_box"+i).offsetHeight
            $("#description_box"+i).css("height","auto")
          }else{
            
            var desc_height=this.itinerarydata.dayTrips[i].description_height
            $("#description_box"+i).css("height",desc_height+"px")
            
          }
    } 
    open(name,pagename){
        var y=localStorage.setItem("Pname",name)
        console.log("TEST t",name)

      console.log("i am in open,pagename", pagename)

      if (pagename == "ITINERARY") {
          this.router.navigate(['itinerary'])
      }
      else if (pagename == "ABOUT TRIP") {
          this.router.navigate(['home'])
      }
    //   else if (pagename == "ACCOMMODATION") 
    //       {
    //       this.router.navigate(['hotel'])
    //   }
    //   else if (pagename == "GUIDES") {
    //       this.router.navigate(['guides'])
    //   }
    //   else if (pagename == "EXPERIENCES") {
    //       this.router.navigate(['experiences'])
    //   }
    //   else if (pagename == "MEALS") {
    //       this.router.navigate(['meals'])
    //   }
    //   else if (pagename == "TRANSPORTATION") {
    //     this.router.navigate(['transport'])
    // } 
    // else if (pagename == "EXTRA") {
    //     this.router.navigate(['extra'])
    // }
      
    }

    open_meal(pagename,meal){

        this.pass_data_service.setData(meal)
        if (pagename == "MEALS") {
            this.router.navigate(['meals'])
        }
    }


    open_experience(pagename,experience){

        this.pass_data_service.setData(experience)
        if (pagename == "EXPERIENCES") {
            this.router.navigate(['experiences'])
        }
    }

    open_accomodation(pagename,accomodation){

         this.pass_data_service.setData(accomodation)
        if (pagename == "ACCOMMODATION") 
        {
        this.router.navigate(['hotel'])
    }
      
      
    }

    open_guide(pagename,guide){

        this.pass_guide_service.setData(guide)

      
       if (pagename == "GUIDES") {
            this.router.navigate(['guides'])
        }
    }

    open_transport(pagename,day,transport){

        this.pass_trasnport_service.setData(day,transport)
       if (pagename == "TRANSPORTATION") {
            this.router.navigate(['transport'])
        } 
    }

    open_extra(pagename,day,extra){

        this.pass_extra_service.setData(day,extra)

        if (pagename == "EXTRA") {
            this.router.navigate(['extra'])
        }

    }



  ngOnInit() {

    //BOC Erica (01)
    //Do heavy commputing here

   
        //If the user see's header before local storage values are set in welcome page 
        // we fetch the live values
        this.tourService.show(data => {

            {
                console.log("List2Show:: itinerary ",data.itinerary.display_feature)
                this.displayFeatures = data.itinerary.display_feature;
                localStorage.setItem("display_feature",JSON.stringify(data.itinerary.display_feature));
      
                if (localStorage.getItem("user_role") != "Travellers" && localStorage.getItem("user_role") != "Admin" && localStorage.getItem("user_role") != "Guides") {
                  this.displayFeatures.itinerary.survey = false;
      
                }
             
              }

          
           
        });

    
    {
        {
            // The Local storage value is set in welcome page while coming to home page
            this.displayFeatures = JSON.parse(localStorage.getItem("display_feature"));
          
          
            if (localStorage.getItem("user_role") != "Travellers" && localStorage.getItem("user_role") != "Admin" && localStorage.getItem("user_role") != "Guides") {
              this.displayFeatures.itinerary.survey = false;
          
            }

          }
    }


    // EOC Erica(01)

       //02: BOC HRUSHIKESH := Organization color applied if exist . Else company primary color applied
    var index = (localStorage.getItem('INDEX_WELCOME'))
    console.log("Slide index from welcome", index)
    
    if (this.tourListProvider[index].brand.length == undefined) {
    
        console.log("TEST IS ON", this.tourListProvider[index].brand.color)
        $('#extraCSS').text(
            '.mat-ink-bar {background-color:' + this.tourListProvider[index].brand.color + ' !important;}' +
    
            '.flag {color:' + this.tourListProvider[index].brand.color + ' !important;}' +
            ' .direction-l .flag-wrapper:before{border :1px solid' + this.tourListProvider[index].brand.color + ' !important;}' +
            ' .direction-r .flag-wrapper:before{border :1px solid' + this.tourListProvider[index].brand.color + ' !important;}' +
            'a {color:' + this.tourListProvider[index].brand.color + ' !important;}'
            +' .daily_survey_button{fill:' + this.tourListProvider[index].brand.color + ' !important;}'
            +'.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb { background-color: ' + this.tourListProvider[index].brand.color + ' !important;}'
    
        );
    } else {
        console.log("Entry else", JSON.parse(localStorage.getItem("primaryColor")))
        $('#extraCSS').text(
            '.mat-ink-bar {background-color:' + JSON.parse(localStorage.getItem("primaryColor")) + ' !important;}' +
            '.flag {color:' + JSON.parse(localStorage.getItem("primaryColor")) + ' !important;}' +
            ' .direction-l .flag-wrapper:before{border :1px solid' + JSON.parse(localStorage.getItem("primaryColor")) + ' !important;}' +
            ' .direction-r .flag-wrapper:before{border :1px solid' + JSON.parse(localStorage.getItem("primaryColor")) + ' !important;}' +
            'a {color:' + JSON.parse(localStorage.getItem("primaryColor")) + ' !important;}'
            +' .daily_survey_button{fill:' + JSON.parse(localStorage.getItem("primaryColor")) + ' !important;}'
            +'.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb { background-color: ' + JSON.parse(localStorage.getItem("primaryColor")) + ' !important;}'
    
        );
    }
    //EOC HRUSHIKESH
  }


  closeAllPanels() {
      this.expand = 0
      this.Accordion.closeAll();

  }

  openAllPanels() {
      this.expand = 1
      this.Accordion.openAll();
  }

  togglePanel(itinerary,value){
    itinerary.panelOpenState = value;
    const anyOpen = this.itinerarydata.dayTrips.some(day => day.panelOpenState);
    
    if (anyOpen){
        this.expand = 1
    }else{
        this.expand = 0
    }
  }




  renderdata() {

   


      this.itinerarydata = JSON.parse(localStorage.getItem("LSDitinerarydata")).itinerary

      console.log(" data xoxo itinerarydata",this.itinerarydata)

      

      var dayTrips =this.itinerarydata.dayTrips

     

      for(var i=0;i<dayTrips.length;i++ ){

        // var description =dayTrips[i].content
      

       

        // dayTrips[i].content=description.replace(/<a/g, "<a  target='_blank' ");



        dayTrips[i].content  =  dayTrips[i].content.replace(/<a href/g, '<u><a href');
        dayTrips[i].content  =  dayTrips[i].content.replace(/<a href="#"/g, '</u><a href="#" ');
        dayTrips[i].content  =  dayTrips[i].content.replace(/<\/a>/g, '</a></u>');
        dayTrips[i].content  =  dayTrips[i].content.replace(/<a href/g, '<a target="_blank" href');



        if(dayTrips[i].pdf_description!= undefined){
            dayTrips[i].pdf_description_iframe = this.domSanitizer.bypassSecurityTrustResourceUrl( dayTrips[i].pdf_description);

        }
       

      }

      this.itinerarydata.dayTrips= dayTrips



      var IDate

      var StartDate = this.itinerarydata.dateStart;


      var EndDate = this.itinerarydata.dateEnd;

      let monthS = this.itinerarydata.iti_start_month;
      let monthE = this.itinerarydata.iti_end_month;

      let YearS = this.itinerarydata.iti_start_year;
      let YearE = this.itinerarydata.iti_end_year;

      var dStart
      var dEnd

      if (monthS == monthE) {
          dStart = moment(StartDate).format("DD");
          dEnd = moment(EndDate).format("DD MMMM YYYY");
          let monthName = dEnd.split(' ')[1];
          dEnd = dEnd.replace(monthName, this.translationsLabels[monthName.toLowerCase()]);

          IDate = dStart + "-" + dEnd
          console.log("in if IDate : " + IDate)

      } else {
          dStart = moment(StartDate).format("DD MMMM");
          let monthNameStart = dStart.split(' ')[1];
          dStart = dStart.replace(monthNameStart, this.translationsLabels[monthNameStart.toLowerCase()]);

          dEnd = moment(EndDate).format("DD MMMM YYYY");
          let monthName = dEnd.split(' ')[1];
          dEnd = dEnd.replace(monthName, this.translationsLabels[monthName.toLowerCase()]);

          IDate = dStart + "-" + dEnd

      }


      if (YearS != YearE) {
          dStart = moment(StartDate).format("DD MMMM YYYY");
          let monthNameStart = dStart.split(' ')[1];
          dStart = dStart.replace(monthNameStart, this.translationsLabels[monthNameStart.toLowerCase()]);

          dEnd = moment(EndDate).format("DD MMMM YYYY");
          let monthName = dEnd.split(' ')[1];
          dEnd = dEnd.replace(monthName, this.translationsLabels[monthName.toLowerCase()]);

          IDate = dStart + "-" + dEnd
      }




      console.log("final formatdate IDate :" + IDate)
      this.itinerarydata.formatDate = IDate;
      console.log("DAY TRIP DATA", this.itinerarydata)

      

  }



  svgregister() {

      this.matIconRegistry.addSvgIcon(
          "abouttrip1",
          this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/Images/svg/abouttrip")
      );


      this.matIconRegistry.addSvgIcon(
          "itinerary1",
          this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/Images/svg/itinerary.svg")
      );


      //this.matIconRegistry.addSvgIcon(
      //    "accomodation1",
      //    this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/Images/svg/accomodation.svg")
      //);


      this.matIconRegistry.addSvgIcon(
          "guide1",
          this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/Images/svg/guides.svg")
      );


      this.matIconRegistry.addSvgIcon(
          "experinces1",
          this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/Images/svg/experiences.svg")
      );


      this.matIconRegistry.addSvgIcon(
          "transport1",
          this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/Images/svg/transportation.svg")
      );


      this.matIconRegistry.addSvgIcon(
          "contact1",
          this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/Images/svg/contacts.svg")
      );


      this.matIconRegistry.addSvgIcon(
          "doccument1",
          this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/Images/svg/documents.svg")
      );


      this.matIconRegistry.addSvgIcon(
          "extras1",
          this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/Images/svg/extras.svg")
      );

      this.matIconRegistry.addSvgIcon(
          "meals1",
          this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/Images/svg/meals.svg")
      );

      this.matIconRegistry.addSvgIcon(
          "itinerarylist",
          this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/Images/svg/itinerary-list.svg")
      );

      this.matIconRegistry.addSvgIcon(
        "daily",
        this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/Images/mixed/daily_survey.svg")
    );

  }


  setBaseColor() {
      return {
          'color': '#ff6d42'
      }
  }

  modify_content_description(){

    
    
    var matdialogscope =this.matDialog;
    
    
    {
    
   
    
   
    
    setTimeout(() => {
    
    
    
    $(".fontnormal a").each(function (index, el) {
    
  
    
    $(this).css('color', localStorage.getItem("primaryColor"));
    if ($(this).attr('href') == "#") {
        $(this).css('text-decoration', 'none');
    
     }
     else{
        $(this).css('text-decoration', 'underline');
    }
    // $(this).css('text-decoration', 'none');
    $(this).click(function (event) {
    
   
    
    
    if ($(this).attr('href') == "#") {
    
    
    
    event.preventDefault();
    
    // if (!popupShown)
    {
    
    //Before Tineri Tip CR
    {
    // var hashTagId = $(this).text().replace('#', '')
    }
    
    //After Tineri Tip CR
    {
    //BOC BUG FIX #
    // var hashTagId = $(this).text().replace('#', '')
    
    var hashTagId = $(this).data("compare")
    
    
    var display_name = $(this).text()
    
    
    //BOC BUG FIX # end
    }
    
    
    // if (isOnline == true)
    // {
    
    // tipsFactory.getHasTag(display_name, (response) => {
    
    
    // if (response != "error") {
    // var content = response.hash_tag.content;
    // if (content != null) {
    // content = content.replace(/<a/g, '<b><u><a ');
    // content = content.replace(/<\/a>/g, '</a></u></b>');
    // content = content.replace(/<ol/g, '<ol class="ordered_list"');
    // content = content.replace(/<ul/g, '<ul class="unordered_list"');
    // content = content.replace(/<em/g, '<em class="make_italic"')
    // }
    
    // //Before Tineri Tip CR
    // {
    // // let profileModal = modalPopup.create('TineriTipsPage', {
    // // valueData: response.hash_tag,
    // // htmlContent: content
    // // });
    // }
    
    // //After Tineri Tip CR
    
    // // let profileModal = modalPopup.create('TineriTipsPage', {
    // // valueData: response.hash_tag,
    // // htmlContent: content,
    // // title: display_name
    // // });
    // //end
    
    
    // // profileModal.onDidDismiss(() => {
    // // popupShown = false;
    // // });
    // // profileModal.present();
    
    // } else {
    // // popupShown = false;
    // }
    // })
    
    // }
    
    //else
    {
    
    
    
    //---------------When offline show tineri tips from localstorage---------------------------
    
    var LSDtipsListoffline = JSON.parse(window.localStorage.getItem('LSDtipsList'))
    // var LSDtipsListoffline = JSON.parse(window.localStorage.getItem('LSDItinerarytipsList'))
    
    
    
    
    var tipsdaytrip = LSDtipsListoffline;
    
    
    
    tipsdaytrip.forEach(tipValue => {
    
    var tipname = tipValue.name
    var tipidcms = tipValue.id
    
    if (tipname == hashTagId) {
    // var content = tipValue.content;
    
    
    let dialogRef = matdialogscope.open(TineriTipComponent, {
    data: {
    tineriTip: tipValue,
    id:hashTagId,
    display_name:display_name
    }
    });
    
    
    
    // if (content != null) {
    
    // content = content.replace(/<a/g, '<a class="tineri_tip_a"');
    // content = content.replace(/<\/a>/g, '</a>');
    
    // content = content.replace(/<ol/g, '<ol class="ordered_list"');
    // content = content.replace(/<ul/g, '<ul class="unordered_list"');
    
    // content = content.replace(/<em/g, '<em class="make_italic"')
    // }
    
    // spinner.dismiss();
    
    {
    // let profileModal = modalPopup.create('TineriTipsPage', {
    // valueData: tipValue,
    // htmlContent: content
    // }, {
    // cssClass: 'settings-modal'
    // });
    }
    
    // let profileModal = modalPopup.create('TineriTipsPage', {
    // valueData: tipValue,
    // htmlContent: content,
    // title: display_name
    // }, {
    // cssClass: 'settings-modal'
    // });
    
    
    
    // profileModal.onDidDismiss(() => {
    
    // popupShown = false;
    // scope.sign.registerBackButton(scope.platform, scope.navCtrl, scope.alertCtrl)
    
    // });
    
    
    // profileModal.present();
    }
    });
    
    
    //---------------When offline show tineri tips from localstorage CLOSE---------------------------
    }
    
    }
    
    }
    
    })
    });
    
    }, 100);
    
    
    
    }
    }

    openDailySurvey(id, survey, day){

     

        this.dailySurveyService.setDataid(id);
        this.dailySurveyService.setDatasurvey(survey)
        this.dailySurveyService.setDataDay(day)
        
        this.router.navigate(['daily-survey'])
    }

    trigger(){
        this.check_height();
    }



}
