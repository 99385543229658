﻿import { Injectable } from '@angular/core';
import { Restangular } from 'ngx-restangular';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router'

import { AppUrls} from '../app-storagekeys-urls'

import { DataServiceService } from '../services/data-service.service'

@Injectable({
  providedIn: 'root'
})
export class MealsService {
  Urls = AppUrls()
  currentUserData
  restHeader
  constructor(private restangular: Restangular, private http: HttpClient, private router:Router,
    private dataService:DataServiceService) {
    console.log("Coming")
  }





  all(callback) {

      var id = localStorage.getItem("LStourListId");
      if ((localStorage.getItem("UserData")) != null) {
          this.currentUserData = JSON.parse(localStorage.getItem("UserData"));
      }

      var user_language = "";
      if (window.localStorage.getItem("selected_language_by_user") == null) {
          user_language = this.currentUserData.user.language;
      } else {
          user_language = window.localStorage.getItem("selected_language_by_user");
      }


      this.restHeader = this.currentUserData.user.email + ':' + this.currentUserData.token + ':' + user_language

      console.log("restHeader",this.restHeader)

      this.restangular.withConfig((RestangularConfigurer) => {
          console.log("COMING TO REST ANGULAR ")
          RestangularConfigurer.setDefaultHeaders({
              'Content-Type': 'application/json',
              'Authorization': this.restHeader
          });
      }).one(this.Urls.mealslist).get({itinerary_id: id}).toPromise().then((response) => {

          console.log("Load:: Hotel: ", response);
          localStorage.setItem("LSDMealslistdata", JSON.stringify(response.meals));
          callback(response)
     
         
      }, (err) => {
          console.log("ERROR:x: 1: ", err);
         

          //nav.setRoot('SignIn')// Commenting this did not solve the refresh bug
      });


  }



 

}
