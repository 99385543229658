﻿// (01) Documents CR
import { Injectable } from '@angular/core';
import { Restangular } from 'ngx-restangular';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router'

import { AppUrls} from '../app-storagekeys-urls'

import { DataServiceService } from '../services/data-service.service'
import { MatDialog } from '@angular/material/dialog';
import { AlertDailogComponent } from '../alert-dailog/alert-dailog.component';
@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  Urls = AppUrls()
  currentUserData
  restHeader
  message: any;
  translationsLabels: any;//Added by HRUSHIKESH change language

constructor(private restangular: Restangular, 
            private http: HttpClient, 
            private router:Router,
            private dataService: DataServiceService, 
            private matDialog: MatDialog) {}

  get userData(){

    if(localStorage.getItem('UserData')){
      return localStorage.getItem('UserData')
    }
    return ''
  }
  //BOC HRUSHIKESH change-password
  resetPass(user_info) { //   {
    
    if ((localStorage.getItem("UserData")) != null) {
        this.currentUserData = JSON.parse(localStorage.getItem("UserData"));
    }


    var user_language = "";
    if (window.localStorage.getItem("selected_language_by_user") == null) {
        
        user_language = this.currentUserData.user.language;
    } else {
        user_language = window.localStorage.getItem("selected_language_by_user");
    }


    this.restHeader = this.currentUserData.user.email + ':' + this.currentUserData.token + ':' + user_language

    if (user_info.email.length != 0 && user_info.new_password.length 
        != 0 && user_info.reenter_password.length != 0) {
        if (user_info.new_password === user_info.reenter_password) {

            this.restangular.withConfig((RestangularConfigurer) => {
                RestangularConfigurer.setDefaultHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': this.restHeader
                });
            }).all(this.Urls.resetPassword).post(user_info).toPromise().then((response) => {
                this.logout()

            }).catch(err => {
           
                // console.log("err:", err)
            })
        } else {
           
            this.message = "Passwords do not match",
             
                this.openDialog()
        }
    } else {
      
        this.message = "Fields are empty",
           
            this.openDialog()
    }
}


openDialog() {
    
    const dialogRef = this.matDialog.open(AlertDailogComponent, {
        data: { message: this.message }
    });

    dialogRef.afterClosed().subscribe(result => {
      
    });
}

//EOC HRUSHIKESH change-password



login(formDetails){
    
    this.restangular.all(this.Urls.loginUrl).post(formDetails).toPromise().then(sessionData=>{
     

      localStorage.setItem("UserData", JSON.stringify(sessionData) );
      localStorage.setItem("user_role",sessionData.user.role);//  Erica (01)

      this.dataService.loggedIn.next(sessionData.user)

      //Added for Change lang feature Erica
      var user_language = "";
        if (window.localStorage.getItem("selected_language_by_user") == null) {
            user_language = sessionData.user.language;
        } else {
            user_language = window.localStorage.getItem("selected_language_by_user");
        }

        window.localStorage.setItem("displayed_language", user_language);
         //Added for Change lang feature Erica
     
      // callback(sessionData)
      this.orgnaisation_basic();
    
    }).catch(err=>{
      // callback(err)
        // console.log("err:", err)

        const dialogRef = this.matDialog.open(AlertDailogComponent, {
            data: { message: "Login Failed: Your username and/or password do not match" }
        });

        dialogRef.afterClosed().subscribe(result => {
          
        });
    })
    
    // return this.http.post("http://54.169.128.166/api/v1/sessions", formDetails).toPromise().then(data=> console.log(data))
  
}



  orgnaisation_basic() {


      if ((localStorage.getItem("UserData")) != null) {
          this.currentUserData = JSON.parse(localStorage.getItem("UserData"));
      }


      var user_language = "";
      if (window.localStorage.getItem("selected_language_by_user") == null) {
          user_language = this.currentUserData.user.language;
      } else {
          user_language = window.localStorage.getItem("selected_language_by_user");
      }


      this.restHeader = this.currentUserData.user.email + ':' + this.currentUserData.token + ':' + user_language

  

      this.restangular.withConfig((RestangularConfigurer) => {
        
          RestangularConfigurer.setDefaultHeaders({
              'Content-Type': 'application/json',
              'Authorization': this.restHeader
          });
      }).one(this.Urls.company).get({
          domain_name: this.currentUserData.user.org
          }).toPromise().then((response) => {

//Setting company color HRUSHIKESH
            console.log("COLOR:BAR::",response.company.color)
          window.localStorage.setItem('primaryColor', JSON.stringify(response.company.color));
          
          window.localStorage.setItem('companyProvider', JSON.stringify(response.company));
          
          this.router.navigate(['welcome'])
      }, (err) => {
        

          //nav.setRoot('SignIn')// Commenting this did not solve the refresh bug
      });


  }




  password(formDetails) {


     

   
      if (formDetails != undefined) {
          this.restangular.all(this.Urls.PasswordUrl).post(formDetails).toPromise().then((response) => {
             

              const dialogRef = this.matDialog.open(AlertDailogComponent, {
                  data: { message: "Email with your password has been sent" }
              });

              dialogRef.afterClosed().subscribe(result => {
                 
              });

              this.router.navigate([''])
          },  (err) => {
             
            
              const dialogRef = this.matDialog.open(AlertDailogComponent, {
                  data: { message: "Login Failed: Invalid username. Your username is your registered email." }
              });

              dialogRef.afterClosed().subscribe(result => {
                
              });
     
          });

      } else {
       
       
      }

  }



  logout(){
     

  
    localStorage.removeItem("UserData");
    localStorage.removeItem("tourListProvider");
    localStorage.removeItem("INDEX_WELCOME");
    window.localStorage.removeItem("selected_language_by_user");

    /////

    window.localStorage.removeItem("displayed_language");
    window.localStorage.removeItem("selected_language_by_user");
    localStorage.removeItem("user_role");
    window.localStorage.removeItem('primaryColor');
    window.localStorage.removeItem('companyProvider');

    ////




    //need remove other data as well 

    this.router.navigate([''])
  }



  changelanguage(callback) {


      if ((localStorage.getItem("UserData")) != null) {
          this.currentUserData = JSON.parse(localStorage.getItem("UserData"));
      }

      var user_language = "";
      if (window.localStorage.getItem("selected_language_by_user") == null) {
          user_language = this.currentUserData.user.language;
      } else {
          user_language = window.localStorage.getItem("selected_language_by_user");
      }


      this.restHeader = this.currentUserData.user.email + ':' + this.currentUserData.token + ':' + user_language
      var LSDuserinfoProvider = JSON.parse(localStorage.getItem('UserData'));

      this.restangular.withConfig((RestangularConfigurer) => {
        
          RestangularConfigurer.setDefaultHeaders({
              'Content-Type': 'application/json',
              'Authorization': this.restHeader
          });
      }).one(this.Urls.languageURL).get({
          language: user_language
      }).toPromise().then((response) => {

        

          LSDuserinfoProvider.user.translations = response.language_json.translations;
          localStorage.setItem("UserData", JSON.stringify(LSDuserinfoProvider));
          
          window.localStorage.setItem("displayed_language", user_language);
          callback(response)
      }, (err) => {
         
          console.log('Error CompanyFactory: ', err.data.response);
          

          });



    
  }

}
