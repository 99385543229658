import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PassSelectedItemService {

 
 
  data

  constructor() { }

  setData(data){
    console.log("PASSSTTTT             ",data)
   
   
    this.data= data;
  }
 
  getData(){
    return this.data;
  }
}
