import { Component, OnInit } from '@angular/core';
import { DocumentViewService } from './../services/document-view.service';
import {DomSanitizer} from '@angular/platform-browser';


@Component({
  selector: 'app-document-view',
  templateUrl: './document-view.component.html',
  styleUrls: ['./document-view.component.css'],
})
export class DocumentViewComponent implements OnInit {

  doc
  url

  header_img

  constructor( private documentViewService:DocumentViewService,
    private domSanitizer: DomSanitizer) {
    this.doc=documentViewService.getData()

 
    this.header_img = JSON.parse(localStorage.getItem("LSDitinerarydata")).itinerary.imgSrc

    this.url = this.domSanitizer.bypassSecurityTrustResourceUrl(this.doc.localurl);

   }

  ngOnInit() {
  }

}
