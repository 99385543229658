import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthenticationService } from '../services/authentication.service'
import { Router } from '@angular/router'

import { DomSanitizer,SafeHtml  } from "@angular/platform-browser";
import * as $ from 'jquery';
import { MatIconRegistry } from "@angular/material/icon";



@Component({
  selector: 'app-tineri-tip',
  templateUrl: './tineri-tip.component.html',
  styleUrls: ['./tineri-tip.component.css']
})
export class TineriTipComponent implements OnInit {

  passDATA

 

  display_name
  
  imageurl
  isImagePresent
  content

  tourListProvider

  translationsLabels

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
  public DomSanitizer:DomSanitizer, public authService: AuthenticationService,
       private router: Router,
      private matDialog: MatDialog,public AuthenticationService:AuthenticationService,
      public domSanitizer:DomSanitizer,
      private matIconRegistry: MatIconRegistry,
      private dialogRef: MatDialogRef<TineriTipComponent>) { 

        this.translationsLabels = JSON.parse(localStorage.getItem("UserData")).user.translations


        this.tourListProvider = JSON.parse(localStorage.getItem("tourListProvider"))
       
        var index = (localStorage.getItem('INDEX_WELCOME'))

      
        if (this.tourListProvider[index].brand.length == undefined) {
            
            $('#tineri_tip').text(
                ' app-tineri-tip a {color:' + this.tourListProvider[index].brand.color + ' !important;'+
               ' text-decoration: underline !important;}'
                
    
            );
        } else {
            
            $('#tineri_tip').text(
                'app-tineri-tip a {color:' + JSON.parse(localStorage.getItem("primaryColor")) + ' !important;'+
                ' text-decoration: underline !important;}'
            );
        }

        setTimeout(()=>{$('#tineri_content').scrollTop(0)},500);

        


        this.passDATA=data


        this.display_name=this.data.display_name

        this.imageurl = this.data.tineriTip.image;



        this.isImagePresent=this.data.tineriTip.image_present


       var str=this.data.tineriTip.content

      //  style='border-bottom: 1px solid;'

        var str2 = str.replace(/<a/g, "<a  target='_blank'  ");
       

        // this.content = str2.replace( /href="#"/g, '<a  href="#"  style="border-bottom: 1px solid white;"  ');

        this.content=str2

       

        this.svgregister();
      

      }

  ngOnInit() {
  }

  close(){

    this.dialogRef.close(  );

  }

  svgregister() {

 

    this.matIconRegistry.addSvgIcon(
        "close",
        this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/Images/mixed/close.svg")
    );
 
    
    

}

}
