﻿import { PassSelectedGuideService } from './../services/pass-selected-guide.service';
//01: HRUSHIKESH 28-May-2020 Organization color change
//02: HRUSHIKESH 2-june-2020 Guides design change  
import { Component, OnInit } from '@angular/core';
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";
import * as $ from 'jquery';
import { TineriTipComponent } from './../tineri-tip/tineri-tip.component';
import { MatDialog } from '@angular/material/dialog';


@Component({
  selector: 'app-guides',
  templateUrl: './guides.component.html',
  styleUrls: ['./guides.component.css']
})
export class GuidesComponent implements OnInit {

    tourlistid
    itinerarydata
    formatDate
    translationsLabels
    traveller
    logo
    guidesdata
    tourListProvider: any;

    guide

    guide_id

    header_img
    constructor( private matIconRegistry: MatIconRegistry,
        public matDialog: MatDialog,//Added by Erica
        private domSanitizer: DomSanitizer,
        private pass_guide_service: PassSelectedGuideService) {

          this.header_img = JSON.parse(localStorage.getItem("LSDitinerarydata")).itinerary.imgSrc


          this.guide = this.pass_guide_service.getGuide();
          this.pass_guide_service.setData(undefined)

          if(this.guide!=undefined){
            console.log("PASSSTTTT             ",this.guide)

            setTimeout(()=>{
               var x = $("#"+this.guide.id).position();
            console.log(" PASSSTTTT  Top: " + x.top + " Left: " + x.left);
  

             window.scrollTo(x.left, x.top+100)

  
          },1000);
          }
         

         


            this.svgregister();
        console.log("in WElcome USer", JSON.parse(localStorage.getItem("UserData")))
        console.log("in WElcome Company", JSON.parse(localStorage.getItem("companyProvider")))
        var userinfo = JSON.parse(localStorage.getItem("UserData"))
        var companyinfo = JSON.parse(localStorage.getItem("companyProvider"))

        this.logo = companyinfo.logo
        this.traveller = userinfo.user.fullname
        this.tourListProvider = JSON.parse(localStorage.getItem("tourListProvider"))
        this.translationsLabels = JSON.parse(localStorage.getItem("UserData")).user.translations
        console.log("this.translationsLabels", this.translationsLabels)
        this.renderdata()

        
     
        var elementname=localStorage.getItem("Pname")
        console.log("elementname",elementname)
        var scrollINDEX
      
        // var t=9
        // console.log("tar",t)
        
        if(this.guidesdata!=undefined){
          for(var i=0;i<this.guidesdata.length;i++){
            if(this.guidesdata[i].name==elementname)
             {  var j=i+1;
             
                 if(this.guidesdata[i].location=='   ' && this.guidesdata[i].experience==''  && this.guidesdata[i].languages==''){
                     console.log("Break 1")
                          scrollINDEX=400*j
                  }
                else if(this.guidesdata[i].location!='   ' && this.guidesdata[i].experience==''  && this.guidesdata[i].languages==''){
                
                 console.log("Break 2",i)
                     scrollINDEX=400*j
             }
             else if(this.guidesdata[i].location=='   ' && this.guidesdata[i].experience=='' && this.guidesdata[i].languages!=''){
                 console.log("Break 3")
                 scrollINDEX=400*j
             }
             else if(this.guidesdata[i].location=='   ' && this.guidesdata[i].experience!='' && this.guidesdata[i].languages==''){
                 console.log("Break 4")
                 scrollINDEX=400*j
             }
             else if(this.guidesdata[i].location!='   ' && this.guidesdata[i].experience!='' && this.guidesdata[i].languages==''){
                 console.log("Break 5")
                 scrollINDEX=600*j
             }
             else if(this.guidesdata[i].location=='   ' && this.guidesdata[i].experience!='' && this.guidesdata[i].languages!=''){
                 console.log("Break 6")
                 scrollINDEX=600*j
             }
             else if(this.guidesdata[i].location!='   ' && this.guidesdata[i].experience!='' && this.guidesdata[i].languages!=''){
                 console.log("Break 7")
                 scrollINDEX=700*j
             }
           
         }
     }
      
        }
        
 


        if(localStorage.getItem('Pname')!=undefined && localStorage.getItem('Pname')!=null)
         {
             
            console.log("scrollINDEX2 ::",scrollINDEX)
            setTimeout(() => {
            window.scrollTo(0,scrollINDEX)
            localStorage.removeItem('Pname')
             },300);
            // document.querySelector('#target').scrollIntoView({ behavior: 'smooth', block: 'center' });
        
            // var t=localStorage.getItem('ITEM_INDEX')
            // const elementList = document.querySelectorAll(this.guidesdata[t]);
            // const element = elementList[0] as HTMLElement;
            // element.scrollIntoView({ behavior: 'smooth' });
            // localStorage.removeItem('ITEM_INDEX')
         }

         this.modify_content_description()

         setTimeout(()=>{this.check_height()},100) 

         $(".fontnormal a").each(function (index, el) {

          $(this).click(function (event) {

            if ($(this).attr('href') == "#") {
              
              event.preventDefault();
            }
          })
        });


        }
    
       

          check_height(){

           
              if($(window).height()>= $(".mainContainer").height()+250){
                this.show_scroll_up_btn=false;
              }else if($(window).height()< $(".mainContainer").height()+250){
                this.show_scroll_up_btn=true;
              }
        
            
            }
          scroll_up(){
  
            $(window).scrollTop( $("body").offset().top );
        }
        show_scroll_up_btn=true;


    ngOnInit() {
		
        localStorage.setItem("showheader", "1")
		//01: BOC HRUSHIKESH := Organization color applied if exist . Else company primary color applied
      
        var index = (localStorage.getItem('INDEX_WELCOME'))
        console.log("Slide index from welcome", index)
        
        if (this.tourListProvider[index].brand.length == undefined) {
            console.log("TEST IS ON", this.tourListProvider[index].brand.color)
            $('#extraCSS').text(
                '.guide-color {color:' + this.tourListProvider[index].brand.color + ' !important;}' +
                'a {color:' + this.tourListProvider[index].brand.color + ' !important;}'
        
            );
        } else {
            console.log("Entry else", JSON.parse(localStorage.getItem("primaryColor")))
            $('#extraCSS').text(
                '.guide-color {color:' + JSON.parse(localStorage.getItem("primaryColor")) + ' !important;}' +
                'a {color:' + JSON.parse(localStorage.getItem("primaryColor")) + ' !important;}'
            );
        }
        //EOC
       
    }
    renderdata() {



        this.itinerarydata = JSON.parse(localStorage.getItem("LSDitinerarydata")).itinerary


console.log(" VIVA  ::", localStorage.getItem("LSDGuidelistdata"))

if( localStorage.getItem("LSDGuidelistdata")!=undefined){
  this.guidesdata = JSON.parse(localStorage.getItem("LSDGuidelistdata")).contacts
  console.log("this.guidesdata",this.guidesdata)
  
  for (let a = 0; a < this.guidesdata.length; a++) {
  
      
  
      // this.guidesdata[a].description=this.guidesdata[a].description.replace(/<a/g, "<a  target='_blank' ");
  
      this.guidesdata[a].description =  this.guidesdata[a].description.replace(/<a href/g, '<u><a href');
      this.guidesdata[a].description =  this.guidesdata[a].description.replace(/<a href="#"/g, '</u><a href="#" ');
      this.guidesdata[a].description=  this.guidesdata[a].description.replace(/<\/a>/g, '</a></u>');
      this.guidesdata[a].description =  this.guidesdata[a].description.replace(/<a href/g, '<a target="_blank" href');
  
      
  
  
      // to fetch days array-Sneha
      this.guidesdata[a].totaldays = [];
      this.guidesdata[a].totaldays=this.guidesdata[a].days_applicable[0].day
  
      if(this.guidesdata[a].pdf_description!= undefined){
          this.guidesdata[a].pdf_description_iframe = this.domSanitizer.bypassSecurityTrustResourceUrl( this.guidesdata[a].pdf_description);
  
      }
  
  }
  
  
       
}

    


    }


    svgregister() {

   


        this.matIconRegistry.addSvgIcon(
            "phone",
            this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/Images/mixed/phone.svg")
        );
      }
    modify_content_description(){

        console.log("tititititititiititit   modify_content_description")

        setTimeout(() => {

         

            $(".fontnormal a").each(function (index, el) {
    
            
    
            
              $(this).css('color',  localStorage.getItem("primaryColor"));
      
              $(this).css('text-decoration', 'none');
              $(this).click(function (event) {
    
               
    
    
      
      
                if ($(this).attr('href') == "#") {
                          
                  
                  event.preventDefault();
      
                //   if (!popupShown)
                   {
      
                   //Before Tineri Tip CR
                    {
                      // var hashTagId = $(this).text().replace('#', '')
                    }
      
                    //After Tineri Tip CR
                    {
                      //BOC BUG FIX #
                    // var hashTagId = $(this).text().replace('#', '')
      
                     var hashTagId = $(this).data("compare")
    
                    
                    var display_name = $(this).text()
    
      
                    //BOC BUG FIX # end
                    }
      
                  
                    // if (isOnline == true) 
                    // {
                     
                    //   tipsFactory.getHasTag(display_name, (response) => {
    
                    
      
                    //     if (response != "error") {
                    //       var content = response.hash_tag.content;
                    //       if (content != null) {
                    //         content = content.replace(/<a/g, '<b><u><a ');
                    //         content = content.replace(/<\/a>/g, '</a></u></b>');
                    //         content = content.replace(/<ol/g, '<ol class="ordered_list"');
                    //         content = content.replace(/<ul/g, '<ul class="unordered_list"');
                    //         content = content.replace(/<em/g, '<em class="make_italic"')
                    //       }
      
                    //       //Before Tineri Tip CR
                    //       {
                    //         // let profileModal = modalPopup.create('TineriTipsPage', {
                    //         //   valueData: response.hash_tag,
                    //         //   htmlContent: content
                    //         // });
                    //       }
      
                    //       //After Tineri Tip CR
      
                    //         // let profileModal = modalPopup.create('TineriTipsPage', {
                    //         //   valueData: response.hash_tag,
                    //         //   htmlContent: content,
                    //         //   title: display_name
                    //         // });
                    //       //end
      
      
                    //       // profileModal.onDidDismiss(() => {
                    //       //   popupShown = false;
                    //       // });
                    //       // profileModal.present();
      
                    //     } else {
                    //       // popupShown = false;
                    //     }
                    //   })
      
                    // }
                    
                    //else 
                    {
    
    
      
                      //---------------When offline show tineri tips from localstorage---------------------------
                      var LSDtipsListoffline = JSON.parse(window.localStorage.getItem('LSDGuidestipsList'))
      
    
      
    
                      var tipsdaytrip = LSDtipsListoffline;
    
                   
    
                      
                      tipsdaytrip.forEach(tipValue => {
      
                        var tipname = tipValue.name
                        var tipidcms = tipValue.id
      
                        if (tipname == hashTagId) {
                          // var content = tipValue.content;
    
    
                          let dialogRef = matdialogscope.open(TineriTipComponent, {
                            data: {
                                tineriTip: tipValue,
                                id:hashTagId,
                                display_name:display_name
                            }
                        });
    
    
      
                          // if (content != null) {
      
                          //   content = content.replace(/<a/g, '<a class="tineri_tip_a"');
                          //   content = content.replace(/<\/a>/g, '</a>');
      
                          //   content = content.replace(/<ol/g, '<ol class="ordered_list"');
                          //   content = content.replace(/<ul/g, '<ul class="unordered_list"');
      
                          //   content = content.replace(/<em/g, '<em class="make_italic"')
                          // }
      
                          // spinner.dismiss();
      
                          {
                            // let profileModal = modalPopup.create('TineriTipsPage', {
                            //   valueData: tipValue,
                            //   htmlContent: content
                            // }, {
                            //   cssClass: 'settings-modal'
                            // });
                          }
      
                          // let profileModal = modalPopup.create('TineriTipsPage', {
                          //   valueData: tipValue,
                          //   htmlContent: content,
                          //   title: display_name
                          // }, {
                          //   cssClass: 'settings-modal'
                          // });
      
      
      
                          // profileModal.onDidDismiss(() => {
      
                          //   popupShown = false;
                          //   scope.sign.registerBackButton(scope.platform, scope.navCtrl, scope.alertCtrl)
      
                          // });
    
    
                          // profileModal.present();
                        }
                      });
      
      
                      //---------------When offline show tineri tips from localstorage CLOSE---------------------------
                    }
      
                  }
                  
                }
      
              })
            });
      
          }, 100);
    
      
        
        var matdialogscope =this.matDialog;
        
        
        // {
        
       
        
        
        
        // setTimeout(() => {
        
       
        
        // $(".fontnormal a").each(function (index, el) {
        
        
        
        
        // $(this).css('color', localStorage.getItem("primaryColor"));
        
        // $(this).css('text-decoration', 'none');
        // $(this).click(function (event) {
        
      
        
        
        
        
        // if ($(this).attr('href') == "#") {
        
      
        
        // event.preventDefault();
        
        // // if (!popupShown)
        // {
        
        // //Before Tineri Tip CR
        // {
        // // var hashTagId = $(this).text().replace('#', '')
        // }
        
        // //After Tineri Tip CR
        // {
        // //BOC BUG FIX #
        // // var hashTagId = $(this).text().replace('#', '')
        
        // var hashTagId = $(this).data("compare")
        
        
        // var display_name = $(this).text()
        
        
        // //BOC BUG FIX # end
        // }
        
        
        // // if (isOnline == true)
        // // {
        
        // // tipsFactory.getHasTag(display_name, (response) => {
        
        
        
        // // if (response != "error") {
        // // var content = response.hash_tag.content;
        // // if (content != null) {
        // // content = content.replace(/<a/g, '<b><u><a ');
        // // content = content.replace(/<\/a>/g, '</a></u></b>');
        // // content = content.replace(/<ol/g, '<ol class="ordered_list"');
        // // content = content.replace(/<ul/g, '<ul class="unordered_list"');
        // // content = content.replace(/<em/g, '<em class="make_italic"')
        // // }
        
        // // //Before Tineri Tip CR
        // // {
        // // // let profileModal = modalPopup.create('TineriTipsPage', {
        // // // valueData: response.hash_tag,
        // // // htmlContent: content
        // // // });
        // // }
        
        // // //After Tineri Tip CR
        
        // // // let profileModal = modalPopup.create('TineriTipsPage', {
        // // // valueData: response.hash_tag,
        // // // htmlContent: content,
        // // // title: display_name
        // // // });
        // // //end
        
        
        // // // profileModal.onDidDismiss(() => {
        // // // popupShown = false;
        // // // });
        // // // profileModal.present();
        
        // // } else {
        // // // popupShown = false;
        // // }
        // // })
        
        // // }
        
        // //else
        // {
        
        
        
        // //---------------When offline show tineri tips from localstorage---------------------------
        // var LSDtipsListoffline = JSON.parse(window.localStorage.getItem('LSDGuidestipsList'))

        // // var LSDtipsListoffline = JSON.parse(window.localStorage.getItem('LSDExperiencetipsList'))
        // // var LSDtipsListoffline = JSON.parse(window.localStorage.getItem('LSDtipsList'))
        // // var LSDtipsListoffline = JSON.parse(window.localStorage.getItem('LSDItinerarytipsList'))
        
        
        
        
        // var tipsdaytrip = LSDtipsListoffline;
        
        
        
        // tipsdaytrip.forEach(tipValue => {
        
        // var tipname = tipValue.name
        // var tipidcms = tipValue.id
        
        // if (tipname == hashTagId) {
        // // var content = tipValue.content;
        
        
        
        // let dialogRef = matdialogscope.open(TineriTipComponent, {
        // data: {
        // tineriTip: tipValue,
        // id:hashTagId,
        // display_name:display_name
        // }
        // });
        
        
        
        // // if (content != null) {
        
        // // content = content.replace(/<a/g, '<a class="tineri_tip_a"');
        // // content = content.replace(/<\/a>/g, '</a>');
        
        // // content = content.replace(/<ol/g, '<ol class="ordered_list"');
        // // content = content.replace(/<ul/g, '<ul class="unordered_list"');
        
        // // content = content.replace(/<em/g, '<em class="make_italic"')
        // // }
        
        // // spinner.dismiss();
        
        // {
        // // let profileModal = modalPopup.create('TineriTipsPage', {
        // // valueData: tipValue,
        // // htmlContent: content
        // // }, {
        // // cssClass: 'settings-modal'
        // // });
        // }
        
        // // let profileModal = modalPopup.create('TineriTipsPage', {
        // // valueData: tipValue,
        // // htmlContent: content,
        // // title: display_name
        // // }, {
        // // cssClass: 'settings-modal'
        // // });
        
        
        
        // // profileModal.onDidDismiss(() => {
        
        // // popupShown = false;
        // // scope.sign.registerBackButton(scope.platform, scope.navCtrl, scope.alertCtrl)
        
        // // });
        
        
        // // profileModal.present();
        // }
        // });
        
        
        // //---------------When offline show tineri tips from localstorage CLOSE---------------------------
        // }
        
        // }
        
        // }
        
        // })
        // });
        
        // }, 100);
        
        
        
        // }
        }
    


        trigger(){
            this.check_height()
        }


}
